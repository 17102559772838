import React, {useContext, useEffect} from "react"
import AppLink from "../shared/RouterDecorators/AppLink";
import {GlobalContext} from "../../context/Global";
import NeedYou from "../../assets/img/landingpage/need-you.png";
import AppStore from "../../assets/img/landingpage/app-store.png";
import QRCode from "../../assets/img/landingpage/qrcode_@2x.png";
import './HotJoinSection.scss'

const TEST_FLIGHT_LINK = "https://testflight.apple.com/join/lE3KeD4k"

const HotJoinSection = () : JSX.Element => {
  const {changeLayoutParams} = useContext(GlobalContext);

  useEffect(() => {
    changeLayoutParams?.({main: {cover: false}});
  }, [changeLayoutParams]);

  return (
    <div className="HotJoinSection">
      <div className='HotJoinSection-Join-footer-background'>
        <div className='HotJoinSection-Join-bottom container'>
          <div className='HotJoinSection-Join-bottom image'>
            <img src={NeedYou} alt='Need You'/>
          </div>
          <div className='HotJoinSection-Join-info'>
            <h2 className='title'>Get Involved today!</h2>
            <div className='HotJoinSection-Join-info-appStore'>
              <AppLink to={{pathname: TEST_FLIGHT_LINK}} target="_blank" className='HotJoinSection-Join-info-play-btn purple'>Play for Free. Click to download</AppLink>
              <div className='HotJoinSection-Join-info-appStore-details'>
                <h4 className='HotJoinSection-Join-info-appStore-title'>Join the world of fitness gaming. </h4>
                <p className='HotJoinSection-Join-info-appStore-description'>Choose a tribe and start defending your district today.</p>
              </div>
              <img className='HotJoinSection-Join-info-appStore-img' src={AppStore} alt='appstore'/>
            </div>
          </div>
          <div className='HotJoinSection-Join-bottom image qr-code'>
            <img src={QRCode} alt='QRcode'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HotJoinSection