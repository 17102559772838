import React, {useContext} from 'react';
import {UserContext} from "context/User";
import {NavLink, NavLinkProps} from "react-router-dom";
import {isRestrictedPath} from "config/urls";

const AppLink: React.FC<NavLinkProps> = (props) => {
  const {user} = useContext(UserContext);
  const allowedPath = props.to ? !isRestrictedPath(user ? user.isOrganizationManager : undefined, props.to as string) : true;

  if(props.to === undefined){
    console.log("%c Received undefined instead of string", "color: red; font-size: 16px; font-weight: bold; border-left: 5px solid red", props);
  }

  return allowedPath
    ? <NavLink {...props}> {props.children} </NavLink>
    : null
};

export default AppLink;