import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Menu: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="80" height="64" viewBox="0 0 80 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Miscellaneous/Icons" transform="translate(-1723.000000, -555.000000)"></g>
    </g>
  </svg>
);

export default Menu;