import React from 'react';
import "./Trailer.scss";

const Trailer = () => {
    return (
        <div className='trailer'>
            <iframe
                className='trailer-iframe'
                src="https://player.vimeo.com/video/537226322?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen title="Hot SizzleT"/>
        </div>
    );
};

export default Trailer;
