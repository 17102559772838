import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Team: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="163" height="92" viewBox="0 0 163 92" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="team-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1739.000000, -1015.000000)">
        <g id="icon-teams-permissions" transform="translate(1739.000000, 1017.000000)">
          <g id="Group-4" transform="translate(67.000000, 51.000000) scale(-1, 1) translate(-67.000000, -51.000000) translate(7.000000, 14.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
            <path d="M90.960373,73.4528302 C89.0921868,70.4491981 84.8695264,65.0196226 80.4649453,62.9811321 L62.9725659,56 C62.9725659,56 55.9756141,59.490566 45.4801865,59.490566 C34.9847588,59.490566 27.9878071,56 27.9878071,56 L10.4954276,62.9811321 C6.09084651,65.0196226 1.86818612,70.4491981 0,73.4528302" id="Stroke-22"/>
            <path d="M56.3977654,45.3773585 C66.1445192,38.8936321 65.2401632,34.7311321 66.4016572,25.2996226 C67.0261351,20.2103774 67.2150528,14.7249528 64.753875,10.0528302 C62.4991073,5.77514151 58.3586611,2.81165094 53.8351318,1.28801887 C51.3547124,0.453773585 48.6381459,0 45.9023377,0 C43.1665296,0 40.4499631,0.453773585 37.9695437,1.28801887 C33.4460143,2.81165094 29.3055681,5.77514151 27.0508004,10.0528302 C24.5861242,14.7249528 24.7785403,20.2103774 25.4030183,25.2996226 C26.5645123,34.7311321 25.6601563,38.8936321 35.4069101,45.3773585" id="Stroke-23"/>
            <path d="M120,57 C118.872667,55.279 116.324556,52.168 113.666667,51 L103.111111,47 C103.111111,47 98.8888889,49 92.5555556,49 C86.2222222,49 82,47 82,47" id="Stroke-24"/>
            <path d="M97.7768739,37 C103.605746,33.1421154 103.064912,30.6653846 103.759523,25.0535385 C104.132981,22.0253846 104.24596,18.7615 102.774096,15.9815385 C101.425673,13.4362692 98.9495527,11.6729615 96.2443366,10.7663846 C94.760966,10.27 93.1363718,10 91.5002705,10 C89.8641692,10 88.239575,10.27 86.7562044,10.7663846 C84.0509883,11.6729615 81.5748683,13.4362692 80.2264446,15.9815385 C78.7524889,18.7615 78.86756,22.0253846 79.2410179,25.0535385 C79.9356287,30.6653846 79.3947947,33.1421154 85.2236671,37" id="Stroke-25"/>
          </g>
          <g id="lock" transform="translate(120.000000, 0.000000)" stroke="#FFFFFF" strokeWidth="4">
            <path d="M29.1428571,44 L4.85714286,44 C2.17478571,44 0,41.8701622 0,39.2432432 L0,23.7837838 C0,21.1568649 2.17478571,19.027027 4.85714286,19.027027 L29.1428571,19.027027 C31.8252143,19.027027 34,21.1568649 34,23.7837838 L34,39.2432432 C34,41.8701622 31.8252143,44 29.1428571,44 Z" id="Shape"/>
            <path d="M27.9285714,19.027027 L6.07142857,19.027027 L6.07142857,10.7027027 C6.07142857,4.79124324 10.9637857,0 17,0 C23.0362143,0 27.9285714,4.79124324 27.9285714,10.7027027 L27.9285714,19.027027 Z" id="Shape"/>
          </g>
          <g id="dots-left-right" transform="translate(0.000000, 82.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="150" cy="3" r="3"/>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
            <circle id="Oval-2-Copy" cx="160" cy="3" r="3"/>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Team;