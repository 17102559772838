import {tabGroupDataInterface} from "interfaces/misc";
import {boardsTabTypes, listTypes} from "config/names";


export const MIN_LOADER_TIME = 500;
export const NOTIFICATIONS_UPDATE_INTERVAL = 30000;
export const MIN_NOTIFICATION_TIME = 3000;
export const MIN_SEARCH_STRING_LENGTH = 3;
export const API_RESPONSE_RESULTS = 20;

export const defaultReqParams = {searchTerm: "", page: 0, size: API_RESPONSE_RESULTS};

export enum defaultTimingOptions {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}
export enum challengesOptions {
  ENDURANCE = "endurance",
  SPEED = "speed",
  STRENGTH = "strength"
}
export const defaultTimingDataset: tabGroupDataInterface[] = [
  // eslint-disable-line
  {
    value: defaultTimingOptions.DAILY,
    label: "Daily",
    isActive: true,
    disabled: false,
  },
  {
    value: defaultTimingOptions.WEEKLY,
    label: "Weekly",
    isActive: false,
    disabled: false,
  },
  {
    value: defaultTimingOptions.MONTHLY,
    label: "Monthly",
    isActive: false,
    disabled: false,
  },
];

export const formatOptions = {
  [defaultTimingOptions.MONTHLY]: {
    month: "numeric",
    day: "numeric",
  },
  [defaultTimingOptions.WEEKLY]: {
    month: "numeric",
    day: "numeric",
  },
  [defaultTimingOptions.DAILY]: {
    month: "numeric",
    day: "numeric",
  },
};
export const tabsData = [
  {
    label: "Alerts",
    value: listTypes.ALERTS,
    isActive: true
  },
  {
    label: "Invitations",
    value: listTypes.INVITATIONS,
    isActive: false,
  },
];
export const boardsTabs = [
  {
    label: "My Boards",
    value: boardsTabTypes.MY_BOARDS,
    isActive: true
  },
  {
    label: "Organizations",
    value: boardsTabTypes.ORGANIZATIONS,
    isActive: false,
  },
];
