import React, {ReactElement, useContext} from 'react';
import {GlobalContext} from "context/Global";
import Loader from "components/shared/Loader/Loader";
import './AppLoader.scss';

interface AppLoaderInterface {
  children: ReactElement;
}

const AppLoader: React.FC<AppLoaderInterface> = ({ children }) => {
  const {isAppLoading} = useContext(GlobalContext);

  if (isAppLoading) {
    return (
      <div className="Loader-wrapper">
        <Loader />
      </div>
    );
  }

  return children
};

export default AppLoader;

