import React, {useContext, useEffect, useState} from 'react';
import {USER_DATA} from "config/names";
import {appSections, isRestrictedPath, routes} from "config/urls";
import {getURLQueryParams,URLParamsToString} from "helpers/misc";
import {useHistory, useLocation} from "react-router-dom";
import {userInterface} from "interfaces/user";
import {GlobalContext} from "context/Global";
import {UserContext} from "context/User";

export const useRedirectsAndInvitations = (user?: userInterface | null) => {
    const history = useHistory();
    const location = useLocation();
    const {showNotification, redirectAfterLogin, handleRedirectAfterLogin} = useContext(GlobalContext);

    const InvitationsRedirects = (invitation_success?: string, orgId?: string) => {

        if (invitation_success === "true") {
            const linkToRedirect = routes[appSections.INFO].invitationConfirmed() + URLParamsToString({organizationId : orgId});
            if (localStorage.getItem(USER_DATA)) {
                history.replace(linkToRedirect);
            } else {
                showNotification?.({
                    type: "info",
                    message: "Please login to complete the invitation step",
                });
                history.replace(routes[appSections.AUTH].signin);
                handleRedirectAfterLogin?.(linkToRedirect);
            }
        } else if (invitation_success === "false") {
            if (localStorage.getItem(USER_DATA)) {
                history.replace(routes[appSections.BOARDS].list);
                showNotification?.({ type: "error", message: "Token is not valid!" });
            } else {
                history.replace(routes[appSections.AUTH].signin);
                showNotification?.({ type: "error", message: "Token is not valid!" });
            }
        } else {
            history.replace(routes[appSections.AUTH].signin);
        }
    };

    useEffect(() => {
        const { invitation_success, organizationId } = getURLQueryParams();
        const allowedPath = !isRestrictedPath(
            user ? user.isOrganizationManager : undefined,
            location.pathname
        );
        if (invitation_success) {
            InvitationsRedirects(invitation_success, organizationId as string | undefined);
        } else if (!allowedPath) {
            const userToken = localStorage.getItem(USER_DATA);
            !userToken && history.replace(routes[appSections.MAIN]);
            if (user) {
                redirectAfterLogin && handleRedirectAfterLogin?.("");
                const redirectLink = user.isOrganizationManager
                    ? redirectAfterLogin || routes[appSections.MANAGER].main
                    : redirectAfterLogin || routes[appSections.USER].dashboard;
                history.replace(redirectLink);
            }
        }
    }, [history, location, user]); //eslint-disable-line
    return {}
};

export const useCustomHistory = () => {
    const history = useHistory();
    const location = useLocation();
    const { user } = useContext(UserContext);
    const [stack, setStack] = useState<string[]>([]);

    const goBack = () => {
        if (!stack.length) return;
        setStack(pr => {
            const newStack = [...pr];
            newStack.length > 1 && newStack.pop();
            history.replace(newStack[newStack.length - 1])
            return newStack;
        })
    };
    useEffect(() => {
        if (!user) {
            setStack([]);
        } else {
            setStack((pr: any) => {
                const newStack = [...pr];
                const allowedPath = !isRestrictedPath(user ? user.isOrganizationManager : undefined, location.pathname);
                if (newStack[pr.length - 1] !== location.pathname + location.search && allowedPath) {
                    return newStack.concat(location.pathname + location.search);
                } else {
                    return newStack;
                }
            });
        }
    }, [user, location]);
    return {goBack}
}
