import React from "react";

const BoardsNav = () =>
  <svg className='board-nav' width="70" height="68" viewBox="0 0 70 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icon-boards-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-boards" transform="translate(1.000000, 4.000000)" stroke="#FFFFFF" strokeWidth="3">
        <rect id="Rectangle" x="0.872987184" y="0.334391534" width="66.1270128" height="59.5978836" rx="5.95978836"/>
       <g id="Group-4" transform="translate(16.500000, 22.000000)" strokeLinecap="round" strokeLinejoin="round">
         <path d="M33.9563753,27.9291005 C33.2706181,26.8101774 31.7206015,24.7875335 30.103807,24.0281481 L23.6828598,21.4275132 C23.6828598,21.4275132 21.1144809,22.7278307 17.2619126,22.7278307 C13.4093443,22.7278307 10.8409654,21.4275132 10.8409654,21.4275132 L4.4200182,24.0281481 C2.8032237,24.7875335 1.25320705,26.8101774 0.567449885,27.9291005" id="Stroke-22"/>
          <path d="M21.4523163,17.0931217 C25.0935897,14.693265 24.7557328,13.1525763 25.1896534,9.6616407 C25.4229511,7.77793081 25.4935285,5.74758095 24.5740613,4.01826292 C23.7317064,2.43493887 22.184884,1.33804607 20.4949463,0.774095889 C19.5682906,0.465312169 18.5534131,0.297354497 17.5313471,0.297354497 C16.5092811,0.297354497 15.4944036,0.465312169 14.5677479,0.774095889 C12.8778101,1.33804607 11.3309878,2.43493887 10.4886329,4.01826292 C9.56785864,5.74758095 9.63974307,7.77793081 9.87304074,9.6616407 C10.3069613,13.1525763 9.96910449,14.693265 13.6103779,17.0931217" id="Stroke-23"/>
       </g>
       <path d="M0.334456129,12.7957672 L67.650838,12.7957672" id="Path-32"/>
     </g>
    </g>
  </svg>;

export default BoardsNav;
