import {TEAMS} from "config/names";
import CapraIcon from "assets/img/tribes/Capra.png";
import AtmosIcon from "assets/img/tribes/Atmos.png";
import MavricIcon from "assets/img/tribes/Mavric.png";
import {reqParamsObjType} from "interfaces/misc";
import {MIN_SCALING_FACTOR} from "config/maps";
import {IThemeState} from "interfaces/theme";
import defaultAvatarCapra from "assets/img/icon-head-capra.png";
import defaultAvatarMavric from "assets/img/icon-head-mavric.png";
import defaultAvatarAtmos from "assets/img/icon-head-atmos.png";
import defaultAvatarUnknown from "assets/img/icon-head-unknown.png";

export const getTribeGradient = (name: string) => {
  switch (name) {
    case TEAMS.CAPRA:
      return ["#62af31", "#009818"];
    case TEAMS.ATMOS:
      return ["#36A3D8", "#205980"];
    case TEAMS.MAVRIC:
      return ["#F0952D", "#e58110"];
    default:
      return ["#62af31", "#009818"];
  }
};

export const getColorsToShow = (showAll: boolean, colors: {name: string; code: string, border?: string}[]) => {
  const mainColors = ["yellow", "dark-orange", "blue", "violet", "green"];
  return showAll ? colors : colors.filter(({name}) => mainColors.indexOf(name) >= 0);
};

export const localizeDates = (dates: string[], config?: Intl.DateTimeFormatOptions): string[] =>
  dates.map((item) => new Date(item).toLocaleDateString([], config || undefined));

export const getLastYear = (dates: string[]): number => {
  const years = dates.reduce((rs, item) => rs.add(new Date(item).getFullYear()), new Set())
  const yearToArray = Array.from(years);
  return yearToArray.length > 0 ? Math.max(...Array.from(years) as number[]) : new Date().getFullYear();
}

/**
 * Map URL search params to object
 * supports a[]=1&a[]=2 syntax
 * curly braces and dots are not supported
 * @param {boolean} removeBraces should the braces be removed?
 * @returns {{[p: string]: string | null }}
 */

export const getURLQueryParams = (removeBraces: boolean = true) => {
  const paramsObj = new URLSearchParams(window.location.search);
  let paramNames = Array.from(new Set(paramsObj.keys()));
  let res: {[key: string]: string | null} = {};

  paramNames.forEach((name) => {
    res[name] = paramsObj.get(name);
  });

  if (removeBraces) {
    Object.keys(res).forEach((key) => {
      if (!key.match(/[\]\[]/g)?.length) return; //eslint-disable-line

      res[key.replace(/[\]\[]/g, "")] = res[key]; //eslint-disable-line
      delete res[key];
    });
  }

  return res;
};

export const URLParamsToString = (params: {[key: string]: string | number | null | undefined}) => {
  let result = "?";
  Object.keys(params).forEach((key, i) => {
    params[key] !== undefined && (result += `${key}=${params[key]}&`);
  });

  return result.slice(0, result.length - 1);
};

export function deleteEmptyProps(params: reqParamsObjType): reqParamsObjType {
  const newParams: reqParamsObjType = {};
  Object.keys(params).forEach((key) => {
    if (!(params[key] === undefined || params[key] === "")) {
      newParams[key] = params[key];
    }
  });
  return newParams;
}

export const getTribeIcon = (tribe: TEAMS) => {switch(tribe){
  case TEAMS.ATMOS  : return AtmosIcon
  case TEAMS.CAPRA  : return CapraIcon
  case TEAMS.MAVRIC : return MavricIcon
  default           : return CapraIcon
}}

export const scrollTo = (element?: HTMLElement, offset?: number) => window.scroll({
  behavior: "smooth",
  top: element? element.offsetTop - (offset || 110) : 0
})

export const findMinMaxCoordinates = (arr: Array<any>) : [[number, number], [number, number]] => {
  const getCoordinates = (feature: any) : Array<[number, number]> => {
    const isPolygon = feature?.geometry?.type.includes('Polygon')
    switch(true) {
      case isPolygon:
        return feature.geometry.coordinates
      case isPolygon && feature.geometry.type === 'MultiPolygon':
        return feature.geometry.coordinates.flatMap((e: Array<any>) => e)
      case feature.coordinates?.length >= 0:
        return feature.coordinates
      default:
        return [feature]
    }
  }

  const res = arr.reduce((acc, feature: any) => {
      const coords = getCoordinates(feature)

      coords.forEach(([lng, lat]: [number, number]) => {
        acc.minLng = Math.min(acc.minLng, lng)
        acc.minLat = Math.min(acc.minLat, lat)
        acc.maxLng = Math.max(acc.maxLng, lng)
        acc.maxLat = Math.max(acc.maxLat, lat)
      })

      return acc
    }, { minLng: 180, maxLng: -180, minLat: 89, maxLat: -89 }
  )

  return [ [res.minLng || -120, res.minLat || -20],
           [res.maxLng ||  120, res.maxLat ||  40] ]
}

export const minScaling = (ref: any) => {
  if (!ref?.current) return 0;
  const scaling = (ref.current.deck.width / ref.current.deck.height);
  return scaling < 0.8 ? 0 : (scaling + MIN_SCALING_FACTOR);
}

export const generateThemeClassName = (theme: IThemeState): string => `${theme.type}-${theme.name}`;

export const flatObj = (obj: {[key:string]: {[key: string]: any}}, field: string):{[key:string]: any} => Object.keys(obj).reduce((res: any, key) => {
  res[key] = obj[key][field];
  return res
}, {})

export const numberWithCommas = (number: number): string =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getDefaultAvatar = (tribeName?: TEAMS) => {switch(tribeName) {
  case TEAMS.CAPRA: return defaultAvatarCapra
  case TEAMS.MAVRIC: return defaultAvatarMavric
  case TEAMS.ATMOS: return defaultAvatarAtmos
  default: return defaultAvatarUnknown
}}

export function debounce(func: Function, wait: number, immediate: boolean = false) {
  let timeout: NodeJS.Timeout | null
  return (...args: any[]) => {
    // @ts-ignore
    const context = this
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function sleep(ms: number) : Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}