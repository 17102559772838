import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {signInData, userContextInterface, userInterface, userPrimaryDataInterface} from "interfaces/user";
import {DEFAULT_ERROR_TEXT, THEME, USER_DATA} from "config/names";
import {GlobalContext} from "context/Global";
import {useApi} from "api/useApi";
import {api, appSections, routes} from "config/urls";
import {ModalContext} from "context/Modal";
import AppInvitation from "components/AppInvitation/AppInvitation";
import {useHistory, useLocation} from "react-router-dom";
import {STATUS_CODES} from "config/api";
import {useRedirectsAndInvitations} from "../hooks/redirects";
import {useGA4React} from "ga-4-react";
import {MIN_LOADER_TIME, NOTIFICATIONS_UPDATE_INTERVAL} from "config/misc";
import {GOOGLE_ANALYTICS_ID} from "config/tokens";

export const UserContext = createContext<Partial<userContextInterface>>({});

interface organization {
  id: number,
  role: string
}

export const UserContextProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<userInterface | null>();
  const {showNotification, setIsAppLoading} = useContext(GlobalContext);
  const {handleModal} = useContext(ModalContext);
  const [invitationCount, setInvitationCount] = useState<number>(0);
  const [alertsCount, setAlertsCount] = useState<number>(0);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const history = useHistory();
  const location = useLocation();
  const {apiCall, defaultApiCatch} = useApi(setUser);
  const ga4React = useGA4React(GOOGLE_ANALYTICS_ID);

  const fetchWatchListCount = () =>
    apiCall({url: api.user.watchListCount})
      .then(({data}) => setAlertsCount(data))
      .catch(defaultApiCatch)

  const fetchInvitationCount = () =>
    apiCall({url: api.invitations.count})
      .then(({data}) => setInvitationCount(data))
      .catch(defaultApiCatch)

  useEffect(() => {
    if (user) {
      fetchWatchListCount()
      fetchInvitationCount()
      const interval = setInterval(() => {
        fetchWatchListCount()
        fetchInvitationCount()
      }, NOTIFICATIONS_UPDATE_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [user])

  useEffect(() => {
    setNotificationsCount(alertsCount + invitationCount);
  }, [alertsCount, invitationCount]);

  useRedirectsAndInvitations(user);

  const getUserDetails = (primaryData?: userPrimaryDataInterface) => {
    if (!localStorage.getItem(USER_DATA)) {
      setUser(null);
      return Promise.resolve(false);
    }

    return apiCall({url: api.user.getDetails})
      .then(({data}) => {
        const isUserHero: boolean = data.organizations.some((org: organization) => org.role === "hero");
        if (!isUserHero && !data.isOrganizationManager && !data.hasDeviceToken) {
          handleModal({component: AppInvitation})
        }
        const newUser = primaryData ? {...primaryData, ...data} : {token: localStorage.getItem(USER_DATA), ...data};
        setUser(newUser);

        return newUser
      })
      .catch(defaultApiCatch)
      .finally(() => setIsAppLoading?.(false))
  }
  const getUserProp = useCallback((propName: string) => (user && propName in user ? user[propName] : null), [user]);

  useEffect(() => {
    if (!ga4React) return;
    ga4React.pageview(location.pathname + location.search)
  }, [location, ga4React]);

  useEffect(() => {
    getUserDetails();
  }, []); //eslint-disable-line

  const updateUser = (newUserData: Partial<userInterface>) => setUser(pr => ({...pr, ...newUserData}) as userInterface)

  const setUserTribe = (tribeId: number): Promise<any> =>
    apiCall({
      method: "PUT",
      url: api.user.setUserTribe(tribeId)
    }).catch(defaultApiCatch)

  const signup = (userData: any) => {
    const {tribeId, ...restUserData} = userData
    const data = {
      deviceToken: null,
      lastStepsUpdate: null,
      ...restUserData
    }
    return apiCall({
      method: "POST",
      url: api.auth.signup,
      data
    }).then(async ({data}) => {
      localStorage.setItem(USER_DATA, data.token)
      await setUserTribe(tribeId)
      const userData = await getUserDetails(data)
      if (userData) {
        history.replace(
          userData.isOrganizationManager
            ? routes[appSections.MANAGER].main
            : routes[appSections.USER].dashboard
        );
      }
      return data;
    })
  }
  const login = ({email, password}: signInData) =>
    apiCall({method: "POST", url: api.auth.signin, data: {email, password, deviceToken: null}}).then(async ({data}) => {
      localStorage.setItem(USER_DATA, data.token);
      setIsAppLoading?.(true);
      const userData = await getUserDetails(data);
      if (userData) {
        history.replace(
          userData.isOrganizationManager
            ? routes[appSections.MANAGER].main
            : routes[appSections.USER].dashboard
        );
        Promise.all([
          fetchWatchListCount(),
          fetchInvitationCount()
        ]).finally(() => setIsAppLoading?.(false))
      }
      return data;
    }).catch(e => {
      const message = (
        e?.response?.status === STATUS_CODES.UNAUTHORIZED
        && (e?.response?.data?.error || "Credentials doesn't match. Try again.")
      ) || DEFAULT_ERROR_TEXT;
      showNotification?.({type: "info", message})
    });

  const logout = () => {
    setIsAppLoading?.(true);

    return apiCall({url: api.auth.signout})
      .finally(() => {
        setTimeout(() => {
          setIsAppLoading?.(false);
        }, MIN_LOADER_TIME);
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(THEME);
        history.replace(routes[appSections.MAIN]);
        setUser(null);
      });
  };

  const resetPassword = (data: { email: string }) => {
    return apiCall({
      method: "PUT",
      url: api.auth.resetPass,
      data,
    })
      .then(() => {
        showNotification?.({
          type: "success",
          message: "Please check your email for next steps instructions to set new password.",
        });
      })
      .catch(defaultApiCatch)
  }
  const setTokenGetData = (token: string) => {
    localStorage.setItem(USER_DATA, token);
    getUserDetails();
  }

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        signup,
        resetPassword,
        getUserProp,
        updateUser,
        setTokenGetData,
        getUserDetails,
        notifications: notificationsCount,
        invitations: invitationCount,
        getInvitationsCount: fetchInvitationCount,
        getAlertsCount: fetchWatchListCount,
        alerts: alertsCount
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
