import React, {useContext} from 'react';
import {TEAMS} from "config/names";
import {ThemeContext} from "context/Theme";
import classNames from "classnames";
import {variantToColor} from "./_defaultIconSettings";

const HPHand: React.FC<{className?: string}> = ({className}) => {
  const {theme} = useContext(ThemeContext)
  const color = variantToColor[theme?.name ?? TEAMS.CAPRA]
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 89"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className)}
    >
      <title>{"icon-hp-goal-capra@1x"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.27 64.916a3.506 3.506 0 010-4.95l12.007-12.034a5.41 5.41 0 013.83-1.59h32.33a5.23 5.23 0 013.703 1.537 5.255 5.255 0 011.533 3.711v.001a5.256 5.256 0 01-1.534 3.713 5.232 5.232 0 01-3.704 1.537H44.281a1.743 1.743 0 00-1.745 1.749v.001a1.744 1.744 0 001.746 1.75h18.151c.55 0 1.089-.16 1.549-.463l18.064-11.882a4.406 4.406 0 016.036 1.161l.014.02a4.429 4.429 0 01-.925 6.042L64.037 73.055a2.818 2.818 0 01-1.72.586H34.16a3.892 3.892 0 00-2.755 1.144l-2.675 2.682a3.488 3.488 0 01-4.939 0L11.27 64.917zM5.848 63.915a1.37 1.37 0 00-1.939 0l-1.94 1.944a1.377 1.377 0 000 1.944l19.382 19.425a1.37 1.37 0 001.939 0l1.94-1.944a1.377 1.377 0 000-1.944L5.847 63.915z"
          fill={color}
        />
        <path
          d="M44.631 4c-10.21 0-18.5 8.308-18.5 18.541 0 10.234 8.29 18.542 18.5 18.542s18.5-8.308 18.5-18.542C63.13 12.308 54.84 4 44.63 4z"
          stroke={color}
          strokeWidth={4.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.564 20.978c0 .127-.062.19-.185.19h-5.045c-.124 0-.186-.063-.186-.19v-4.711c0-.93-.7-1.162-1.338-1.162-.597 0-1.339.232-1.339 1.162v12.55c0 .929.742 1.161 1.339 1.161.638 0 1.338-.232 1.338-1.162V24.02c0-.127.062-.19.186-.19h5.045c.123 0 .185.063.185.19v4.796c0 .93.741 1.162 1.339 1.162.638 0 1.338-.232 1.338-1.162v-12.55c0-.929-.7-1.161-1.338-1.161-.598 0-1.339.232-1.339 1.162v4.71z"
          fill={color}
        />
        <path
          d="M40.564 20.978c0 .127-.062.19-.185.19h-5.045c-.124 0-.186-.063-.186-.19v-4.711c0-.93-.7-1.162-1.338-1.162-.597 0-1.339.232-1.339 1.162v12.55c0 .929.742 1.161 1.339 1.161.638 0 1.338-.232 1.338-1.162V24.02c0-.127.062-.19.186-.19h5.045c.123 0 .185.063.185.19v4.796c0 .93.741 1.162 1.339 1.162.638 0 1.338-.232 1.338-1.162v-12.55c0-.929-.7-1.161-1.338-1.161-.598 0-1.339.232-1.339 1.162v4.71z"
          stroke={color}
          strokeWidth={0.7}
          strokeLinejoin="round"
        />
        <path
          d="M49.192 21.992c-.123 0-.185-.063-.185-.19V18.02c0-.126.062-.19.185-.19h3.254c.968 0 1.565.676 1.565 2.091 0 1.416-.597 2.071-1.565 2.071h-3.254zm3.357 2.64c2.368 0 4.242-1.52 4.242-4.71 0-3.212-1.874-4.733-4.242-4.733h-5.045c-.721 0-1.174.423-1.174 1.247v12.38c0 .93.741 1.162 1.338 1.162.639 0 1.339-.232 1.339-1.162v-3.993c0-.127.062-.19.185-.19h3.357z"
          fill={color}
        />
        <path
          d="M49.192 21.992c-.123 0-.185-.063-.185-.19V18.02c0-.126.062-.19.185-.19h3.254c.968 0 1.565.676 1.565 2.091 0 1.416-.597 2.071-1.565 2.071h-3.254zm3.357 2.64c2.368 0 4.242-1.52 4.242-4.71 0-3.212-1.874-4.733-4.242-4.733h-5.045c-.721 0-1.174.423-1.174 1.247v12.38c0 .93.741 1.162 1.338 1.162.639 0 1.339-.232 1.339-1.162v-3.993c0-.127.062-.19.185-.19h3.357z"
          stroke={color}
          strokeWidth={0.7}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default HPHand
