import {appSections, routes} from "./urls";
import {MenuNavTitleText, userRoles} from "./names";

const sharedLinks = [
    {
        name: MenuNavTitleText.STATISTICS,
        link: routes[appSections.STATS].main,
    },
    {
        name: MenuNavTitleText.MANAGER,
        link: routes[appSections.MANAGER].main,
    },
    {
        name: MenuNavTitleText.ADMIN,
        link: routes[appSections.ADMIN].main,
    },
    {
        name: MenuNavTitleText.SETTINGS,
        link: routes[appSections.USER].settings,
    },
    {
        name: MenuNavTitleText.INVITE,
        link: routes[appSections.INVITES].main,
    },
];

export const navLinks = {
    [userRoles.USER]: [
        {
            name: MenuNavTitleText.USER_RANK,
            link: routes[appSections.USER].ranking,
        },
        {
            name: MenuNavTitleText.USER_DASHBOARD,
            link: routes[appSections.USER].dashboard,
        },
        {
            name: MenuNavTitleText.USER_NOTIFICATIONS,
            link: routes[appSections.USER].notifications,
        },
        {
            name: MenuNavTitleText.BOARDS,
            link: routes[appSections.BOARDS].list,
        },
        {
            name: MenuNavTitleText.ORGANIZATIONS,
            link: routes[appSections.ORG].list,
        },
        ...sharedLinks,
    ],
    [userRoles.MANAGER]: [...sharedLinks],
};

const footerSharedLinks = [
    {
        name: MenuNavTitleText.DASHBOARD,
        link: routes[appSections.MANAGER].main,
        icon: "dashboard",
        className: "",
    },
    {
        name: MenuNavTitleText.STATISTICS,
        link: routes[appSections.STATS].main,
        icon: "statistics-nav",
        className: "",
    },
    {
        name: MenuNavTitleText.INVITE,
        link: routes[appSections.INVITES].main,
        icon: "invite",
        className: "invite-icon",
    },
    {
        name: MenuNavTitleText.SETTINGS,
        link: routes[appSections.USER].settings,
        icon: "settings-nav",
        className: "",
    },

];

export const adminRoute = {
    name: MenuNavTitleText.ADMIN,
    link: routes[appSections.ADMIN].main,
    icon: "settings-nav",
    className: "",
}

export const footerNavLinks = {
    [userRoles.USER]: [
        {
            name: MenuNavTitleText.DASHBOARD,
            link: routes[appSections.USER].dashboard,
            icon: "dashboard",
            className: "",
        },
        {
            name: MenuNavTitleText.BOARDS,
            link: routes[appSections.BOARDS].list,
            icon: "board-nav",
            className: "",
        },
        {
            name: MenuNavTitleText.INVITE,
            link: routes[appSections.INVITES].main,
            icon: "invite",
            className: "invite-icon",
        },
        {
            name: MenuNavTitleText.STATISTICS,
            link: routes[appSections.STATS].main,
            icon: "statistics-nav",
            className: "",
        },
        {
            name: MenuNavTitleText.SETTINGS,
            link: routes[appSections.USER].settings,
            icon: "settings-nav",
            className: "",
        },
    ],
    [userRoles.MANAGER]: [
        ...footerSharedLinks
    ],
};
