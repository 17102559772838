import AppLink from "../shared/RouterDecorators/AppLink";
import {appSections, routes} from "../../config/urls";
import Icon from "../shared/Icon/Icon";
import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../context/Global";
import './HotNavbar.scss'

const SHOP_LINK = "https://teespring.com/stores/healthovertime"

const HotNavbar = (): JSX.Element => {

  const {changeLayoutParams} = useContext(GlobalContext);

  useEffect(() => {
    changeLayoutParams?.({main: {cover: false}});
  }, [changeLayoutParams]);

  const switchMenu = (e: SyntheticEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (!target.classList.contains('Humburger-btn') && !target.classList.contains('backdrop')) return;
    toggleMenu((pr) => !pr);
  };

  const [open, toggleMenu] = useState(false);

  return (
    <div className="HotNavbar">
      <header className="HotNavbar-header">
        <div className="container">
          <AppLink to={routes[appSections.MAIN] + '?section='}>
            <div className="HotNavbar-logo" />
          </AppLink>
          <nav className="HotNavbar-header-menu-desk">
            <li className="HotNavbar-header-menu-desk-item dropdown">
              <div className="dropbtn">The Game</div>
              <div className="dropdown-content">
                <div className="dropdown-content-item">
                  <AppLink to={routes[appSections.WELCOME].badges}>Badges</AppLink>
                </div>
                <div className="dropdown-content-item">
                  <AppLink to={routes[appSections.WELCOME].districtChecker}>District Checker</AppLink>
                </div>
              </div>
            </li>
            <li className="HotNavbar-header-menu-desk-item">
              <AppLink to={routes[appSections.WELCOME].aboutUs}>About us</AppLink>
            </li>
            <li className="HotNavbar-header-menu-desk-item">
              <AppLink to={routes[appSections.MAIN] + '?section=Brands'}>Brands</AppLink>
            </li>
            <li className="HotNavbar-header-menu-desk-item">
              <AppLink to={routes[appSections.MAIN] + '?section=School'}>School</AppLink>
            </li>
            <li className="HotNavbar-header-menu-desk-item">
              <AppLink to={routes[appSections.MAIN] + '?section=Enterprise'}>Enterprise</AppLink>
            </li>
          </nav>
          <div className="HotNavbar-header-right-block">
            <AppLink to={routes[appSections.AUTH].signin} className="HotNavbar-header-signin-btn">Sign in</AppLink>
            <AppLink target="_blank" rel="noreferrer" to={{pathname: SHOP_LINK}} className="HotNavbar-header-play-btn"><Icon
              name="bag-white"
            /><span>Shop</span></AppLink>
            <div className="Humburger-menu" onClick={switchMenu}>
              <input type="checkbox" id="menu_checkbox" className={`Humburger-btn ${open ? 'open' : ""}`} />
              <span />
              <span />
              <span />
              <div className={`backdrop ${open ? "open" : ""}`} onClick={switchMenu}>
                <div className="HotNavbar-header-menu-mobile">
                  <nav className="HotNavbar-header-menu-mobile-header">
                    <li className="HotNavbar-header-menu-mobile-header-item">
                      <AppLink to={routes[appSections.WELCOME].badges}>
                        <span>The Game</span>
                        <Icon name="menu-arrow-right" />
                      </AppLink>
                    </li>
                    <li className="HotNavbar-header-menu-mobile-header-item">
                      <AppLink to={routes[appSections.WELCOME].aboutUs}>
                        <span>About us</span>
                        <Icon name="menu-arrow-right" />
                      </AppLink>
                    </li>
                    <li className="HotNavbar-header-menu-mobile-header-item">
                      <AppLink to={routes[appSections.MAIN]}>
                        <span>Brands</span>
                        <Icon name="menu-arrow-right" />
                      </AppLink>
                    </li>
                    <li className="HotNavbar-header-menu-mobile-header-item">
                      <AppLink to={routes[appSections.MAIN]}>
                        <span>Brands</span>
                        <Icon name="menu-arrow-right" />
                      </AppLink>
                    </li>
                    <li className="HotNavbar-header-menu-mobile-header-item">
                      <AppLink to={routes[appSections.MAIN]}>
                        <span>School</span>
                        <Icon name="menu-arrow-right" />
                      </AppLink>
                    </li>
                    <li className="HotNavbar-header-menu-mobile-header-item">
                      <AppLink to={routes[appSections.MAIN]}>
                        <span>Enterprise</span>
                        <Icon name="menu-arrow-right" />
                      </AppLink>
                    </li>
                    <AppLink to={routes[appSections.AUTH].signin} className="HotNavbar-header-menu-mobile-header-item"><span>Sign In</span><Icon
                      name="menu-arrow-right"
                    /></AppLink>
                  </nav>
                  <nav className="HotNavbar-header-menu-mobile-footer">
                    <AppLink to="/" className="HotNavbar-header-menu-mobile-footer-item">Terms of Use</AppLink>
                    <AppLink to="/" className="HotNavbar-header-menu-mobile-footer-item">Contact us</AppLink>
                    <AppLink to="/" className="HotNavbar-header-menu-mobile-footer-item">Copyrights</AppLink>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default HotNavbar