import React, {useContext} from 'react';
import {Redirect, RedirectProps} from "react-router";
import {isRestrictedPath} from "config/urls";
import {UserContext} from "context/User";

const AppRedirect: React.FC<RedirectProps> = (props) => {
  const {user} = useContext(UserContext);
  let {to} = props;

  const allowedPath = !isRestrictedPath(user ? user.isOrganizationManager : undefined, props.to as string)

  return allowedPath
    ? <Redirect {...props} to={to}/>
    : null
};

export default AppRedirect;
