import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Run: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="67" height="67" viewBox="0 0 67 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="App_Game_IPhoneX_Leaderboard_WatchList_Notification" transform="translate(-100.000000, -838.000000)" stroke="#FFFFFF" strokeWidth="2.13333333">
        <g id="Activity_Notification" transform="translate(48.000000, 670.000000)">
          <g id="Activity" transform="translate(32.000000, 149.000000)">
            <g id="Icon">
              <g id="icon-running" transform="translate(22.000000, 21.000000)">
                <path d="M44.3870968,15.483871 L51.6541935,23.8214194 L60.8557419,20.9806452 C61.828129,20.6234839 62.976,20.9507097 63.4869677,21.8539355 C64.1372903,23.0018065 63.5829677,24.4180645 62.4,24.8516129 L51.2330323,28.8134194 C50.8562581,28.9476129 50.4350968,28.8516129 50.1532903,28.5677419 L46.464,25.9065806" id="Stroke-1"/>
                <path d="M29.0582118,21.6774194 C27.7761473,25.5597419 26.7676311,27.1793548 24.5513731,29.4069677 C23.3973085,30.5651613 22.4393731,31.9060645 21.7560182,33.3945806 L16.6009214,44.6069677 L4.13640532,54.4877419 C2.99266339,55.3445161 2.75627629,56.9858065 3.60892145,58.1336774 C4.46259887,59.2836129 6.09666339,59.5220645 7.24040532,58.6652903 L21.791115,48.7752258 L21.7890505,48.7783226 C21.7900827,48.7772903 21.7900827,48.7762581 21.791115,48.7752258 L26.9813085,42.5218065" id="Stroke-3"/>
                <path d="M43.4363871,21.6774194 C43.4363871,24.804129 36.1703226,33.1416774 36.1703226,33.1416774 L46.7447742,44.6472258 C47.2609032,45.2593548 47.3806452,46.1140645 47.0544516,46.8459355 L40.1992258,62.2007742 C39.5540645,63.7326452 37.595871,64.3261935 36.1868387,63.2227097 C35.2536774,62.4939355 34.9935484,61.1809032 35.4549677,60.0867097 L40.3220645,46.6900645 C40.3220645,46.6900645 30.980129,40.4376774 28.9032258,37.3109677" id="Stroke-5"/>
                <path d="M38.1935484,5.67741935 C38.1935484,8.8123871 40.736,11.3548387 43.8709677,11.3548387 C47.0069677,11.3548387 49.5483871,8.8123871 49.5483871,5.67741935 C49.5483871,2.54245161 47.0069677,0 43.8709677,0 C40.736,0 38.1935484,2.54245161 38.1935484,5.67741935 Z" id="Stroke-7"/>
                <path d="M36.1424516,33.0456774 C36.1424516,33.0456774 34.0645161,30.9677419 33.0322581,30.9677419" id="Stroke-9"/>
                <path d="M34.2332227,11.3548387 L20.1790292,12.2797419 C19.2758033,12.3499355 18.5212227,12.9992258 18.3158033,13.883871 L15.5359324,25.851871 C15.2407066,27.1494194 16.2347711,28.4531613 17.6644485,28.4005161 C18.6089646,28.3643871 19.3893517,27.6335484 19.5999324,26.7076129 L21.7759324,17.6082581 L31.1178679,17.6082581 C31.1178679,17.6082581 33.1947711,17.6082581 33.1947711,19.6923871" id="Stroke-11"/>
                <path d="M19.6129032,5.16129032 L8.25806452,5.16129032" id="Stroke-13"/>
                <path d="M4.12903226,5.16129032 L2.06451613,5.16129032" id="Stroke-15"/>
                <path d="M15.483871,34.0645161 L5.16129032,34.0645161" id="Stroke-17"/>
                <path d="M6.19354839,41.2903226 L0,41.2903226" id="Stroke-19"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Run;