import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Org: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="179" height="115" viewBox="0 0 179 115" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-904.000000, -231.000000)">
        <g id="icon-organization" transform="translate(904.000000, 233.000000)">
          <g id="dots-left-right" transform="translate(0.000000, 107.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="166" cy="3" r="3"/>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
            <circle id="Oval-2-Copy" cx="176" cy="3" r="3"/>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
          </g>
          <g id="Group-9" transform="translate(27.000000, 0.000000)" stroke="#FFFFFF" strokeLinecap="round"
             strokeLinejoin="round" strokeWidth="4">
            <path
  d="M48.6538086,95.0615234 L48.6538086,68.4004374 C52.3126628,65.7656406 56.7613932,64.4482422 62,64.4482422 C67.2386068,64.4482422 71.6787109,65.7656406 75.3203125,68.4004374 L75.3203125,94.7143914"
  id="Path-35"/>
            <path d="M62,65 L62,95.435783" id="Path-36"/>
            <path d="M11,54 L11,100" id="Path-36-Copy"/>
            <path d="M23,54 L23,100" id="Path-36-Copy-2"/>
            <path d="M0,110 L125,110" id="Path-34"/>
            <path d="M8,53 L114,53" id="Path-34-Copy-5"/>
            <path d="M7,102 L27.0249844,102" id="Path-34-Copy"/>
            <path d="M96,102 L116.024984,102" id="Path-34-Copy-2"/>
            <path d="M41,102 L83,102" id="Path-34-Copy-3"/>
            <path d="M58,87 L66,87" id="Path-34-Copy-4"/>
            <path d="M100,54 L100,100" id="Path-36-Copy-3"/>
            <path d="M112,54 L112,100" id="Path-36-Copy-4"/>
            <path
  d="M11,46 L11,31 C11,24.372583 16.372583,19 23,19 L100,19 C106.627417,19 112,24.372583 112,31 L112,45.4396446"
  id="Path-37"/>
            <polyline id="Path-38"
  points="62 18.5 62 0 75.3630367 0 75.3630367 7.68479858 67.2254872 7.68479858"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Org;