const {HOT_API_URL} = (window as any).env;
const {NODE_ENV} = process.env;
export enum STATUS_CODES {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403
}
export enum TIMEOUTS {
  REQUEST = 20000,
}
export enum URLS {
  BASE_API_URL = ( NODE_ENV === "development") ? HOT_API_URL : HOT_API_URL,
}
