declare global {
  interface Number {
    prettify(afterComma?: number): string,
  }
}

Number.prototype.prettify = function (afterComma = 2) {
  const exponent = +this.toExponential().split('+')[1];
  if (exponent >= 3 && exponent < 6) return (this as number / 1e3).toFixed(afterComma) + "K";
  if (exponent >= 6 && exponent < 9) return (this as number / 1e6).toFixed(afterComma) + "M";
  if (exponent >= 9 && exponent < 12) return (this as number / 1e9).toFixed(afterComma) + "B";
  return (this + "").indexOf(".") >= 0 ? this.toFixed(2) : this + "";
}

export {}
