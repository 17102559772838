import React from 'react';

const BagWhite = () =>
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="HoT_LandingPage1440px_Shop" transform="translate(-1180.000000, -55.000000)" stroke="#FFFFFF" strokeWidth="2">
            <g id="button-play-now" transform="translate(1155.000000, 42.000000)">
                <g id="icon-store" transform="translate(26.000000, 14.000000)">
                    <path d="M17.6666757,0.540540541 C17.6666757,0.242216216 17.4244595,0 17.1261351,0 L2.7027027,0 C2.40437838,0 2.16216216,0.242216216 2.16216216,0.540540541 L0,19.2882973 C0,19.5866216 0.242216216,19.8288378 0.540540541,19.8288378 L19.2882973,19.8288378 C19.5866216,19.8288378 19.8288378,19.5866216 19.8288378,19.2882973 L17.6666757,0.540540541 Z" id="Stroke-1"/>
                    <path d="M6,4 C7.10162162,7.85562162 13.1604324,8.40640541 14.8128108,4" id="Stroke-2"/>
                </g>
            </g>
        </g>
    </g>
</svg>;

export default BagWhite;
