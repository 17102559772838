import React, {useState, FC, createContext} from 'react'
import Modal from 'components/Modal/Modal'

interface ModalInterface {
  modal: boolean;
  modalContent: any;
  handleModal: (content: any) => any;
  closeModal: () => void,
}

export const ModalContext = createContext<ModalInterface>({
  modal: false,
  modalContent: {},
  handleModal: (content: any) => {
  },
  closeModal: () => {
  },
})
const useModal = () => {
  let [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  let [modalContent, setModalContent] = useState<any>({
    text: 'I\'m the Modal Content',
    component: () => null,
  })

  let handleModal = (content = false) => {
    setIsModalVisible(!isModalVisible)
    if (content) {
      setModalContent(content)
    }
  }

  const closeModal = () => setIsModalVisible(false)

  return {modal: isModalVisible, handleModal, modalContent, closeModal}
}
const ModalContextProvider: FC = ({children}) => {
  let {modal, handleModal, modalContent, closeModal} = useModal()
  return (
    <ModalContext.Provider value={{modal, handleModal, modalContent, closeModal}}>
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider
