import React, {useEffect, useState} from 'react';
import Icon from "components/shared/Icon/Icon";
import {notificationInterface} from "interfaces/misc";
import {MIN_NOTIFICATION_TIME} from "config/misc";
import './Notification.scss';

interface NotificationPropsInterface {
  notification: notificationInterface,
  onClose: (n: notificationInterface) => void
}

const Notification: React.FC<NotificationPropsInterface> = ({notification, onClose}) => {

  const [timer, setTimer] = useState<number | null>(null);

  const killTimer = (e: React.MouseEvent) => {
    e.preventDefault();
    timer && clearTimeout(timer);
    setTimer(null);
  };

  const createTimer = () => {
    setTimer( window.setTimeout(() => onClose(notification), MIN_NOTIFICATION_TIME) )
  };

  const closeNotification = () => {
    onClose(notification)
  }

  useEffect(() => {
    createTimer();
    return () => {timer && clearTimeout(timer)};
  }, []); // eslint-disable-line

  return (
    <div className={`Notification ${notification.type}`}
         onMouseOverCapture={killTimer}
         onMouseOutCapture={createTimer}
    >
      <Icon className="Notification-close" name="close" onClick={closeNotification} />
      <div className="Notification-title">
        { notification.type === "success" && "Success" }
        { notification.type === "info"    && "Info"    }
        { notification.type === "warning" && "Warning" }
        { notification.type === "error"   && "Error"   }
      </div>
      <div className="Notification-message">
        { notification.message }
      </div>
    </div>
  );
};

export default Notification;
