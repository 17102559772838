import React, {Component, ReactNode, ErrorInfo, FC} from "react";
import "./ErrorBoundaries.scss";

const {NODE_ENV} = process.env;

interface Props {
  children: ReactNode;
  FallbackComponent?: FC<any>;
}

interface State {
  hasError: boolean;
  errorInfo: ErrorInfo | null;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorInfo: null,
    error: null,
  };

  public static getDerivedStateFromError(error: any) {

    return {hasError: true};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {

    this.setState({errorInfo, error});
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    const {hasError, errorInfo, error} = this.state;
    const {FallbackComponent} = this.props;

    if (hasError) {
      if (FallbackComponent) {
        return <FallbackComponent {...this.state} />;
      }
      return (
        <div className="ErrorBoundary">
          <div className="ErrorBoundary-header">
            <h2 className="ErrorBoundary-title">Oops! There's an error!</h2>
            <div className="ErrorBoundary-text">Sorry, we are trying to solve this issue. Reload the page or try again later!</div>
          </div>
          <button className="ErrorBoundary-reload-btn" onClick={() => window.location.reload()}>
            Reload Page.
          </button>
          {NODE_ENV === "development" && (
            <>
              <details className="ErrorBoundary-details">
                <summary>Click for error details</summary>

                <pre className="ErrorBoundary-stack">{error && error.stack?.toString()}</pre>
              </details>
              <details className="ErrorBoundary-details">
                <summary>Click for component stack error details</summary>

                <pre className="ErrorBoundary-stack">{errorInfo && errorInfo.componentStack.toString()}</pre>
              </details>
            </>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
