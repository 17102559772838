import React from "react";
import Icon from "components/shared/Icon/Icon";
import classNames from "classnames";

const mobileSliderOptions = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
};
function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <div
      className={classNames("slick-next-arrow arrow")}
      style={{ ...style }}
      onClick={onClick}
    >
      <Icon name={"next-arrow"} />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;

  return (
    <div
      className={classNames("slick-prev-arrow arrow")}
      style={{ ...style }}
      onClick={onClick}
    >
      <Icon name={"prev-arrow"} />
    </div>
  );
}

const quotesSliderOptions = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <SampleNextArrow/>,
  prevArrow: <SamplePrevArrow/>,
  responsive: [
    {
      breakpoint: 676,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};

export const orgBoardSliderOptions = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 676,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};
export { quotesSliderOptions, mobileSliderOptions };
