import React from "react";
import Quotes from "assets/img/landingpage/img-quotes-bubble@2x.png";
import Slider from "react-slick";
import {quotesSliderOptions} from "pages/Welcome/sliderOptions";
import CaryAvatar from "assets/img/landingpage/img-quotes-cary-avatar.png";
import GusAvatar from "assets/img/landingpage/img-quotes-gus-avatar.png";
import "./WelcomePageQuotes.scss"
import {getDefaultAvatar} from "../../../helpers/misc";
import {TEAMS} from "../../../config/names";

const WelcomePageQuotes : () => JSX.Element = () => {
  const persons = [{
    imgSrc: CaryAvatar,
    fullName: "Cary Ceroni",
    subTitle: "PhysEd Teacher, Burlington ON",
    review: "HoT has been so engaging for my students, and has helped them to push themselves and reach goals they" +
      " never thought they’d accomplish. It’s amazing to see them so excited about being active."
  },{
    imgSrc: GusAvatar,
    fullName: "Gus Galloway",
    subTitle: "Student, Burlington ON",
    review: "It’s been awesome to compete against my friends, while learning to love running. I didn’t realize how" +
      " good it felt to get outdoors and get my steps in."
  },{
    imgSrc: getDefaultAvatar(TEAMS.ATMOS),
    fullName: "Simone Darby",
    subTitle: "Student, Fresno CA",
    review: "I don't know what I'd do without HoT. It's helped me get in shape and make the process fun. Normally," +
      " exercising can be difficult and boring, but I always feel engaged and excited when working out with HoT."
  },{
    imgSrc: getDefaultAvatar(TEAMS.MAVRIC),
    fullName: "William Redford",
    subTitle: "Student, Los Angeles CA",
    review: "I've loved my time playing HoT so far! It's been a great way to motivate myself to exercise and engage" +
      " in positive community competitions with my friends and other schools."
  }]

  return (
    <div className='WelcomePage-Quotes'>
      <div className='container'>
        <h2 className='WelcomePage-Quotes title'>Player Reviews</h2>
        <div className='WelcomePage-Quotes-content'>
          <div className='WelcomePage-Quotes-about'>
            <img src={Quotes} alt='quotes' className='WelcomePage-Quotes-about-img'/>
            <h3 className='WelcomePage-Quotes-subtitle'>What they say?</h3>
          </div>
          <div className='WelcomePage-Quotes-slider'>
            <Slider {...quotesSliderOptions}>
              { persons.map(person => (
                <div className='wr' key={person.fullName}>
                  <div className='WelcomePage-Quotes-slider-content'>
                    <div className='WelcomePage-Quotes-slider-content-header'>
                      <img src={ person.imgSrc } alt='mobile-Map' className='WelcomePage-Quotes-slider-content-header-img' width={50} height={50} />
                      <div>
                        <h4 className='WelcomePage-Quotes-slider-content-header-name'>{ person.fullName }</h4>
                        <p className='WelcomePage-Quotes-slider-content-header-subtitle'>{ person.subTitle }</p>
                      </div>
                    </div>
                    <p className='WelcomePage-Quotes-slider-content-description'>{ person.review }</p>
                  </div>
                </div>
              )) }
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomePageQuotes