import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "context/Modal";
import classNames from "classnames";
import "./Modal.scss";

const Modal = () => {
  const { modalContent, handleModal, modal } = useContext(ModalContext);
  const { component: Component, ...rest } = modalContent;
  const [el] = useState(document.createElement("div"));
  const [modalState, closeModal] = useState<boolean>(false);
  const [modalClass, setClassName] = useState<string | null>(null);

  useEffect(() => {
    document.body.appendChild(el).className = `modal-root`;

    return () => {
      document.body.removeChild(el);
    };
  }, []);

  useEffect(() => {
    const ANIMATION_EXIT_TIME = modal ? 0 : 250;
    setTimeout(() => closeModal(modal), ANIMATION_EXIT_TIME);
    modalContent?.animation && setClassName(modalContent.animation);
  }, [modal]);

  const handleClose = (e: SyntheticEvent) => {
    if (!e) return;
    const target = e.target as HTMLElement;
    if (!target.classList.contains("Modal-container")) return;
    handleModal({});
  };

  if (modalState) {
    return ReactDOM.createPortal(
      <div className="Modal-container" onClick={handleClose}>
        <div
          className={classNames(
            modalContent?.contentWrapperClass,
            "Modal-container-content",
            modalContent?.animation && modalContent.animation,
            !modal && modalClass + "-exit"
          )}
        >
          {Component && <Component {...rest} toggleModal={handleModal} modal={modal}/>}
        </div>
      </div>,
      el
    );
  } else return null;
};

export default Modal;
