import React, {Suspense, useContext} from "react";
import {Switch} from "react-router-dom";
import {GlobalContext} from "context/Global";
import Portal from "components/shared/Portal/Portal";
import Notification from "components/shared/Notification/Notification";
import ScrollToTop from "components/shared/ScrollToTop/ScrollToTop";
import AppRoute from "components/shared/RouterDecorators/AppRoute";
import ErrorBoundary from "components/ErrorBoundaries/ErrorBoundaries";
import {appSections, routes} from "config/urls";
import MainLayout from "layouts/MainLayout/MainLayout";
import ThemeContextProvider from "context/Theme";
import "config/NumberObjExt";
import "./App.scss";
import AppLoader from "components/AppLoader/AppLoader";
import Welcome from "../../pages/Welcome/Welcome";
import WelcomePage from "../../pages/Welcome/WelcomePage";

const AuthPages = React.lazy(() => import("pages/Auth/Auth"));
const BoardPages = React.lazy(() => import("pages/Boards/Boards"));
const InfoPages = React.lazy(() => import("pages/InfoPages/InfoPages"));
const Statistics = React.lazy(() => import("pages/Statistics/Statistics"));
const Manager = React.lazy(() => import("pages/Manager/Manager"));
const Admin = React.lazy(() => import("pages/Admin/Admin"));
const UserPages = React.lazy(() => import("pages/UserPage/UserPage"));
const OrgPage = React.lazy(() => import("pages/Organisation/Organisation"));
const ManageTeams = React.lazy(() => import("pages/ManageTeamPage/ManageTeamPage"));
const Invites = React.lazy(() => import("pages/Invites/Invites"));
const Events = React.lazy(() => import("pages/Events/Events"));
const InviteCode = React.lazy(() => import("pages/InviteCode/InviteCode"));
const JoinPage = React.lazy(() => import("pages/JoinPage/JoinPage"));
const DeepLinkPage = React.lazy(() => import("pages/DeepLink/DeepLink"));
const NotFound = React.lazy(() => import("pages/NotFoundPage/NotFoundPage"));

const App = () => {
  const {notifications, hideNotification} = useContext(GlobalContext);

  return (
    <ErrorBoundary>
        <Suspense fallback={null}>
          {notifications && hideNotification && !!notifications.length && (
            <Portal className="Notifications-set">
              {notifications.map((item, i) => (
                <Notification notification={item} key={i} onClose={hideNotification} />
              ))}
            </Portal>
          )}
          <Switch>
            <AppRoute path={routes[appSections.MAIN]} component={WelcomePage} exact />
            <AppRoute path={routes[appSections.WELCOME].main} component={Welcome} />
            <AppRoute path={routes[appSections.DEEP_LINK].main} component={DeepLinkPage} />
            <AppRoute path={routes[appSections.AUTH].main} component={AuthPages}/>
            <AppRoute path={routes[appSections.EVENTS].main} component={Events} />
            <AppRoute path={routes[appSections.JOIN].main} component={JoinPage} />
            <AppLoader>
              <ThemeContextProvider>
                <ScrollToTop />
                <MainLayout>
                  <Suspense fallback={null}>
                    <Switch>
                      {/*{user === null && <AppRedirect to={routes[appSections.AUTH].signin} />}*/}
                      <AppRoute path={routes[appSections.BOARDS].board} component={BoardPages} />
                      <AppRoute path={routes[appSections.STATS].main} component={Statistics} />
                      <AppRoute path={routes[appSections.INFO].main} component={InfoPages} />
                      <AppRoute path={routes[appSections.MANAGER].main} component={Manager} />
                      <AppRoute path={routes[appSections.ADMIN].main} component={Admin} />
                      <AppRoute path={routes[appSections.USER].main} component={UserPages} />
                      <AppRoute path={routes[appSections.ORG].main} component={OrgPage} />
                      <AppRoute path={routes[appSections.TEAMS].teams} component={ManageTeams} />
                      <AppRoute path={routes[appSections.INVITES].main} component={Invites} />
                      <AppRoute path={routes[appSections.INVITE_CODE].main} component={InviteCode} />
                      <AppRoute component={NotFound} />
                    </Switch>
                  </Suspense>
               </MainLayout>

              </ThemeContextProvider>
            </AppLoader>
          </Switch>
        </Suspense>
    </ErrorBoundary>
  );
};

export default App;
