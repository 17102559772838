import React, {ReactElement, useContext} from "react";
import Header from "components/shared/Header/Header";
import {GlobalContext} from "context/Global";
import FooterNavigation from "components/FooterNavigation/FooterNavigation";
import "./MainLayout.scss";


/**
 * Interface for StandardLayout component props
 */
interface MainLayoutPropsInterface {
  children: ReactElement;
}

const MainLayout: React.FC<MainLayoutPropsInterface> = ({children}) => {
  const {layoutParams} = useContext(GlobalContext);

  if (!layoutParams?.main?.cover) {
    return children;
  }

  const {header, main, footer} = layoutParams;

  return (
    <div className='MainLayout'>
      <Header title={header?.title}
              className={header?.className}
              avatar={header?.avatar} />
      <main className={`MainLayout-main ${main?.className}`}>
        {children}
      </main>
      <footer className={`MainLayout-footer ${footer?.className || ""}`}>
        {footer?.children}
        <FooterNavigation/>
      </footer>
    </div>
  );
};

export default MainLayout;
