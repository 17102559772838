import React, {useContext} from "react";
import AppLink from "components/shared/RouterDecorators/AppLink";
import Icon from "components/shared/Icon/Icon";
import {UserContext} from "context/User";
import {navLinks} from "config/nav";
import Image from "components/shared/Image/Image";
import {boardTypes, MenuNavTitleText, userRoles} from "config/names";
import {useCustomHistory} from "hooks/redirects";
import {getDefaultAvatar, getURLQueryParams} from "helpers/misc";
import classNames from "classnames";
import {ThemeContext} from "context/Theme";
import "./Header.scss";

/**
 * Interface for Header component props
 */
interface HeaderPropsInterface {
  title?: string;
  className?: string;
  avatar?: string;
}
/**
 Header component
 */
const Header: React.FC<HeaderPropsInterface> = ({
  title,
  className,
  avatar
}) => {
  const {theme} = useContext(ThemeContext);
  const {logout, user, notifications} = useContext(UserContext);
  const {types} = getURLQueryParams();
  const {goBack} = useCustomHistory();
  const linkByRole = user?.isOrganizationManager ? userRoles.MANAGER : userRoles.USER;

  return (
    <header className={`Header ${className || ""}`}>
      <Icon name="arrow-left" className="Header-backBtn" onClick={goBack} />
      <div className="Header-section">
        {avatar && <Image src={avatar}
                          alt="member-avatar"
                          className="Header-section-avatar avatar"
        />}
        {title && <div className="Header-section-name" data-testid="header-section-name">
                    {title || ""}
                  </div>
        }
      </div>
      <input id="burger-menu-controller" type="checkbox" />
      {
        user?.id && (
          <label htmlFor="burger-menu-controller" className="Header-nav-icon">
            {!!notifications && <span className="notification-mark" />}
          </label>
        )
      }
      <label htmlFor="burger-menu-controller" className="Header-nav-backdrop" />
      <nav className="Header-nav">
        <Image src={user?.avatar || getDefaultAvatar(theme?.name)} alt="user-avatar" className="Header-nav-avatar avatar" />

        {navLinks[linkByRole].map(
          ({link, name}) =>
            (!!user?.organizations?.length || name !== MenuNavTitleText.ORGANIZATIONS) && (
              <AppLink to={link} key={name} className="Header-nav-item" activeClassName={
                  classNames({activeLink : !(types && types === boardTypes.CLASS_BOARD)})
              }>
                <span>{name}</span>
                {name === "Notifications" && !!notifications && <span className="notification-mark-number">{notifications}</span>}
              </AppLink>
            ),
        )}
        <button className="Header-nav-logout lined" onClick={logout}>
          Sign Out
        </button>
      </nav>
    </header>
  );
};

export default Header;
