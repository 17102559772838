import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Eye: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="64" height="35" viewBox="0 0 64 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Eye-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="App_Game_IPhoneX_Board_8_SignIn" transform="translate(-909.000000, -1327.000000)" fill="#A5ADBA" fillRule="nonzero">
        <g id="icon-password-eye" transform="translate(909.000000, 1327.000000)">
          <path d="M63.2666667,16.2211538 C60.2,12.1826923 49.4666667,0.0673076923 32,0.0673076923 C14.5333333,0.0673076923 3.86666667,12.1826923 0.733333333,16.2211538 C0.133333333,16.9615385 0.133333333,18.0384615 0.733333333,18.7788462 C3.8,22.8173077 14.5333333,34.9326923 32,34.9326923 C49.4666667,34.9326923 60.1333333,22.8173077 63.2666667,18.7788462 C63.8,18.0384615 63.8,16.9615385 63.2666667,16.2211538 Z M32,30.4903846 C24.8666667,30.4903846 19.1333333,24.7019231 19.1333333,17.5 C19.1333333,10.2980769 24.8666667,4.50961538 32,4.50961538 C39.1333333,4.50961538 44.8666667,10.2980769 44.8666667,17.5 C44.8666667,24.7019231 39.1333333,30.4903846 32,30.4903846 Z" id="Shape"></path>
          <ellipse id="Oval" cx="32" cy="17.5" rx="8.26666667" ry="8.34615385"></ellipse>
        </g>
      </g>
    </g>
  </svg>
);

export default Eye;