import React, {useContext, useEffect, useRef, useState} from 'react';
import AppLink from 'components/shared/RouterDecorators/AppLink';
import TrailerBtn from 'components/shared/Icon/icons/TrailerBtn';
import Enterprise from 'assets/img/landingpage/Left-Photo-Enterprise2x.jpg';
import EnterprisTablet from 'assets/img/landingpage/Left-Photo-Enterprise2x-mobile.jpg';
import School from 'assets/img/landingpage/Right-Photo-School@2x.jpg'
import BrandMobile from 'assets/img/landingpage/Left-Photo-Brand.jpg'
import Map from 'assets/img/landingpage/features-mock-slide1-map@2x.png'
import Strike from 'assets/img/landingpage/features-mock-slide2-strike@2x.png'
import Dashboard from 'assets/img/landingpage/features-mock-slide3-dashboard@2x.png'
import Icon from 'components/shared/Icon/Icon';
import Slider from 'react-slick';
import {numberWithCommas, scrollTo} from 'helpers/misc';
import TrailerPhone from 'assets/img/landingpage/mobile.png'
import {mobileSliderOptions} from 'pages/Welcome/sliderOptions';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {GlobalContext} from 'context/Global';
import {api} from 'config/urls';
import {useApi} from 'api/useApi';
import {ILandingStatistics} from 'interfaces/landing';
import {ModalContext} from 'context/Modal';
import Trailer from 'components/Trailer/Trailer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-circular-progressbar/dist/styles.css';
import WelcomePageQuotes from './WelcomePageQuotes/WelcomePageQuotes';
import './WelcomePage.scss';
import HotNavbar from '../../components/HomePage/HotNavbar';
import HotFooter from '../../components/HomePage/HotFooter';
import HotJoinSection from '../../components/HomePage/HotJoinSection';
import useQuery from '../../hooks/useQuery';


const DEFAULT_LEADERS = [{
    hpAmount: 0,
    username: 'noname_1',
    position: 1,
}, {
    hpAmount: 0,
    username: 'noname_2',
    position: 2,
}, {
    hpAmount: 0,
    username: 'noname_3',
    position: 3,
}]
const SHOP_LINK = 'https://teespring.com/stores/healthovertime';
const TEST_FLIGHT_LINK = 'https://testflight.apple.com/join/lE3KeD4k'

const WelcomePage: React.FC = () => {
    const brandRef = useRef<HTMLDivElement>(null);
    const schoolRef = useRef<HTMLDivElement>(null);
    const enterpriseRef = useRef<HTMLDivElement>(null);
    const {handleModal} = useContext(ModalContext);
    const {changeLayoutParams} = useContext(GlobalContext);

    const [statistics, setStatistic] = useState<ILandingStatistics | null>(null);
    const {apiCall} = useApi();

    const smoothlyNavigateTo = (value?: string) => {
        switch (value) {
            case 'Brands':
                brandRef.current && scrollTo(brandRef.current);
                return;
            case 'School':
                schoolRef.current && scrollTo(schoolRef.current);
                return;
            case 'Enterprise':
                enterpriseRef.current && scrollTo(enterpriseRef.current);
                return;
            default:
                return scrollTo();
        }
    }

    let query = useQuery()

    useEffect(() => {
        smoothlyNavigateTo(query?.get('section') || '')
    }, [query])

    useEffect(() => {
        apiCall({url: api.landing.statistic})
            .then(({data: {response}}) => setStatistic({
                ...response,
                leaderboard: response?.leaderboard && (response?.leaderboard?.length) ? response.leaderboard : DEFAULT_LEADERS,
            }))
    }, []) //eslint-disable-line

    useEffect(() => {
        changeLayoutParams?.({main: {cover: false}});
    }, [changeLayoutParams]);

    const openTrailerModal = () => {
        handleModal?.({
            component: Trailer,
            animation: 'slideUp',
            contentWrapperClass: 'trailer-wrapper',
        })
    }

    return (
        <div className="WelcomePage">
            <div className="Wave-background">
                <HotNavbar/>
                <div className="WelcomePage-Join">
                    <div className="container">
                        <div className="WelcomePage-Join-info">
                            <h2 className="WelcomePage-Join-info-title">Making the world a healthier place, one player
                                at a time.</h2>
                            <p className="WelcomePage-Join-info-description"> Project HoT is a challenge-based global
                                fitness game
                                where players choose a tribe and compete against friends and family, their local
                                community and the
                                world.</p>
                            <AppLink to={{pathname: TEST_FLIGHT_LINK}} target="_blank"
                                     className="WelcomePage-Join-info-play-btn purple">Play for Free. Click to
                                download</AppLink>
                            <AppLink target="_blank" rel="noreferrer" to={{pathname: SHOP_LINK}}
                                     className="WelcomePage-Join-info-shop">
                                <div className="WelcomePage-Join-info-shop-img">
                                    <Icon name="bag-gradient" className="WelcomePage-Join-info-shop-bag"/>
                                </div>
                                <div className="WelcomePage-Join-info-shop-details">
                                    <h4 className="WelcomePage-Join-info-shop-title">Shop now high-quality unique Hot
                                        Gaming T-Shirts &
                                        more gadgets.</h4>
                                </div>
                            </AppLink>
                        </div>
                        <div className="WelcomePage-Join-trailer">
                            <img src={TrailerPhone} alt="phone"/>
                            <div className="WelcomePage-Join-trailer-watch">
                                <TrailerBtn className="WelcomePage-Join-trailer-btn" onClick={openTrailerModal}/>
                                <div className="WelcomePage-Join-trailer-description">Watch the trailer</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="WelcomePage-statistics container">
                <div className="WelcomePage-statistics-item">
                    <div
                        className="WelcomePage-statistics-item-count">{numberWithCommas(statistics?.totalSteps || 0)}</div>
                    <div className="WelcomePage-statistics-item-description">total steps and counting</div>
                </div>
                <div className="WelcomePage-statistics-item">
                    <div
                        className="WelcomePage-statistics-item-count">{numberWithCommas(statistics?.totalChallenges?.totalCount || 0)}</div>
                    <div className="WelcomePage-statistics-item-description">challenges completed</div>
                </div>
                <div className="WelcomePage-statistics-item">
                    <CircularProgressbarWithChildren className="WelcomePage-statistics-item-progressBar" strokeWidth={4}
                                                     value={75} styles={
                        {
                            path: {stroke: `#FFFFFF`, strokeWidth: '3px'},
                            trail: {
                                stroke: `rgba(255, 255, 255, .3)`,
                                strokeWidth: '3px',
                            },
                        }
                    }>
                        <div
                            className="WelcomePage-statistics-item-count">{numberWithCommas(statistics?.totalDistrictsTaken || 0)}</div>
                        <div className="WelcomePage-statistics-item-description">taken districts</div>
                    </CircularProgressbarWithChildren>
                </div>
                <div className="WelcomePage-statistics-item">
                    <h2 className="WelcomePage-statistics-item-boardTitle">Leaderboard</h2>
                    <ol className="WelcomePage-statistics-item-boardList">
                        {statistics?.leaderboard && statistics?.leaderboard?.length ? statistics?.leaderboard.map(({
                                                                                                                       username,
                                                                                                                       hpAmount,
                                                                                                                   }) =>
                                <li className="WelcomePage-statistics-item-boardItem" key={username}>
                                    <div className="WelcomePage-statistics-item-boardItem-name">{username || 'noname'}</div>
                                    <div
                                        className="WelcomePage-statistics-item-boardItem-score">{hpAmount?.prettify() || 0}</div>
                                </li>) :
                            <>
                                <li className="WelcomePage-statistics-item-boardItem">
                                    <div className="WelcomePage-statistics-item-boardItem-name">StevenAmato</div>
                                    <div className="WelcomePage-statistics-item-boardItem-score">6,43M</div>
                                </li>
                                <li className="WelcomePage-statistics-item-boardItem">
                                    <div className="WelcomePage-statistics-item-boardItem-name">Arhutch1007</div>
                                    <div className="WelcomePage-statistics-item-boardItem-score">5,2M</div>
                                </li>
                                <li className="WelcomePage-statistics-item-boardItem">
                                    <div className="WelcomePage-statistics-item-boardItem-name">Caryceroni</div>
                                    <div className="WelcomePage-statistics-item-boardItem-score">4,22M</div>
                                </li>
                            </>
                        }


                    </ol>
                </div>
            </div>
            <div className="WelcomePage-features">
                <div className="container">
                    <div className="WelcomePage-features-header">
                        <h2 className="WelcomePage-features-header-title">Our top features in HoT</h2>
                        <p className="WelcomePage-features-header-description">We believe that sustainable fitness is
                            achieved when
                            you’re able to combine challenging exercises with fun and engaging gameplay. All of our
                            features are
                            designed with this core belief in mind.
                        </p>
                    </div>
                    <ul className="WelcomePage-features-list">
                        <li className="WelcomePage-features-list-item">
                            <div className="WelcomePage-features-list-item-img"><Icon name="walkMan"/></div>
                            <div className="WelcomePage-features-list-item-details">
                                <h3 className="WelcomePage-features-list-item-details-head">Fitness Challenges</h3>
                                <p className="WelcomePage-features-list-item-details-description">Try to beat our
                                    curated fitness
                                    challenges and complete daily challenges to earn HP and XP. The more steps you take
                                    in a day, the
                                    better reward you get!</p>
                            </div>
                        </li>
                        <li className="WelcomePage-features-list-item">
                            <div className="WelcomePage-features-list-item-img"><Icon name="launch"/></div>
                            <div className="WelcomePage-features-list-item-details">
                                <h3 className="WelcomePage-features-list-item-details-head">Launch Strikes</h3>
                                <p className="WelcomePage-features-list-item-details-description">Use HP to Launch
                                    Remote Strikes
                                    against districts across the world
                                    and fight to take control your district.</p>
                            </div>
                        </li>
                        <li className="WelcomePage-features-list-item">
                            <div className="WelcomePage-features-list-item-img"><Icon name="recycle"/></div>
                            <div className="WelcomePage-features-list-item-details">
                                <h3 className="WelcomePage-features-list-item-details-head">Global District
                                    Competition</h3>
                                <p className="WelcomePage-features-list-item-details-description">Participating in
                                    district battles with
                                    Strikes will assist your tribe in becoming the #1. If your tribe is able to emerge
                                    with the most
                                    districts controlled at the end of the season, there will be some special rewards
                                    for the victors!</p>
                            </div>
                        </li>
                        {/*<li  className='WelcomePage-features-list-item'>*/}
                        {/*  <div className='WelcomePage-features-list-item-img'><Icon name='steps'/></div>*/}
                        {/*  <div className='WelcomePage-features-list-item-details'>*/}
                        {/*    <h3 className='WelcomePage-features-list-item-details-head'>Steps Tracking</h3>*/}
                        {/*    <p className='WelcomePage-features-list-item-details-description'>Allowing you to track different physical activities, which can be performed by users outside of their homes.</p>*/}
                        {/*  </div>*/}
                        {/*</li>*/}
                        <li className="WelcomePage-features-list-item">
                            <div className="WelcomePage-features-list-item-img"><Icon name="heart2"/></div>
                            <div className="WelcomePage-features-list-item-details">
                                <h3 className="WelcomePage-features-list-item-details-head">Randomized Map
                                    Challenges</h3>
                                <p className="WelcomePage-features-list-item-details-description">In addition to our
                                    curated challenges,
                                    randomly generated challenges spawn on the map and can be accessed if you’re close.
                                    Completing them
                                    will reward significantly more HP/XP than normal, in addition to any bonuses earned
                                    if the challenge
                                    is especially difficult.</p>
                            </div>
                        </li>
                        <li className="WelcomePage-features-list-item">
                            <div className="WelcomePage-features-list-item-img"><Icon name="community"/></div>
                            <div className="WelcomePage-features-list-item-details">
                                <h3 className="WelcomePage-features-list-item-details-head">Compete in your
                                    Community </h3>
                                <p className="WelcomePage-features-list-item-details-description">With our customizable
                                    leaderboards,
                                    you can track all of your gains with whoever you want, whether it’s family members,
                                    friends,
                                    neighbors, states, and the world! It’s a fun way to both view how far you’ve and
                                    your board-mates have
                                    come, as well promoting some healthy competition between everyone.</p>
                            </div>
                        </li>
                        <li className="WelcomePage-features-list-item">
                            <div className="WelcomePage-features-list-item-slider">
                                <Slider {...mobileSliderOptions}>
                                    <div className="slick-img">
                                        <img src={Map} alt="mobile-Map"/>
                                    </div>
                                    <div className="slick-img">
                                        <img src={Strike} alt="mobile-Strike"/>
                                    </div>
                                    <div className="slick-img">
                                        <img src={Dashboard} alt="mobile-Dashboard"/>
                                    </div>

                                </Slider>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <WelcomePageQuotes/>
            <div className="WelcomePage-About">
                <div className="WelcomePage-About-background">
                    <div className="WelcomePage-About-content About-Wrapper" ref={brandRef}>
                        <div className="WelcomePage-About-content-image">
                            <img src={BrandMobile} alt="alt"/>
                        </div>
                        <div className="WelcomePage-About-content-text">
                            <h3>Brands</h3>
                            <p>Be part of the global fitness gaming audience. With the physical activity market now
                                valued at $828.2
                                billion globally (GWI), in what has become an increasingly fast-moving and competitive
                                landscape, for
                                fitness operators, innovation has never been more crucial. </p>
                            <div className="WelcomePage-About-content-findMore">
                                <div>Find out more</div>
                                <Icon name="link-arrow"/></div>
                        </div>
                    </div>
                </div>
                <div className="WelcomePage-About-background">
                    <div className="WelcomePage-About-content  About-Wrapper" ref={schoolRef}>
                        <div className="WelcomePage-About-content-image">
                            <img src={School} alt="Graduation"/>
                        </div>
                        <div className="WelcomePage-About-content-text">
                            <h3>School</h3>
                            <p>Over the years, we've come to realize the necessity of promoting fitness in kids.
                                However, in our time
                                studying this issue, we’ve learned that if it’s not fun and engaging, kids won’t
                                participate. A prime
                                example of this is school PE programs, which have limited to no engagement from kids
                                these days. With
                                HoT, we hope to improve and modernize school PE programs in order to promote health and
                                wellness, get
                                kids excited about fitness, and reconnect them in a time when many people are feeling
                                distant from their
                                peers.</p>
                            <div className="WelcomePage-About-content-findMore">
                                <div>Find out more</div>
                                <Icon name="link-arrow"/></div>
                        </div>
                    </div>
                </div>
                <div className="WelcomePage-About-background">
                    <div className="WelcomePage-About-content  About-Wrapper" ref={enterpriseRef}>
                        <div className="WelcomePage-About-content-image">
                            <picture>
                                <source srcSet={EnterprisTablet}
                                        media="(max-width: 1110px) and (min-width: 676px)"/>
                                <img src={Enterprise} alt="alt"/>
                            </picture>
                            {/*<img src={Enterprise} alt='city'/>*/}
                        </div>
                        <div className="WelcomePage-About-content-text">
                            <h3>Enterprise</h3>
                            <p>We live in an increasingly hyper-targeted/personalized world. The rapid growth
                                and success of fitness apps like HoT – For marketing, building a community around a
                                shared passion that
                                also recognizes local needs and sub-segments within
                                the community is growing in importance. Provide the flexibility within the community to
                                allow members to
                                engage at a micro level by creating/following groups, segments, or friend connections
                                where they can
                                share and engage at
                                a more personal level.
                            </p>
                            <div className="WelcomePage-About-content-findMore">
                                <div>Find out more</div>
                                <Icon name="link-arrow"/></div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className='WelcomePage-Trusted'>*/}
            {/*  <div className='container'>*/}
            {/*    <h2 className='title'>*/}
            {/*      Trusted by the world’s best*/}
            {/*    </h2>*/}
            {/*    <div className='WelcomePage-Trusted-icons'>*/}
            {/*        <img className='WelcomePage-Trusted-icon' src={Google} alt='google'/>*/}
            {/*        <img className='WelcomePage-Trusted-icon' src={Facebook} alt='Facebook'/>*/}
            {/*        <img className='WelcomePage-Trusted-icon' src={Slack} alt='Slack'/>*/}
            {/*        <img className='WelcomePage-Trusted-icon' src={Amazon} alt='Amazon'/>*/}
            {/*        <img className='WelcomePage-Trusted-icon' src={Viber} alt='Viber'/>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <HotJoinSection/>
            <HotFooter/>
        </div>
    );
};
export default WelcomePage;
