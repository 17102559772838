import axios, {AxiosError} from "axios";
import {THEME, TOKEN, USER_DATA} from "config/names";
import {deleteEmptyProps} from "helpers/misc";
import {appSections, routes} from "../config/urls";
import {HISTORY} from "../interfaces/misc";
import {userInterface} from "../interfaces/user";

const {HOT_API_URL} = (window as any).env;
const {NODE_ENV} = process.env;

const configuredAxios = (history: HISTORY, removeUser?: (data: userInterface | null) => void, logout?: any) => () => {
  const http = axios.create({
    timeout: 20000,
    baseURL: HOT_API_URL,
  });
  http.interceptors.request.use(
    (config) => {
      const user_token = localStorage.getItem(USER_DATA) || "";
      config.params && (config.params = deleteEmptyProps(config.params));
      user_token && (config.headers[TOKEN] = user_token);

      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );
  http.interceptors.response.use(
    (r) => r,
    (error: AxiosError) => {
      if (error?.response?.status === 403) {
          if (logout) {
              logout()
          } else {
              localStorage.removeItem(USER_DATA);
              localStorage.removeItem(THEME);
              history.replace(routes[appSections.MAIN]);
              removeUser && removeUser(null);
          }
      }

      if (NODE_ENV === "development") {
        console.log("%c psh-psh-psh...", "color: cyan; font-size: 16px; font-weight: bold; border-left: 5px solid cyan");
        console.log(
          "%c HUSTON WE HAVE A PROBLEM...\r\n",
          "color: royalblue; font-size: 16px; font-weight: bold; border-left: 5px solid royalblue",
          axios.isCancel(error) ? error.message : error,
        );
        console.log("%c psh-psh-psh...", "color: cyan; font-size: 16px; font-weight: bold; border-left: 5px solid cyan");
      }

      return Promise.reject({...error, cancelledPromise: axios.isCancel(error)})
    }
  );
  return http;
}
export default configuredAxios;
