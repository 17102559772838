import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const BellNotification: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="32" height="38" viewBox="0 0 32 38" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="notification-bell-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="App_Game_IPhoneX_User/HomPage/MenuRedesign/Notification" transform="translate(-97.000000, -1030.000000)"
         stroke="#FFFFFF" strokeWidth="1.96363625">
        <g id="Group-3" transform="translate(48.000000, 438.000000)">
          <g id="Activity_Notification" transform="translate(0.000000, 553.000000)">
            <g id="description-copy" transform="translate(33.000000, 26.000000)">
              <g id="icon-notification-bell">
                <g id="icon-notification-generic-bell-list" transform="translate(17.500000, 14.000000)">
                  <path
                    d="M20.0220156,29.8610679 C20.032559,29.9453519 20.032559,30.0401713 20.032559,30.1244553 C20.032559,33.0322516 17.6708258,35.3922022 14.760833,35.3922022 C11.8508402,35.3922022 9.48910691,33.0322516 9.48910691,30.1244553 C9.48910691,30.0401713 9.48910691,29.9453519 9.49965036,29.8610679"
                    id="Shape" strokeLinejoin="round"></path>
                  <path
                    d="M17.82584,4.21419756 L17.82584,3.16064817 C17.82584,1.41175618 16.4130174,0 14.6628044,0 C12.9125913,0 11.4997687,1.41175618 11.4997687,3.16064817 L11.4997687,4.21419756"
                    id="Shape"></path>
                  <path
                    d="M5.27172606,13.267665 C5.27172606,8.03047102 9.51968292,3.78572053 14.760833,3.78572053 C20.001983,3.78572053 24.2499399,8.03047102 24.2499399,13.267665 L24.2499399,15.1998746 C24.2499399,18.0539399 25.1429703,20.8374174 26.803564,23.1604938 L29.5216659,26.9638071 C29.5216659,26.9638071 26.0792288,30.1244553 14.760833,30.1244553 C3.44243712,30.1244553 0,26.9638071 0,26.9638071 L2.71810196,23.1604938 C4.37869567,20.8374174 5.27172606,18.0539399 5.27172606,15.1998746 L5.27172606,13.267665 Z"
                    id="Shape" strokeLinejoin="round"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BellNotification;