import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const TotalDistance: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="147" height="98" viewBox="0 0 147 98" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1212.000000, -1249.000000)">
        <g id="icon-total-distance" transform="translate(1212.000000, 1251.000000)">
          <g id="Group-10" transform="translate(17.000000, 0.000000)" stroke="#FFFFFF" strokeLinejoin="round"
             strokeWidth="4">
            <path
  d="M36,51 C36,57.627 30.627,63 24,63 C17.373,63 12,57.627 12,51 C12,44.373 17.373,39 24,39 C30.627,39 36,44.373 36,51 Z"
  id="Stroke-1" strokeLinecap="round"/>
            <path
  d="M48,51 C48,64.254 28.5,90 24,90 C19.5,90 0,64.254 0,51 C0,37.746 10.746,27 24,27 C37.2555,27 48,37.746 48,51 Z"
  id="Stroke-2" strokeLinecap="round"/>
            <path
  d="M75.0634766,34 L71.0512478,34 C63.3324872,34 57.0751953,40.2572918 57.0751953,47.9760525 L57.0751953,47.9760525 C57.0751953,55.6948132 63.3324872,61.952105 71.0512478,61.952105 L87.3168728,61.952105 C95.0620851,61.952105 101.34082,68.2308402 101.34082,75.9760525 L101.34082,75.9760525 C101.34082,83.7212648 95.0620851,90 87.3168728,90 L39.6625977,90"
  id="Path-40" strokeLinecap="round"/>
            <g id="Group-11" transform="translate(87.000000, 0.000000)">
              <path
  d="M6,25.9673361 L6,3.13671985 C9.6304709,5.96219996 13.2609418,4.3328064 17.4269143,2.45593882 C22.0492938,0.393579446 27.2864882,-1.95731008 32.48419,2.70375403 C32.801662,2.99217045 33,3.42547746 33,3.87914193 L33,23.100345 C33,23.7191437 32.662727,24.2553754 32.127116,24.5235481 C31.5915051,24.7710221 30.9765808,24.6677563 30.5399747,24.276074 C26.7509866,20.8730787 22.9617797,22.5641133 18.5973599,24.5235481 C14.3718751,26.4415855 10.2057932,28.1740174 6,25.9673361 Z"
  id="Stroke-5"/>
              <path d="M6,0 L6,46.5" id="Stroke-6" strokeLinecap="round"/>
              <path d="M0.119628906,47 L12.6619206,47" id="Path-41" strokeLinecap="round"/>
            </g>
          </g>
          <g id="Group-8" transform="translate(0.000000, 90.000000)" fill="#FFFFFF">
            <g id="Group-13" transform="translate(131.000000, 0.000000)">
              <circle id="Oval-2" cx="3" cy="3" r="3"/>
              <circle id="Oval-2-Copy" cx="13" cy="3" r="3"/>
            </g>
            <g id="Group-12">
              <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
              <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TotalDistance;