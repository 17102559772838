import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Heart: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="52" height="45" viewBox="0 0 52 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Heart-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="Miscellaneous/Icons" transform="translate(-1561.000000, -563.000000)" stroke="#FFFFFF" strokeWidth="3">
        <path d="M1587.14286,606 C1594.39619,601.667226 1611.14286,592.898516 1611.14286,578.225806 C1611.14286,570.925161 1605.16952,565 1597.80952,565 C1593.44952,565 1589.56952,567.089677 1587.14286,570.303548 C1584.71619,567.089677 1580.83619,565 1576.47619,565 C1569.11619,565 1563.14286,570.925161 1563.14286,578.225806 C1563.14286,592.898516 1579.88952,601.667226 1587.14286,606 Z" id="icon-heart-health-points"/>
      </g>
    </g>
  </svg>
);

export default Heart;