import React from 'react';
import {Route, RouteProps} from "react-router";

const AppRoute: React.FC<RouteProps> = (props) => {
  const {component, children, ...rProps} = props;
  return children
    ? <Route {...rProps}>{children}</Route>
    : <Route {...rProps} component={component} />
};

export default AppRoute;
