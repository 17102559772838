import React from "react";

const Recycle = () => (
  <svg width="83" height="83" viewBox="0 0 83 83" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HoT_LandingPage1440px" transform="translate(-165.000000, -1714.000000)">
        <g id="1-row" transform="translate(165.000000, 1516.000000)">
          <g id="Group-17-Copy-3" transform="translate(0.000000, 198.000000)">
            <g id="icon-features-take-overdistrict">
              <circle id="Oval-Copy" fill="#F2F6FF" cx="41.5" cy="41.5" r="41.5"/>
              <g id="icon-change-attack" transform="translate(22.000000, 21.000000)" stroke="#36A3D8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
                <path d="M17.615797,0.432502088 C18.3284242,0.351602417 19.0525616,0.310263574 19.7860808,0.310263574 C30.5795586,0.310263574 39.3423939,9.27816993 39.3423939,20.3241755 C39.3423939,21.2226508 39.2846263,22.1073909 39.1721313,22.9747065" id="Stroke-1"/>
                <polyline id="Stroke-2" points="35.6943434 19.8874061 39.0548586 23.693469 42.7741414 19.8874061"/>
                <path d="M37.7040505,28.346578 C37.4160808,29.0184898 37.0890202,29.6810226 36.7224343,30.3311092 C31.3256737,39.8971842 19.355299,43.1796661 10.0078374,37.6567078 C9.24751919,37.2074479 8.52781212,36.7136932 7.85006263,36.1804666" id="Stroke-3"/>
                <polyline id="Stroke-4" points="12.2014889 34.6418615 7.30040101 35.7172048 8.66167677 40.9163867"/>
                <path d="M4.03817778,32.1935352 C3.61356364,31.6022564 3.21644343,30.9811958 2.84972727,30.3311092 C-2.54703333,20.7650342 0.660419192,8.51460149 10.0078374,2.99150982 C10.768199,2.54256109 11.5458909,2.15139785 12.3359616,1.81713108" id="Stroke-5"/>
                <polyline id="Stroke-6" points="11.4622798 6.44308096 13.0028091 1.56198596 7.92246768 0.168911401"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>)

export default Recycle
