import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const PlaceMark: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="160" height="119" viewBox="0 0 160 119" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="PlaceMark-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
       strokeLinejoin="round">
      <g id="App_Game_IPhoneX_Leaderboard_Total_Distance_Daily" transform="translate(-483.000000, -618.000000)"
         stroke="#FFFFFF">
        <g id="BEST_WINER" transform="translate(44.000000, 546.000000)">
          <g id="icon-distance" transform="translate(441.000000, 74.000000)">
            <path
  d="M31.2570732,69.0487148 L59.9650683,69.0487148 C65.0672195,69.0487148 69.2033122,73.1830689 69.2033122,78.2830754 L69.2033122,78.286118 C69.2033122,83.2454033 65.1813659,87.265659 60.2199951,87.265659 C50.4271902,87.265659 34.6708098,87.265659 23.5443951,87.265659 C20.1114439,87.265659 16.8190829,88.6287541 14.3915707,91.0552459 C11.9640585,93.4817377 10.6002,96.7727148 10.6002,100.204413 C10.6002,100.205934 10.6002,100.207646 10.6002,100.209167 C10.6002,107.340315 16.3836146,113.121298 23.517761,113.121298 C49.6255024,113.121298 106.209366,113.121298 106.209366,113.121298"
  id="Stroke-1" strokeWidth="3.79999995"/>
            <path
  d="M23.1595317,-7.10542736e-15 C35.941639,-7.10542736e-15 46.3190634,10.3730623 46.3190634,23.1497967 C46.3190634,35.9265311 23.1595317,69.0487148 23.1595317,69.0487148 C23.1595317,69.0487148 0,35.9265311 0,23.1497967 C0,10.3730623 10.3774244,-7.10542736e-15 23.1595317,-7.10542736e-15 Z M23.1595317,10.1988721 C30.4205707,10.1988721 36.3158488,16.091482 36.3158488,23.3494689 C36.3158488,30.6076459 30.4205707,36.5002557 23.1595317,36.5002557 C15.8983024,36.5002557 10.0032146,30.6076459 10.0032146,23.3494689 C10.0032146,16.091482 15.8983024,10.1988721 23.1595317,10.1988721 Z"
  id="Fill-2" strokeWidth="3.8"/>
            <path
  d="M116.858268,0.480544262 C138.021761,0.480544262 155.203639,17.6553902 155.203639,38.8097967 C155.203639,59.9642033 116.858268,114.80463 116.858268,114.80463 C116.858268,114.80463 78.5130878,59.9642033 78.5130878,38.8097967 C78.5130878,17.6553902 95.6949659,0.480544262 116.858268,0.480544262 Z M116.858268,17.3667213 C128.880541,17.3667213 138.641195,27.123082 138.641195,39.1403016 C138.641195,51.1575213 128.880541,60.9140721 116.858268,60.9140721 C104.835995,60.9140721 95.0755317,51.1575213 95.0755317,39.1403016 C95.0755317,27.123082 104.835995,17.3667213 116.858268,17.3667213 Z"
  id="Fill-3" strokeWidth="3.8"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PlaceMark;