import React from "react";

const SettingsNav = () =>
  <svg className='settings' width="68" height="68" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icon-setting-active" stroke="#fff" strokeWidth="3" fill="none" fillRule="evenodd">
      <path d="M26.2797,10.628 L26.8499,5.0279 C27.1094,2.4556 29.2755,0.5 31.8604,0.5 C31.8604,0.5 36.1038,0.5 36.1038,0.5 C38.6881,0.5 40.854,2.4548 41.1158,5.0249 L41.6928,10.6297 C42.8409,11.0085 43.9632,11.4688 45.0491,12.0205 L49.4124,8.4633 C51.415,6.831 54.332,6.9778 56.1575,8.8059 C56.1575,8.8059 59.1583,11.8067 59.1583,11.8067 C60.9869,13.6353 61.1331,16.5494 59.5018,18.5508 L55.9456,22.9218 C56.4889,24.0012 56.9573,25.12 57.3362,26.2779 L62.9377,26.8482 C65.5094,27.1102 67.4642,29.2761 67.4642,31.8604 L67.4642,36.1038 C67.4642,38.6881 65.5094,40.854 62.9393,41.1158 L57.3345,41.6928 C56.9557,42.8409 56.4954,43.9632 55.9437,45.0491 L59.5009,49.4124 C61.1332,51.415 60.9864,54.332 59.1583,56.1575 C59.1583,56.1575 56.1575,59.1583 56.1575,59.1583 C54.3289,60.9869 51.4148,61.133 49.4134,59.5018 L45.0424,55.9456 C43.963,56.4889 42.8442,56.9573 41.6863,57.3362 L41.116,62.9377 C40.854,65.5094 38.6881,67.4642 36.1038,67.4642 L31.8604,67.4642 C29.2761,67.4642 27.1102,65.5094 26.8484,62.9393 L26.2714,57.3345 C25.1233,56.9557 24.001,56.4954 22.9151,55.9437 L18.5518,59.5009 C16.5492,61.1332 13.6322,60.9864 11.8067,59.1583 C11.8067,59.1583 8.8059,56.1575 8.8059,56.1575 C6.9773,54.3289 6.8312,51.4147 8.4624,49.4134 L12.0186,45.0424 C11.4753,43.963 11.0069,42.8442 10.628,41.6863 L5.0265,41.116 C2.4548,40.854 0.5,38.6881 0.5,36.1038 L0.5,31.8604 C0.5,29.2761 2.4548,27.1102 5.025,26.8484 L10.6315,26.2714 C11.0103,25.1233 11.4706,24.001 12.0223,22.9151 L8.4651,18.5518 C6.8327,16.5492 6.9796,13.6322 8.8077,11.8067 C8.8077,11.8067 11.8085,8.8059 11.8085,8.8059 C13.6371,6.9773 16.5512,6.8311 18.5526,8.4624 L22.9236,12.0186 C24.003,11.4753 25.1218,11.0069 26.2797,10.628 Z M34.3189,46.58 C41.086,46.58 46.58,41.086 46.58,34.3189 C46.58,27.5519 41.086,22.0579 34.3189,22.0579 C27.5519,22.0579 22.0579,27.5519 22.0579,34.3189 C22.0579,41.086 27.5519,46.58 34.3189,46.58 Z" id="Fill-1" fill="none"/>
    </g>
  </svg>

export default SettingsNav;
