import React from 'react';

const Wallet = () =>
  <svg width="80px" height="56px" viewBox="0 0 80 56" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.352596507">
          <g id="Boards_Teacher_Student_Stats" transform="translate(-1861.000000, -213.000000)">
              <g id="icon-board-code" transform="translate(1862.000000, 214.000000)">
                  <rect id="Rectangle-2" stroke="#FFFFFF" strokeWidth="3" x="1.5" y="14.5" width="75" height="1"/>
                  <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="3" x="1.5" y="1.5" width="75" height="51" rx="12"/>
                  <g id="noun_code_1342500" transform="translate(15.000000, 26.000000)" fill="#FFFFFF"
                     fillRule="nonzero">
                      <path
                        d="M11.8,1.7 C11.3309787,1.2297915 10.6941363,0.965531082 10.03,0.965531082 C9.3658637,0.965531082 8.72902132,1.2297915 8.26,1.7 L6.5,3.46 L4.73,1.7 C3.75245598,0.722456059 2.16754405,0.72245609 1.19000007,1.70000007 C0.21245609,2.67754405 0.212456059,4.26245598 1.19,5.24 L3,7 L1.2,8.77 C0.567639906,9.40236009 0.320675177,10.324045 0.552135035,11.187865 C0.783594894,12.0516849 1.45831508,12.7264051 2.32213504,12.957865 C3.18595499,13.1893248 4.10763991,12.9423601 4.74,12.31 L6.51,10.54 L8.28,12.31 C8.91236009,12.9423601 9.83404502,13.1893249 10.697865,12.957865 C11.5616849,12.7264051 12.2364051,12.0516849 12.467865,11.187865 C12.6993249,10.324045 12.4523601,9.40236009 11.82,8.77 L10,7 L11.77,5.23 C12.7509685,4.2626125 12.7643851,2.68392025 11.8,1.7 Z"
                        id="Shape"/>
                      <path
                        d="M29.3,1.7 C28.8309787,1.2297915 28.1941363,0.965531082 27.53,0.965531082 C26.8658637,0.965531082 26.2290213,1.2297915 25.76,1.7 L24,3.46 L22.23,1.7 C21.252456,0.722456059 19.667544,0.72245609 18.6900001,1.70000007 C17.7124561,2.67754405 17.7124561,4.26245598 18.69,5.24 L20.46,7 L18.7,8.77 C18.0676399,9.40236009 17.8206751,10.324045 18.052135,11.187865 C18.2835948,12.051685 18.958315,12.7264052 19.822135,12.957865 C20.685955,13.1893249 21.6076399,12.9423601 22.24,12.31 L24,10.54 L25.77,12.31 C26.4023601,12.9423601 27.324045,13.1893248 28.187865,12.957865 C29.0516849,12.7264051 29.7264051,12.0516849 29.957865,11.187865 C30.1893248,10.324045 29.9423601,9.40236009 29.31,8.77 L27.54,7 L29.31,5.23 C30.2798688,4.25157257 30.2753967,2.67291676 29.3,1.7 Z"
                        id="Shape"/>
                      <path
                        d="M46.8,1.7 C46.3309787,1.2297915 45.6941363,0.965531082 45.03,0.965531082 C44.3658637,0.965531082 43.7290213,1.2297915 43.26,1.7 L41.5,3.46 L39.73,1.7 C38.752456,0.722456059 37.167544,0.72245609 36.1900001,1.70000007 C35.2124561,2.67754405 35.2124561,4.26245598 36.19,5.24 L38,7 L36.2,8.77 C35.222456,9.74754401 35.222456,11.332456 36.2,12.31 C37.177544,13.287544 38.762456,13.287544 39.74,12.31 L41.51,10.54 L43.28,12.31 C44.257544,13.287544 45.842456,13.287544 46.82,12.31 C47.797544,11.332456 47.797544,9.74754401 46.82,8.77 L45,7 L46.77,5.23 C47.7509685,4.2626125 47.7643851,2.68392025 46.8,1.7 Z"
                        id="Shape"/>
                  </g>
              </g>
          </g>
      </g>
  </svg>

export default Wallet
