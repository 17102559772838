import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const EnvelopeRound: React.FC<IconsPropsInterface> = ({...props}) => (
  <svg width="119" height="119" viewBox="0 0 119 119" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="EnvelopeRound-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Miscellaneous/Icons" transform="translate(-1259.000000, -527.000000)">
        <g id="icon-email-list" transform="translate(1261.285714, 529.000000)">
          <path d="M57.5,115 C89.2563731,115 115,89.2563731 115,57.5 C115,25.7436269 89.2563731,0 57.5,0 C25.7436269,0 0,25.7436269 0,57.5 C0,89.2563731 25.7436269,115 57.5,115 Z" id="Oval-4-Copy" stroke="#FFFFFF" strokeWidth="3.04761897" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6.299999833106995,8.099999785423279"></path>
          <g id="icon-email" transform="translate(35.000000, 40.000000)" fill="#FFFFFF">
            <path d="M1,7.30398864 L23.0001423,19.7225572 L45,7.29935552 L45,30.8571429 C45,33.6972857 42.6822353,36 39.8235294,36 L6.17647059,36 C3.31776471,36 1,33.6972857 1,30.8571429 L1,7.30398864 Z M1.14250772,3.93947724 C1.68787025,1.67977377 3.73486874,0 6.17647059,0 L39.8235294,0 C42.2635708,0 44.309513,1.67762731 44.8564448,3.93514531 L22.9998577,16.2774428 L1.14250772,3.93947724 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EnvelopeRound;