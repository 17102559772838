import React, {createContext, FC, useCallback, useContext, useEffect, useState} from "react";
import {IThemeState, themeContextInterface} from "interfaces/theme";
import {TEAMS, THEMES_TYPES, THEME} from "config/names";
import {generateThemeClassName} from "helpers/misc";
import {UserContext} from "context/User";
import {useApi} from "api/useApi";
import { api } from "config/urls";

export const ThemeContext = createContext<Partial<themeContextInterface>>({});

const DEFAULT_THEME = localStorage.getItem(THEME) ? JSON.parse(localStorage.getItem(THEME) as string) : {
  type: THEMES_TYPES.LIGHT,
  name: TEAMS.CAPRA
}

const ThemeContextProvider: FC = ({children}) => {
  const [theme, setTheme] = useState<IThemeState>(DEFAULT_THEME);
  const {user, updateUser} = useContext(UserContext);
  const {apiCall} = useApi();


  const onSetTheme = (data: IThemeState) => setTheme((pr: IThemeState): IThemeState => {
    localStorage.setItem(THEME, JSON.stringify({...pr, ...data}));
    return { ...pr, ...data }
  });

  const changeTheme = useCallback((data: IThemeState) => {
    apiCall({
      method: "PUT", url: api.user.updateUserDataTheme,
      data: {
        darkTheme: data.type === THEMES_TYPES.DARK
      }
    }).then(() => {
      updateUser?.({darkTheme: data.type === THEMES_TYPES.DARK })
      onSetTheme(data);
    })
  }, [apiCall, updateUser]);

  useEffect(() => () => {
        document.documentElement.setAttribute("class", "");
        localStorage.removeItem(THEME);
      }, [])

  useEffect(() => {
    if (!user) return;

    const data = {
      name: user.tribeName || TEAMS.CAPRA,
      type: user.darkTheme ? THEMES_TYPES.DARK : THEMES_TYPES.LIGHT
    }

    onSetTheme(data);
  } , [user])

  useEffect(() => {
    document.documentElement.setAttribute("class", generateThemeClassName(theme));
  }, [theme]);

  return <ThemeContext.Provider value={{theme, changeTheme}}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;
