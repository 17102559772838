import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import {UserContextProvider} from "context/User";
import {GlobalContextProvider} from "context/Global";
import ModalContextProvider from "context/Modal";
import {BrowserRouter} from "react-router-dom";
import { defaults } from 'react-chartjs-2'
import "assets/styles/index.scss";


defaults.global.defaultFontFamily = 'Signika';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ModalContextProvider>
        <GlobalContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </GlobalContextProvider>
      </ModalContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
