import React, {FC, MouseEvent} from "react";
interface ITrailerBtn {
  className?: string,
  onClick?: (e: MouseEvent) => void
}
const TrailerBtn: FC<ITrailerBtn> = ({className, onClick}) => (
  <svg className={className || ""} onClick={onClick} width="100" height="100" viewBox="37 35 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HoT_LandingPage1440px" transform="translate(-907.000000, -632.000000)">
        <g id="Watch-a-trialer" transform="translate(926.000000, 677.000000)">
          <g id="button-play" transform="translate(28.000000, 0.000000)">
            <g id="Oval-Copy">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
              <use fill="#FFFFFF" fillRule="evenodd"/>
            </g>
            <g id="Group-7" transform="translate(14.000000, 15.000000)" fill="#212B35" fillRule="nonzero">
              <path d="M37.9230665,25.4454884 L30.946104,20.6169577 L24.9227434,16.4485873 C23.2646514,15.3014136 21,16.4871323 21,18.5030989 L21,27.5 L21,36.4969011 C21,38.5128677 23.2646514,39.6985864 24.9227434,38.5514127 L30.946104,34.3830423 L37.9230665,29.5545116 C39.3589778,28.5609063 39.3589778,26.4390937 37.9230665,25.4454884 Z" id="Shape"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default TrailerBtn;
