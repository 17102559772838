import React from 'react';

const BagGradient = () =>
  <svg width="41px" height="40px" viewBox="0 0 41 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="115.216725%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
            <stop stopColor="#F80066" offset="0%"/>
            <stop stopColor="#700B81" offset="100%"/>
        </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="HoT_LandingPage1440px_Shop" transform="translate(-186.000000, -649.000000)" stroke="url(#linearGradient-1)" strokeWidth="3">
            <g id="icon-store-under-main-button" transform="translate(188.000000, 651.000000)">
                <path d="M32.7306667,0.972972973 C32.7306667,0.435989189 32.2946775,0 31.7576937,0 L4.86486486,0 C4.32788108,0 3.89189189,0.435989189 3.89189189,0.972972973 L0,34.7189351 C0,35.2559189 0.435989189,35.6919081 0.972972973,35.6919081 L35.6495856,35.6919081 C36.1865694,35.6919081 36.6225586,35.2559189 36.6225586,34.7189351 L32.7306667,0.972972973 Z" id="Stroke-1"/>
                <path d="M10.8,7.2 C12.7829189,14.1401189 23.6887784,15.1315297 26.6630595,7.2" id="Stroke-2"/>
            </g>
        </g>
    </g>
</svg>;

export default BagGradient;
