import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Statistics: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="179" height="103" viewBox="0 0 179 103" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1210.000000, -243.000000)">
        <g id="icon-statistic" transform="translate(1210.000000, 245.000000)">
          <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="4" x="28" y="0" width="124" height="99" rx="11"/>
          <polyline id="Path-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                    points="45 91.5848462 45 80 57.0238902 80 57.0238902 91.5848462"/>
          <polyline id="Path-2-Copy" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                    points="64 91.6588208 64 48 76.0238902 48 76.0238902 91.6588208"/>
          <polyline id="Path-2-Copy-4" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                    points="122 91.6588208 122 48 134.02389 48 134.02389 98.8359375"/>
          <polyline id="Path-2-Copy-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                    points="103 91.6588208 103 32.8674316 115.02389 32.8674316 115.02389 91.6588208"/>
          <polyline id="Path-2-Copy-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                    points="84 91.6588208 84 64.1040039 96.0238902 64.1040039 96.0238902 91.6588208"/>
          <path d="M95,15 L103.15456,15" id="Path-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M111,15 L119.15456,15" id="Path-3-Copy" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M127,15 L135.15456,15" id="Path-3-Copy-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M45,15 L61,15" id="Path-3-Copy-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round"
                strokeLinejoin="round"/>
          <g id="dots-left-right" transform="translate(0.000000, 95.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="166" cy="3" r="3"/>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
            <circle id="Oval-2-Copy" cx="176" cy="3" r="3"/>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Statistics