import React from "react";

const InviteCodeParty = () =>
  <svg width="362" height="213" viewBox="0 0 362 213" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Elements_InviteCode" transform="translate(-254.000000, -1323.000000)">
        <g id="icon-board-congratulation" transform="translate(255.000000, 1326.000000)">
          <g id="Group-4" transform="translate(0.000000, 6.000000)">
            <g id="icon_board">
              <path d="M11,172 L31.0905594,172 L11,172 Z" id="Fill-1" fill="#ECECEC"/>
              <path d="M62,172 L310.902629,172 L62,172 Z" id="Fill-4" fill="#ECECEC"/>
              <g id="Group-26" fill="#FFFFFF" opacity="0.200000003">
                <rect id="Rectangle-12" x="0" y="0" width="360" height="201" rx="17"/>
              </g>
            </g>
          </g>
          <g id="Group" transform="translate(71.000000, 0.000000)">
            <path d="M166.741813,141.456963 C158.707413,149.514506 129.397547,133.178706 101.27568,104.965534 C73.1538133,76.7523629 56.8679467,47.3473343 64.9023467,39.2868486 C72.9367467,31.2263629 102.246613,47.5651057 130.36848,75.7782771 C158.490347,103.991449 174.77328,133.396477 166.741813,141.456963 Z" id="Stroke-1" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <polyline id="Stroke-3" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" points="61.1359467 49.0759686 0.873546667 205.691883 157.170347 145.160254"/>
            <path d="M121.60456,154.630957 L121.589893,128.045186" id="Stroke-5" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M91.32288,166.630751 L91.30528,96.5142371" id="Stroke-7" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M61.0414933,178.159394 L61.0238933,56.2809657" id="Stroke-9" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.7598133,190.16066 L30.7598133,132.513031" id="Stroke-11" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M91.6065333,66.0750886 L105.381467,24.9192314 C105.381467,24.9192314 111.362533,4.53700286 98.6348,1.44406 C85.1444,-1.83428286 80.7884,11.47626 83.4254667,16.0465171" id="Stroke-13" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M133.99936,108.608497 L175.02496,94.78884 C175.02496,94.78884 195.341227,88.7883543 198.42416,101.557411 C201.691893,115.091611 188.424427,119.461754 183.866027,116.816126" id="Stroke-15" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M118.648053,86.8575457 L154.112053,51.3431457 C154.112053,51.3431457 170.80272,33.3210886 159.20432,23.5213743 C146.91072,13.1360314 135.544053,24.6749743 135.925387,30.7755171" id="Stroke-17" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <polygon id="Stroke-19" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" points="185.416 77.3132714 165.041067 71.5393857 172.614933 60.5242714 192.989867 66.3011"/>
            <path d="M123.80456,4.14854571 L124.485093,11.3055743" id="Stroke-21" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M197.80816,134.510643 L190.91776,132.535986" id="Stroke-23" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M123.11992,42.33094 C120.711653,44.2614543 117.19752,43.8641686 115.273253,41.4480829 C113.348987,39.0319971 113.744987,35.5093971 116.15032,33.5788829 C118.56152,31.6483686 122.075653,32.0427114 123.996987,34.4587971 C125.921253,36.8748829 125.528187,40.4004257 123.11992,42.33094" id="Fill-25" fill="#FFFFFF"/>
            <path d="M217.725493,125.277134 C215.317227,127.204706 211.803093,126.810363 209.878827,124.394277 C207.95456,121.978191 208.35056,118.455591 210.758827,116.522134 C213.167093,114.594563 216.681227,114.988906 218.60256,117.404991 C220.526827,119.821077 220.13376,123.34662 217.725493,125.277134" id="Fill-27" fill="#FFFFFF"/>
          </g>
        </g>
      </g>
    </g>
  </svg>;

export default InviteCodeParty;
