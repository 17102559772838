import React from "react";
import {Switch} from "react-router-dom";
import {appSections, routes} from "config/urls";
import AppRoute from "components/shared/RouterDecorators/AppRoute";
import AppRedirect from "components/shared/RouterDecorators/AppRedirect";

const WelcomeAboutUs = React.lazy(() => import("pages/WelcomeAboutUs/WelcomeAboutUs"));
const WelcomeBadges = React.lazy(() => import("pages/WelcomeBadges/WelcomeBadges"));
const WelcomeDistrictChecker = React.lazy(() => import("pages/WelcomeDistrictChecker/WelcomeDistrictChecker"));

const Welcome: React.FC = () => {
  return (
    <Switch>
      <AppRoute path={routes[appSections.WELCOME].aboutUs} component={WelcomeAboutUs} exact/>
      <AppRoute path={routes[appSections.WELCOME].badges} component={WelcomeBadges} exact/>
      <AppRoute path={routes[appSections.WELCOME].districtChecker} component={WelcomeDistrictChecker} exact/>
      <AppRedirect to={routes[appSections.MAIN]} />
    </Switch>
  );
};

export default Welcome;