import React, { useContext } from "react";
import { TEAMS } from "config/names";
import { ThemeContext } from "context/Theme";
import classNames from "classnames";
import {variantToColor} from "./_defaultIconSettings";

const GoalsHeart: React.FC<{ className?: string }> = ({ className }) => {
  const {theme} = useContext(ThemeContext)
  const color = variantToColor[theme?.name ?? TEAMS.CAPRA]
  return (
    <svg
      width="69"
      height="62"
      viewBox="0 0 69 62"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classNames(className)}
    >
      <title>{"icon-challenge-goal-capra@1x"}</title>
      <defs>
        <path
          d="M.818 26.695A20.886 20.886 0 010 20.891C0 9.361 9.354 0 20.875 0c4.566 0 8.792 1.47 12.23 3.963A20.763 20.763 0 0145.335 0C52.94 0 59.602 4.078 63.25 10.168a3.245 3.245 0 11-5.567 3.34C55.17 9.31 50.578 6.496 45.335 6.496a14.33 14.33 0 00-9.978 4.031 3.244 3.244 0 01-4.504 0 14.33 14.33 0 00-9.978-4.031c-7.939 0-14.384 6.45-14.384 14.395 0 .579.034 1.15.1 1.71h5.53c3.32 0 6.503 1.32 8.85 3.67l3.63 3.633 5.37-11.818a3.43 3.43 0 016.246 0l5.37 11.818 3.63-3.633a12.513 12.513 0 018.85-3.67h8.19c.69 0 1.328.216 1.854.582a3.251 3.251 0 011.654 3.54c-.766 3.638-2.215 7.369-4.445 10.994-8.366 13.602-20.571 18.868-25.344 20.436-1.71.562-2.785.676-2.86.675-.12.001-.991-.076-2.394-.51-3.59-1.108-12.888-4.775-20.907-13.888a3.251 3.251 0 01.29-4.584 3.245 3.245 0 014.581.291c7.326 8.325 15.86 11.4 18.404 12.108 3.116-.872 14.85-5.166 22.703-17.933a29.34 29.34 0 002.547-5.215h-4.272a6.025 6.025 0 00-4.262 1.767l-6.752 6.757a3.429 3.429 0 01-5.549-1.007l-4.41-9.709-4.412 9.71a3.431 3.431 0 01-5.549 1.006l-6.752-6.757a6.025 6.025 0 00-4.262-1.767H4.016a3.227 3.227 0 01-2.01-.649 3.231 3.231 0 01-1.188-1.753z"
          id="prefix__a"
        />
      </defs>
      <g transform="translate(1.58 1.486)" fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#2FA3D9" xlinkHref="#prefix__a" />
        <g mask="url(#prefix__b)" fill={color}>
          <path d="M-5.775-10.52h77.414v77.433H-5.775z" />
        </g>
      </g>
    </svg>
  )
}

export default GoalsHeart
