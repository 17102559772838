import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const BestPlayer: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="149" height="115" viewBox="0 0 149 115" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <circle id="path-1" cx="48" cy="48" r="48"></circle>
    </defs>
    <g id="best-player-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1214.000000, -508.000000)">
        <g id="icon-best-player" transform="translate(1210.000000, 504.000000)">
          <g id="Group-14" transform="translate(4.000000, 113.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="118" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy" cx="128" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"></circle>
          </g>
          <g id="Group-9" transform="translate(0.000000, 14.000000)" stroke="#FFFFFF" strokeLinecap="round"
             strokeLinejoin="round" strokeWidth="4">
            <path
              d="M85.2566506,119.806024 C86.9077358,114.783618 87.8014038,109.413862 87.8014038,103.833944 C87.8014038,79.2837643 70.5020548,58.8018477 47.5076542,54.0722852"
              id="Oval-5"
              transform="translate(67.654529, 86.939155) rotate(-240.000000) translate(-67.654529, -86.939155) "></path>
            <path
              d="M56.9943873,66.9902032 C58.6454725,61.9677972 59.5391404,56.5980411 59.5391404,51.0181224 C59.5391404,26.467943 42.2397915,5.98602646 19.2453909,1.25646397"
              id="Oval-5"
              transform="translate(39.392266, 34.123334) rotate(-120.000000) translate(-39.392266, -34.123334) "></path>
          </g>
          <g id="Group-16" transform="translate(98.000000, 6.000000)">
            <polygon id="Stroke-1" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                     points="26.4999474 0 33.5213846 6.28456004 42.8779055 5.34752129 44.8823337 14.5791273 53 19.3475477 49.2218457 28.0000528 53 36.6524523 44.8823337 41.4208727 42.8779055 50.6524787 33.5213846 49.71544 26.4999474 56 19.4785103 49.71544 10.1220945 50.6524787 8.11766631 41.4208727 0 36.6524523 3.77815434 28.0000528 0 19.3475477 8.11766631 14.5791273 10.1220945 5.34752129 19.4785103 6.28456004"></polygon>
            <g id="noun_Best-Buy_3282716" transform="translate(15.000000, 3.000000)" fill="#FFFFFF"
               fontFamily="Signika-Semibold, Signika" fontSize="40" fontWeight="500">
              <text id="1">
                <tspan x="0" y="38">1</tspan>
              </text>
            </g>
            <g id="Group-15" transform="translate(15.000000, 51.000000)" stroke="#FFFFFF" strokeLinecap="round"
               strokeLinejoin="round" strokeWidth="4">
              <polyline id="Path-42"
                        points="0 0.209716797 0 29.1849435 11.8406059 20.9619141 24 29.1849435 24 0.209716797"></polyline>
            </g>
          </g>
          <g transform="translate(6.000000, 0.000000)" id="profile">
            <g transform="translate(15.000000, 22.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Mask"></g>
              <g mask="url(#mask-2)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
                <g
                  transform="translate(47.000000, 51.000000) scale(-1, 1) translate(-47.000000, -51.000000) translate(0.000000, 14.000000)">
                  <path
                    d="M94,73.4528302 C92.0693846,70.4491981 87.7056154,65.0196226 83.1538462,62.9811321 L65.0769231,56 C65.0769231,56 57.8461538,59.490566 47,59.490566 C36.1538462,59.490566 28.9230769,56 28.9230769,56 L10.8461538,62.9811321 C6.29438462,65.0196226 1.93061538,70.4491981 0,73.4528302"
                    id="Stroke-22"></path>
                  <path
                    d="M56.3977654,45.3773585 C66.1445192,38.8936321 65.2401632,34.7311321 66.4016572,25.2996226 C67.0261351,20.2103774 67.2150528,14.7249528 64.753875,10.0528302 C62.4991073,5.77514151 58.3586611,2.81165094 53.8351318,1.28801887 C51.3547124,0.453773585 48.6381459,0 45.9023377,0 C43.1665296,0 40.4499631,0.453773585 37.9695437,1.28801887 C33.4460143,2.81165094 29.3055681,5.77514151 27.0508004,10.0528302 C24.5861242,14.7249528 24.7785403,20.2103774 25.4030183,25.2996226 C26.5645123,34.7311321 25.6601563,38.8936321 35.4069101,45.3773585"
                    id="Stroke-23"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>);

export default BestPlayer;