import React from "react";

const Invite = () =>
  <svg className='invite' width="68" height="68" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icon-invite-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
       strokeLinejoin="round">
      <g id="icon-invite" transform="translate(3.000000, 2.000000)" stroke="#FFFFFF" strokeWidth="3">
        <path
          d="M45.0104336,34.2107509 C51.2094664,34.2107509 56.2422743,39.2786526 56.2422743,45.5208421 C56.2422743,51.7630877 51.2094664,56.8309895 45.0104336,56.8309895 C38.8114009,56.8309895 33.7785372,51.7630877 33.7785372,45.5208421 C33.7785372,39.2786526 38.8114009,34.2107509 45.0104336,34.2107509 Z"
          id="Stroke-1"/>
        <path d="M45.2149327,40.5681404 L45.2149327,50.4693895" id="Stroke-2"/>
        <path d="M50.0955372,45.5187649 L40.3343283,45.5187649" id="Stroke-3"/>
        <path
          d="M7.60382124,52.2870456 C8.60173009,50.3244912 10.7712159,47.7612912 15.4737478,46.3268491 C23.8119929,43.7832982 24.9021159,42.8072421 24.9021159,37.6893193 C24.9021159,36.0874667 20.6852416,34.4950456 20.6852416,26.0505263 C20.6852416,17.6059509 24.2697743,12.3459368 31.2966159,12.3459368 C38.3235133,12.3459368 41.9595611,17.6059509 41.9595611,26.0505263 C41.9595611,32.1571368 39.7543938,34.6805333 38.5333646,36.2154105"
          id="Stroke-4"/>
        <path
          d="M37.2582,62.9743719 C35.1494841,63.3865544 32.9667292,63.5846737 30.7337416,63.5456 C13.4741947,63.2438456 -0.295040708,48.8887018 0.00468318584,31.5090526 C0.304351327,14.1293474 14.5602478,0.264421053 31.8197389,0.565894737 C49.0792858,0.867929825 62.848354,15.2229614 62.5489646,32.6026105 C62.5171858,34.4284632 62.331531,36.2155228 62.004823,37.9514386"
          id="Stroke-5"/>
      </g>
    </g>
  </svg>

export default Invite
