import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Plus: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="38" height="38" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Plus-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Miscellaneous/Icons" transform="translate(-414.000000, -568.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="icon-plus" transform="translate(413.857143, 568.000000)">
          <path d="M27.5895833,17.2583333 L20.7416667,17.2583333 L20.7416667,10.4104167 C20.7416667,9.46041667 19.95,8.66875 19,8.66875 C18.05,8.66875 17.2583333,9.46041667 17.2583333,10.4104167 L17.2583333,17.2583333 L10.4104167,17.2583333 C9.46041667,17.2583333 8.66875,18.05 8.66875,19 C8.66875,19.475 8.86666667,19.9104167 9.18333333,20.2270833 C9.5,20.54375 9.93541667,20.7416667 10.4104167,20.7416667 L17.2583333,20.7416667 L17.2583333,27.5895833 C17.2583333,28.0645833 17.45625,28.5 17.7729167,28.8166667 C18.0895833,29.1333333 18.525,29.33125 19,29.33125 C19.95,29.33125 20.7416667,28.5395833 20.7416667,27.5895833 L20.7416667,20.7416667 L27.5895833,20.7416667 C28.5395833,20.7416667 29.33125,19.95 29.33125,19 C29.33125,18.05 28.5791667,17.2583333 27.5895833,17.2583333 Z" id="Shape"/>
          <path d="M19,0.197916667 C8.62916667,0.197916667 0.197916667,8.62916667 0.197916667,19 C0.197916667,29.3708333 8.62916667,37.8020833 19,37.8020833 C29.3708333,37.8020833 37.8020833,29.3708333 37.8020833,19 C37.8020833,8.62916667 29.3708333,0.197916667 19,0.197916667 Z M19,34.31875 C10.5291667,34.31875 3.68125,27.4708333 3.68125,19 C3.68125,10.5291667 10.5291667,3.68125 19,3.68125 C27.4708333,3.68125 34.31875,10.5291667 34.31875,19 C34.31875,27.4708333 27.4708333,34.31875 19,34.31875 Z" id="Shape"/>
        </g>
      </g>
    </g>
  </svg>
)

export default Plus;