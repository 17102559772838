import React from "react";

const LinkArrow = () => (
  <svg width="4" height="8" viewBox="0 0 4 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="HoT_LandingPage1440px" transform="translate(-601.000000, -3235.000000)" fill="#1D334F">
      <g id="Features-Copy" transform="translate(165.000000, 2923.000000)">
        <g id="Brands" transform="translate(0.000000, 136.000000)">
          <g transform="translate(335.000000, 32.000000)" id="Link_More">
            <g transform="translate(0.000000, 134.000000)">
              <path d="M101.128,13.6407186 L104.242667,10.1437126 C104.424,9.95209581 104.701333,9.95209581 104.872,10.1437126 C105.042667,10.3353293 105.042667,10.6467066 104.872,10.8502994 L102.066667,14 L104.872,17.1497006 C105.042667,17.3413174 105.042667,17.6646707 104.872,17.8562874 C104.701333,18.0479042 104.424,18.0479042 104.242667,17.8562874 L101.128,14.3473054 C100.957333,14.1556886 100.957333,13.8443114 101.128,13.6407186 Z" id="arrow-right-link-more" transform="translate(103.000000, 14.000000) scale(-1, 1) translate(-103.000000, -14.000000) "></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>)

export default LinkArrow;
