import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const Comparison: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="160" height="124" viewBox="0 0 160 124" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="App_Game_IPhoneX_Leaderboard_Comparison_Users" transform="translate(-483.000000, -722.000000)">
        <g id="Users" transform="translate(48.000000, 671.000000)">
          <g id="icon-vs" transform="translate(438.000000, 54.000000)">
            <text id="User-Copy" fontFamily="DINRoundWebW04-Bold" fontSize="40" fontWeight="bold" fill="#FFFFFF">
              <tspan x="58.31" y="72">vs</tspan>
            </text>
            <path
              d="M18.0332616,61.0000109 C18.0111457,60.3360608 18,59.669332 18,59 C18,26.4151998 44.4151998,0 77,0 C109.5848,0 136,26.4151998 136,59 C136,59.669332 135.988854,60.3360608 135.966738,61.0000109 L153,61 C153.552285,61 154,61.4477153 154,62 C154,62.5522847 153.552285,63 153,63 L135.866543,63 C133.809945,93.7193947 108.240492,118 77,118 C45.7595078,118 20.1900549,93.7193947 18.1334569,63 L1,63 C0.44771525,63 6.76353751e-17,62.5522847 0,62 C-6.76353751e-17,61.4477153 0.44771525,61 1,61 L18.0332612,61 Z"
              id="Combined-Shape" strokeOpacity="0.4" stroke="#FFFFFF" strokeWidth="6"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Comparison;