import React from "react";
import Image from "components/shared/Image/Image";
import QRPhoto from "assets/img/hot-qr-code-app-store.svg";
import "./AppInvitation.scss"


const AppInvitation = ({toggleModal}: any) => {
  return (
    <div className='AppInvitation'>
      <h2 className='AppInvitation-title'>Almost done. <br/> To play HoT game you need to download the app</h2>
      <Image className='AppInvitation-image' alt='QR-photo' src={QRPhoto}/>
      <button className='AppInvitation-btn' onClick={() => toggleModal()}>Continue</button>
    </div>
  );
};

export default AppInvitation;
