import React, {useEffect, useState} from "react";
import fallbackImage from "assets/img/no-image.png";

interface IImageProps {
  src?: string | null;
  alt: string;
  className?: string;
  [k: string]: any;
}

const Image: React.FC<IImageProps> = ({src, alt, className, ...props}) => {
  const [source, setSource] = useState<string | undefined | null>(src);
  useEffect(() => {
    setSource(src || fallbackImage);
  }, [src]); //eslint-disable-line

  return <img src={source as string} alt={alt} onError={() => setSource(fallbackImage)} className={className || ""} {...props} />;
};

export default Image;
