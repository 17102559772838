import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import "./Portal.scss";

const Portal: React.FC<{className?: string}> = ({className="", children}) =>{
  const [el] = useState(document.createElement("div"));

  useEffect(() =>{
    document.body
      .appendChild(el)
      .className = `Portal ${ className ? className : "" }`;

    return () => {
      document.body.removeChild(el);
    }
  });

  return ReactDOM.createPortal(children, el);
}

export default Portal;
