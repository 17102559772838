import { sortType } from "interfaces/misc";

export const TOKEN = "token";
export const USER_DATA = "user_data";
export const THEME = "theme";

export enum TEAMS {
  ATMOS = "Atmos",
  CAPRA = "Capra",
  MAVRIC = "Mavric",
}
export enum THEMES_TYPES {
  DARK = "dark",
  LIGHT = "light",
}
export const SORTING_ASC: sortType = "asc";
export const SORTING_DESC: sortType = "desc";
export const SORTING_DIRECTION_ARR: sortType[] = [SORTING_DESC, SORTING_ASC];

export enum boardTypes {
  PUBLIC_BOARD = "public_board",
  PRIVATE_BOARD = "private_board",
  CLASS_BOARD = "organization_board",
}

export enum boardTypeNames {
  public_board = "Public",
  private_board = "Private",
  organization_board = "Org. board",
}

export enum reqParamNames {
  search = "searchTerm",
  size = "size",
  page = "page",
  sort = "sort",
}

export const DEFAULT_ERROR_TEXT =
  "Sorry, something wrong. Please reload the page and try again.";

export enum MenuNavTitleText {
  INVITE = "Invite",
  DASHBOARD = "Dashboard",
  USER_RANK = "User ranking",
  USER_DASHBOARD = "User dashboard",
  USER_NOTIFICATIONS = "Notifications",
  BOARDS = "Boards",
  ORGANIZATIONS = "Organization list",
  STATISTICS = "Statistics",
  MANAGER = "Manager dashboard",
  ADMIN = "Admin",
  SETTINGS = "Settings",
  INVITE_CODE = "Invite code"
}

export enum userRoles {
  MANAGER = "manager",
  HERO = "hero",
  USER = "user",
}

export enum listTypes {
  INVITATIONS = "invitations",
  ALERTS = "alerts",
}

export enum boardsTabTypes {
  MY_BOARDS = "my_boards",
  ORGANIZATIONS = "organizations",
}
export enum BoardPages {
  INVITE = "invite",
  SUMMARY = "summary",
  MAIN = "main",
  GOALS = "goals"
}
