import React from 'react';

const Star = () =>
  <svg width="28" height="27" viewBox="0 0 28 27" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Boards_Teacher_Student_Stats" transform="translate(-2550.000000, -224.000000)" fill="#FFFFFF"
             fillRule="nonzero">
              <g id="icon-steps-goal" transform="translate(2551.000000, 225.000000)">
                  <path
                    d="M20.6921941,15.5515933 C20.3542527,15.8805187 20.1982797,16.3660126 20.277566,16.8278238 L21.4330657,23.3010759 C21.5253497,23.8536705 21.304388,24.4049495 20.8481671,24.7220336 C20.4062437,25.050959 19.8213451,25.0904301 19.3274307,24.8272897 L13.5707285,21.788019 C13.3614648,21.6827629 13.1405031,21.6156621 12.9208412,21.6156621 L12.5686022,21.6156621 C12.4386248,21.6288191 12.3229448,21.6827629 12.2176631,21.7340752 L6.45966113,24.7878187 C6.1737107,24.9325459 5.84876702,24.9851739 5.53682109,24.9325459 C4.75695627,24.7746617 4.25134391,24.0365531 4.38002161,23.2602891 L5.53682109,16.7870371 C5.61480757,16.3147002 5.45883461,15.8410476 5.12219296,15.4989652 L0.428706512,10.8940095 C0.0387741015,10.499299 -0.104201116,9.9322316 0.0790671173,9.40726665 C0.248037829,8.89282731 0.688661453,8.51127384 1.23456683,8.42049043 L7.6944471,7.47186955 C8.18836149,7.41924149 8.61728714,7.11794582 8.83824884,6.67060726 L11.6847554,0.763106945 C11.7497442,0.631536782 11.8407284,0.511807934 11.9460101,0.407867505 L12.0616901,0.315768391 C12.1136811,0.248667608 12.1916676,0.197355244 12.2709538,0.144727179 L12.4126293,0.0920991141 L12.633591,0 L13.1807961,0 C13.6747105,0.0526280652 14.1036362,0.353923738 14.3245979,0.788105276 L17.2087979,6.67060726 C17.4167619,7.1047888 17.819692,7.39424316 18.2876109,7.47186955 L24.7474912,8.42049043 C25.2933966,8.49943253 25.7496175,8.880986 25.9302862,9.40726665 C26.0992569,9.9322316 25.9562817,10.512456 25.5533515,10.8940095 L20.6921941,15.5515933 Z"
                    id="Shape"/>
              </g>
          </g>
      </g>
  </svg>

export default Star
