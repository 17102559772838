import React from 'react';

const ArrowMobile = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
       strokeLinejoin="round">
      <g id="HoT_LandingPage_Mobile_MenuExpand320px" transform="translate(-279.000000, -94.000000)" stroke="#1D334F"
         strokeWidth="0.799999952">
        <g id="MOBILE_MENU" transform="translate(0.000000, -1.000000)">
          <g id="Menu_Items" transform="translate(0.000000, 81.000000)">
            <g id="Menu_Mobile_Item">
              <polyline id="arrow-right-mobile-menu" points="280.000625 14 288 21.9996875 280 30"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>)
export default ArrowMobile
