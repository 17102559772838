import {useEffect, useRef, useState, useContext} from "react";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios";
import configuredAxios from "api/http";
import {useHistory} from "react-router";
import {userInterface} from "../interfaces/user";
import {UserContext} from "../context/User";
import {STATUS_CODES} from "../config/api";
import {DEFAULT_ERROR_TEXT} from "../config/names";
import {GlobalContext} from "../context/Global";

export const useApi = (updateUser?: (data: userInterface | null) => void) => {
  const history = useHistory();
  const {logout} = useContext(UserContext);
  const {showNotification} = useContext(GlobalContext);
  const [http] = useState<AxiosInstance>( configuredAxios(history, updateUser, logout) );
  const activeRequests = useRef<CancelTokenSource[]>([]);

  useEffect(() => () => activeRequests.current.forEach(ar => ar.cancel('Request Canceled')), []);

  const apiCallAll = (configs: AxiosRequestConfig[]): Promise<AxiosResponse[]> => {
    let cancelSource = axios.CancelToken.source();

    activeRequests.current.push(cancelSource);
    return Promise.all(configs.map(config => http.request({...config, cancelToken: cancelSource.token})))
      .finally(() => {
        activeRequests.current.splice(activeRequests.current.indexOf(cancelSource), 1);
      })
  }

  const apiCall = (configObj: AxiosRequestConfig): Promise<AxiosResponse> => {
    let cancelSource = axios.CancelToken.source();

    activeRequests.current.push(cancelSource);

    return http.request({...configObj, cancelToken: cancelSource.token})
      .finally(() => {
        activeRequests.current.splice(activeRequests.current.indexOf(cancelSource), 1);
      })
  };

  const defaultApiCatch = (e: AxiosError) => {
    const isSessionExpired = e?.response?.data?.status === STATUS_CODES.FORBIDDEN
    showNotification?.({
      type: isSessionExpired ? "info" : "error",
      message: isSessionExpired ? "Session expired. Please sign in again." : (e?.response?.data?.error || DEFAULT_ERROR_TEXT)
    })
  }

  return { apiCall, apiCallAll, defaultApiCatch }
}
