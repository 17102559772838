import React from 'react';

const Flag = () =>
  <svg width="31" height="40" viewBox="0 0 31 40" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Boards_Student_Stats" transform="translate(-1272.000000, -308.000000)">
              <g id="icon-challenges-flag" transform="translate(1274.000000, 310.000000)">
                  <g id="icon-challenges-new3-fill">
                      <path
                        d="M1,23.8320779 L1,2.87879171 C4.40236304,5.47193649 7.80482861,3.97652572 11.7091645,2.25399036 C16.0412436,0.361215951 20.9495253,-1.79636317 25.8207946,2.48142807 C26.1183284,2.74612841 26.3042101,3.14380518 26.3042101,3.56016545 L26.3042101,21.2008355 C26.3042101,21.7687513 25.9881189,22.2608894 25.486146,22.5070106 C24.9841732,22.7341351 24.4078681,22.6393607 23.9987849,22.279886 C20.4476549,19.1567143 16.8964225,20.7086974 12.8061024,22.5070106 C8.84599169,24.2673304 4.94155326,25.8573069 1,23.8320779 Z"
                        id="Fill-10" fill="#FFFFFF"/>
                      <path
                        d="M1,23.8320779 L1,2.87879171 C4.40236304,5.47193649 7.80482861,3.97652572 11.7091645,2.25399036 C16.0412436,0.361215951 20.9495253,-1.79636317 25.8207946,2.48142807 C26.1183284,2.74612841 26.3042101,3.14380518 26.3042101,3.56016545 L26.3042101,21.2008355 C26.3042101,21.7687513 25.9881189,22.2608894 25.486146,22.5070106 C24.9841732,22.7341351 24.4078681,22.6393607 23.9987849,22.279886 C20.4476549,19.1567143 16.8964225,20.7086974 12.8061024,22.5070106 C8.84599169,24.2673304 4.94155326,25.8573069 1,23.8320779 Z"
                        id="Stroke-11" stroke="#FFFFFF" strokeWidth="2.69999984" strokeLinejoin="round"/>
                      <path d="M1,0 L1,35" id="Stroke-12" stroke="#FFFFFF" strokeWidth="2.69999984"
                            strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
              </g>
          </g>
      </g>
  </svg>

export default Flag
