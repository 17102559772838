import React  from "react";

const Dashboard = () => (
  <svg className='dashboard' width="68" height="68" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icon-activity-dashboard-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icon-activity-dashboard" transform="translate(3.000000, 2.000000)" stroke="#fff" strokeWidth="3">
        <path d="M39.9424642,3.0378 L15.4420295,3.0378 C7.19387053,3.0378 0.497368421,9.76973333 0.497368421,18.0615333 L0.497368421,48.5329333 C0.497368421,56.8247333 7.19387053,63.5566667 15.4420295,63.5566667 L45.3314179,63.5566667 C53.5796432,63.5566667 60.2761453,56.8247333 60.2761453,48.5329333 L60.2761453,23.4588667" id="Stroke-1"/>
        <polyline id="Stroke-2" points="14.8981737 41.4556 24.9588758 28.3911333 35.6064063 37.2017333 45.2637758 25.0577333"/>
        <path d="M56.5745968,0.5002 C59.9874726,0.5002 62.7582789,3.28566667 62.7582789,6.7166 C62.7582789,10.1474667 59.9874726,12.9329333 56.5745968,12.9329333 C53.1617211,12.9329333 50.3909147,10.1474667 50.3909147,6.7166 C50.3909147,3.28566667 53.1617211,0.5002 56.5745968,0.5002 Z" id="Stroke-3"/>
      </g>
    </g>
  </svg>)

export default Dashboard;
