import React  from "react";

const SliderPrevArrow = () => (
  <svg width="37" height="37" viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HoT_LandingPage1440px_BlockColors5" transform="translate(-1186.000000, -2599.000000)">
        <g id="Reviews" transform="translate(0.000000, 2178.000000)">
          <g id="Group-26" transform="translate(492.000000, 133.000000)">
            <g id="buttons-quotest-slider" transform="translate(694.000000, 288.000000)">
              <g id="btn-prev" transform="translate(18.500000, 18.500000) scale(-1, 1) translate(-18.500000, -18.500000) ">
                <circle id="Oval-2" fill="#FFFFFF" cx="18.5" cy="18.5" r="18.5"/>
                <path d="M23.1162464,19.0737686 L10,19.0737686 L10,17.6058107 L23.2637836,17.6058107 L17.695976,12.038003 L18.733979,11 L26,18.266021 L24.961997,19.304024 L18.733979,25.5320421 L17.695976,24.4940391 L23.1162464,19.0737686 Z" id="Combined-Shape" fill="#A659F3"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SliderPrevArrow;
