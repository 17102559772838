import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const ArrowRight: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="28" height="48" viewBox="0 0 28 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icons" transform="translate(-3074.000000, -482.000000)" stroke="#FFFFFF" strokeWidth="8">
        <polyline id="arrow-right-button-menu" points="3078.08333 486 3098 505.95825 3078 526"/>
      </g>
    </g>
  </svg>
);

export default ArrowRight;
