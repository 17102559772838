import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const BestTribe: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="149" height="118" viewBox="0 0 149 118" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="best-tribe-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1214.000000, -750.000000)">
        <g id="icon-best-tribe" transform="translate(1210.000000, 752.000000)">
          <g id="3-tribes" transform="translate(0.000000, 11.000000)" stroke="#FFFFFF" strokeLinecap="round"
             strokeLinejoin="round" strokeWidth="4">
            <g id="Group-9">
              <path
                d="M85.2566506,119.806024 C86.9077358,114.783618 87.8014038,109.413862 87.8014038,103.833944 C87.8014038,79.2837643 70.5020548,58.8018477 47.5076542,54.0722852"
                id="Oval-5"
                transform="translate(67.654529, 86.939155) rotate(-240.000000) translate(-67.654529, -86.939155) "></path>
              <path
                d="M56.9943873,66.9902032 C58.6454725,61.9677972 59.5391404,56.5980411 59.5391404,51.0181224 C59.5391404,26.467943 42.2397915,5.98602646 19.2453909,1.25646397"
                id="Oval-5"
                transform="translate(39.392266, 34.123334) rotate(-120.000000) translate(-39.392266, -34.123334) "></path>
            </g>
            <g id="Group-9"
               transform="translate(62.759333, 50.379972) rotate(-300.000000) translate(-62.759333, -50.379972) translate(32.533769, 19.678480)">
              <path
                d="M58.9695706,34.518456 C59.7985673,31.9969188 60.2472709,29.3009919 60.2472709,26.4995512 C60.2472709,14.1739468 51.5614053,3.89084462 40.0160996,1.51633177"
                id="Oval-5"></path>
              <path
                d="M43.2559268,60.1495267 C44.0849235,57.6279895 44.5336271,54.9320626 44.5336271,52.1306219 C44.5336271,39.8050175 35.8477615,29.5219153 24.3024558,27.1474025"
                id="Oval-5"
                transform="translate(34.418041, 43.648465) rotate(-240.000000) translate(-34.418041, -43.648465) "></path>
              <path
                d="M29.065669,33.6329415 C29.8946658,31.1114043 30.3433693,28.4154774 30.3433693,25.6140368 C30.3433693,13.2884323 21.6575038,3.00533015 10.112198,0.630817301"
                id="Oval-5"
                transform="translate(20.227784, 17.131879) rotate(-120.000000) translate(-20.227784, -17.131879) "></path>
            </g>
          </g>
          <g id="Group-16" transform="translate(98.000000, 0.000000)">
            <polygon id="Stroke-1" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                     points="26.4999474 0 33.5213846 6.28456004 42.8779055 5.34752129 44.8823337 14.5791273 53 19.3475477 49.2218457 28.0000528 53 36.6524523 44.8823337 41.4208727 42.8779055 50.6524787 33.5213846 49.71544 26.4999474 56 19.4785103 49.71544 10.1220945 50.6524787 8.11766631 41.4208727 0 36.6524523 3.77815434 28.0000528 0 19.3475477 8.11766631 14.5791273 10.1220945 5.34752129 19.4785103 6.28456004"></polygon>
            <g id="noun_Best-Buy_3282716" transform="translate(15.000000, 3.000000)" fill="#FFFFFF"
               fontFamily="Signika-Semibold, Signika" fontSize="40" fontWeight="500">
              <text id="1">
                <tspan x="0" y="38">1</tspan>
              </text>
            </g>
            <g id="Group-15" transform="translate(15.000000, 51.000000)" stroke="#FFFFFF" strokeLinecap="round"
               strokeLinejoin="round" strokeWidth="4">
              <polyline id="Path-42"
                        points="0 0.209716797 0 29.1849435 11.8406059 20.9619141 24 29.1849435 24 0.209716797"></polyline>
            </g>
          </g>
          <g id="Group-14" transform="translate(4.000000, 108.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="118" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy" cx="128" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BestTribe;