import React  from "react";

const Steps = () => (
  <svg width="30" height="38" viewBox="0 0 30 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Boards_Student_Stats" transform="translate(-1273.000000, -454.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="icon-average-steps" transform="translate(1274.000000, 456.000000)">
          <path d="M9.17020622,27.5699566 C6.74701781,28.0786864 4.62141395,27.6123508 5.21658303,30.4103647 C5.81175211,33.2083787 8.27745259,35.0737214 10.700641,34.5649915 C13.1238294,34.0562617 14.6542642,31.3006419 14.0590951,28.5026279 C13.463926,25.704614 11.6359067,27.0188327 9.17020622,27.5699566 Z" id="Shape" transform="translate(9.651599, 30.712507) rotate(-4.000000) translate(-9.651599, -30.712507) "/>
          <path d="M7.4821256,24.8380074 C7.65217391,24.7956133 7.77971014,24.7532191 7.94975845,24.710825 C8.75748792,24.4564601 9.52270531,24.159701 10.1603865,23.608577 C10.8405797,23.0150589 11.2231884,22.1247818 11.3932367,21.2345046 C11.8183575,19.0300087 11.5207729,16.571148 10.7980676,14.4938346 C10.2028986,12.7980685 9.31014493,11.2294849 7.99227053,10.0000546 C6.97198068,9.02498909 5.56908213,8.21950022 4.1236715,8.47386512 C2.63574879,8.72823003 1.53043478,10.0848429 0.892753623,11.4838498 C-0.0425120773,13.6035574 -0.170048309,15.9776299 0.297584541,18.2245199 C0.637681159,19.8354976 1.36038647,21.4040812 2.16811594,22.8454823 C2.67826087,23.6933653 3.35845411,24.4988542 4.29371981,24.8804016 C5.35652174,25.3043431 6.46183575,25.1347665 7.4821256,24.8380074 Z" id="Shape"/>
          <path d="M20.8349931,19.2676866 C18.4118047,19.09811 16.3712249,17.5719206 15.9035921,20.4123287 C15.4359592,23.2527368 17.1364423,25.83878 19.6021428,26.2203274 C22.0678433,26.6018747 24.4485196,24.6093496 24.8736404,21.7689415 C25.2987612,18.9285334 23.2581815,19.8188106 20.8349931,19.2676866 Z" id="Shape"/>
          <path d="M23.7011806,0.0445207668 C21.4055284,-0.294632441 19.7900695,1.35873945 18.7272675,3.13929379 C17.5794414,5.00463644 16.9417603,7.16673814 16.771712,9.37123399 C16.6866878,10.5158761 16.771712,11.6605181 16.9842724,12.8051602 C17.1968328,13.7802257 17.3668811,14.7976853 18.2171226,15.4759917 C18.7697796,15.8999333 19.4499729,16.111904 20.1301661,16.2814806 C21.1079439,16.5358455 22.0857216,16.6206338 23.1060115,16.6206338 C24.3388617,16.6206338 25.1040791,16.1542982 25.7842724,15.1368385 C27.0596347,13.2714959 27.78234,11.1093942 27.9523883,8.86250418 C28.0799245,6.78519078 27.7398279,4.41111832 26.8045622,2.50338153 C26.4644656,1.86746926 26.0393448,1.231557 25.4866878,0.765221334 C24.9340308,0.383673975 24.3813738,0.17170322 23.7011806,0.0445207668 Z" id="Shape"/>
        </g>
      </g>
    </g>
  </svg>);

export default Steps;
