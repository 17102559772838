import React from "react";

const Community = () => (
  <svg width="83" height="83" viewBox="0 0 83 83" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

    <defs/>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HoT_LandingPage1440px" transform="translate(-925.000000, -1908.000000)">
        <g id="2-row" transform="translate(925.000000, 1516.000000)">
          <g id="Group-17-Copy-5" transform="translate(0.000000, 392.000000)">
            <g id="icon-features-community">
              <circle id="Oval-Copy" fill="#F2F6FF" cx="41.5" cy="41.5" r="41.5"/>
              <g id="icon-member" transform="translate(20.000000, 24.000000)" stroke="#36A3D8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
                <path d="M5.89354959,35.6704851 L5.89354959,30.3888255 C5.89354959,30.3888255 6.1432438,26.2327404 11.7053554,24.4975787 C17.2674669,22.762417 17.9946818,22.0965702 17.9946818,18.6052213 C17.9946818,17.5124681 15.1817727,16.4261489 15.1817727,10.6654596 C15.1817727,4.90473191 17.5728347,1.31645106 22.2601983,1.31645106 C26.947562,1.31645106 29.3730248,4.90473191 29.3730248,10.6654596 C29.3730248,16.4261489 26.5601157,17.5124681 26.5601157,18.6052213 C26.5601157,22.0965702 27.2872934,22.762417 32.849405,24.4975787 C38.411405,26.2327404 38.6613223,30.3888255 38.6613223,30.3888255 L38.6613223,35.6704851" id="Stroke-1"/>
                <path d="M0,29.8157745 L0,25.2318255 C0,25.2318255 0.216706612,21.6247787 5.04405372,20.1188298 C9.87140083,18.6128809 10.5025165,18.0350043 10.5025165,15.0048766 C10.5025165,14.0564681 8.06121074,13.1136894 8.06121074,8.11397872 C8.06121074,3.11426809 10.1364174,0 14.2045661,0 C16.1789132,0 17.6909132,0.733557447 18.7242769,2.06011915" id="Stroke-2"/>
                <path d="M44.6556198,29.8157745 L44.6556198,25.2318255 C44.6556198,25.2318255 44.4388017,21.6247787 39.6115289,20.1188298 C34.7842562,18.6128809 34.1531033,18.0350043 34.1531033,15.0048766 C34.1531033,14.0564681 36.5942975,13.1136894 36.5942975,8.11397872 C36.5942975,3.11426809 34.5192025,0 30.4510537,0 C28.4767066,0 26.9647438,0.733557447 25.9313802,2.06011915" id="Stroke-3"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>);

export default Community;
