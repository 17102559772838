import React, {JSXElementConstructor} from 'react';
import BagGradient      from "./icons/BagGradient";
import BagWhite         from "./icons/BagWhite";
import LeftArrow        from "./icons/LeftArrow";
import ArrowRight       from "./icons/ArrowRight";
import Info             from "./icons/Info";
import AddBoard         from "./icons/AddBoard";
import Board            from "./icons/Board";
import BoardRequest     from "./icons/BoardRequest";
import Envelope         from "./icons/Envelope";
import EnvelopeRound    from "./icons/EnvelopeRound";
import Heart            from "./icons/Heart";
import Medal            from "./icons/Medal";
import Menu             from "./icons/Menu";
import Plus             from "./icons/Plus";
import Search           from "./icons/Search";
import Badge            from "./icons/Badge";
import Eye              from "./icons/Eye";
import ArrowUp          from "./icons/ArrowUp";
import ArrowDown        from "./icons/ArrowDown";
import Close            from "./icons/Close";
import Shoe             from "./icons/Shoe";
import PlaceMark        from "./icons/PlaceMark";
import Org              from "./icons/Org";
import Team             from "./icons/Team";
import AccountSettings  from "./icons/AccountSettings";
import Billing          from "./icons/Billing";
import ManageTeam       from "./icons/ManageTeam";
import Comparison       from "./icons/Comparison";
import Filter           from "./icons/Filter";
import Run              from "./icons/Run";
import Walk             from "./icons/Walk";
import User             from "./icons/User";
import Lock             from "./icons/Lock";
import Notification     from "./icons/Notification";
import Statistics       from "./icons/Statistics";
import Settings         from "./icons/Settings";
import MyBoard          from "./icons/MyBoard";
import NotificationBell from "./icons/BellNotification";
import BestPlayer       from "./icons/BestPlayer";
import BestTribe        from "./icons/BestTribe";
import TotalSteps       from "./icons/TotalSteps";
import TotalDistance    from "./icons/TotalDistance";
import Routes           from "./icons/Routes";
import Map              from "./icons/Map";
import ArrowMobileRight from "./icons/MobileMenuArrowRight";
import TrailerBtn       from "./icons/TrailerBtn";
import WalkMan          from "./icons/WalkMan";
import Launch           from "./icons/Launch";
import Recycle          from "./icons/Recycle";
import Steps            from "./icons/Steps";
import Heart2           from "./icons/Heart2";
import Community        from "./icons/community";
import SliderPrevArrow from "./icons/SliderPrevArrow";
import SliderNextArrow from "./icons/SliderNextArrow";
import LinkArrow from "components/shared/Icon/icons/LinkArrow";
import Dashboard from "./icons/Dashboard";
import BoardsNav from "./icons/BoardsNav";
import Invite from "./icons/Invite";
import InviteCode from "./icons/InviteCode";
import InviteCodeBoard from "./icons/InviteCodeBoard";
import InviteCodeParty from "./icons/InviteCodeParty";
import StatisticsNav from "./icons/StatisticsNav";
import SettingsNav from "./icons/SettingsNav";
import Members from "./icons/Members";
import BoardSuccess from "./icons/BoardSuccess";
import GoalsHeart from "./icons/GoalsHeart"
import HPHand from "./icons/HPHand";
import EnduranceWhite from "./icons/Endurance-white";
import SpeedWhite from "./icons/Speed-white";
import StrengthWhite from "./icons/Strength-white";
import Wallet from "./icons/Wallet";
import Star from "./icons/Star";
import Flag from "./icons/Flag";
import './Icon.scss';

export interface IconsPropsInterface {
  className?: string,
  iconClass?: string
}

/**
 * Interface for Icon component props
 */
export interface IconPropsInterface extends IconsPropsInterface{
  name: string,
  [key: string]: any
}

const mapping: { [key: string]: JSXElementConstructor<any> } = {
  "account-settings":   AccountSettings,
  "add-board":          AddBoard,
  "arrow-up":           ArrowUp,
  "arrow-down":         ArrowDown,
  "arrow-left":         LeftArrow,
  "arrow-right":        ArrowRight,
  "badge":              Badge,
  "best-player":        BestPlayer,
  "best-tribe":         BestTribe,
  "board":              Board,
  "board-request":      BoardRequest,
  "calculator":         Billing,
  "close":              Close,
  "comparison":         Comparison,
  "eye":                Eye,
  "envelope-round":     EnvelopeRound,
  "envelope":           Envelope,
  "filter":             Filter,
  "heart":              Heart,
  "manage-team":        ManageTeam,
  "my-board":           MyBoard,
  "notification":       Notification,
  "notification-bell":  NotificationBell,
  "lock":               Lock,
  "map":                Map,
  "medal":              Medal,
  "menu":               Menu,
  "menu-arrow-right":   ArrowMobileRight,
  "info":               Info,
  "placemark":          PlaceMark,
  "org":                Org,
  "plus":               Plus,
  "user":               User,
  "routes":             Routes,
  "run":                Run,
  "search":             Search,
  "settings":           Settings,
  "shoe":               Shoe,
  "stats":              Statistics,
  "total-distance":     TotalDistance,
  "total-steps":        TotalSteps,
  "team":               Team,
  "trailer":            TrailerBtn,
  "walk":               Walk,
  "walkMan":            WalkMan,
  "launch":             Launch,
  "recycle":            Recycle,
  "steps":              Steps,
  "heart2":             Heart2,
  "community":          Community,
  "prev-arrow":         SliderPrevArrow,
  "next-arrow":         SliderNextArrow,
  "link-arrow":         LinkArrow,
  //board-card
  "members":            Members,
  "board-success":      BoardSuccess,

  //footer
  "invite":             Invite,
  "dashboard":          Dashboard,
  "board-nav":          BoardsNav,
  "statistics-nav":     StatisticsNav,
  "settings-nav":       SettingsNav,

  "invite-code":        InviteCode,
  "invite-code-board":  InviteCodeBoard,
  "invite-code-party":  InviteCodeParty,

  "bag-white":          BagWhite,
  "bag-gradient":       BagGradient,
  "goal-heart":         GoalsHeart,
  "hp_hand":            HPHand,
  "endurance":          EnduranceWhite,
  "speed":              SpeedWhite,
  "strength":           StrengthWhite,
  "wallet":             Wallet,
  "star":               Star,
  "flag":               Flag,
}

const Icon: React.FC<IconPropsInterface> = ({name, className, iconClass,...props}) => {

  if(!mapping.hasOwnProperty(name)) return null;

  const IconComponent = mapping[name];
  return (
    <span {...props} className={`Icon ${className || ""}`}>
      <IconComponent className={`Icon-img ${iconClass}`} {...props}/>
    </span>
  );
};

export default Icon;
