import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Billing: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="179" height="100" viewBox="0 0 179 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="billing-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1729.000000, -1455.000000)">
        <g id="icon-billings" transform="translate(1729.000000, 1457.000000)">
          <g id="Group-8">
            <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="4" x="28" y="0" width="124" height="95" rx="11"></rect>
            <path d="M77,55 L104.575434,55" id="Path-39" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M77,69 L87.5,69" id="Path-39-Copy-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M96,69 L116.5,69" id="Path-39-Copy-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M113,55 L125.5,55" id="Path-39-Copy" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M49.25,54.3125 C49.25,56.5874375 51.156125,58.4375 53.5,58.4375 C58.18775,58.4375 62,62.137625 62,66.6875 C62,70.519625 59.28,73.7226875 55.625,74.644625 L55.625,74.9375 C55.625,76.076 54.673,77 53.5,77 C52.327,77 51.375,76.076 51.375,74.9375 L51.375,74.644625 C47.72,73.7226875 45,70.519625 45,66.6875 C45,65.549 45.952,64.625 47.125,64.625 C48.298,64.625 49.25,65.549 49.25,66.6875 C49.25,68.9624375 51.156125,70.8125 53.5,70.8125 C55.843875,70.8125 57.75,68.9624375 57.75,66.6875 C57.75,64.4125625 55.843875,62.5625 53.5,62.5625 C48.81225,62.5625 45,58.862375 45,54.3125 C45,50.480375 47.72,47.2773125 51.375,46.355375 L51.375,46.0625 C51.375,44.924 52.327,44 53.5,44 C54.673,44 55.625,44.924 55.625,46.0625 L55.625,46.355375 C59.28,47.2773125 62,50.480375 62,54.3125 C62,55.451 61.048,56.375 59.875,56.375 C58.702,56.375 57.75,55.451 57.75,54.3125 C57.75,52.0375625 55.843875,50.1875 53.5,50.1875 C51.156125,50.1875 49.25,52.0375625 49.25,54.3125 L49.25,54.3125 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M95,15 L103.15456,15" id="Path-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M111,15 L119.15456,15" id="Path-3-Copy" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M127,15 L135.15456,15" id="Path-3-Copy-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M45,15 L61,15" id="Path-3-Copy-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
          </g>
          <g id="dots-left-right" transform="translate(0.000000, 92.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="166" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy" cx="176" cy="3" r="3"></circle>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Billing;