import React from "react";

const Members = () =>
  <svg width="132" height="105" viewBox="0 0 132 105" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
       strokeLinejoin="round">
      <g id="icons" transform="translate(-3770.000000, -1152.000000)" stroke="#FFFFFF" strokeWidth="6">
        <g id="icon-member" transform="translate(3773.000000, 1155.000000)">
          <path
            d="M16.5019388,98.093834 L16.5019388,83.5692702 C16.5019388,83.5692702 17.2010826,72.1400362 32.774995,67.3683415 C48.3489074,62.5966468 50.3851091,60.7655681 50.3851091,51.1643585 C50.3851091,48.1592872 42.5089636,45.1719096 42.5089636,29.3300138 C42.5089636,13.4880128 49.2039372,3.62024043 62.3285554,3.62024043 C75.4531736,3.62024043 82.2444694,13.4880128 82.2444694,29.3300138 C82.2444694,45.1719096 74.368324,48.1592872 74.368324,51.1643585 C74.368324,60.7655681 76.4044215,62.5966468 91.9783339,67.3683415 C107.551934,72.1400362 108.251702,83.5692702 108.251702,83.5692702 L108.251702,98.093834"
            id="Stroke-1"/>
          <path
            d="M0,81.9933798 L0,69.3875202 C0,69.3875202 0.606778512,59.4681415 14.1233504,55.3267819 C27.6399223,51.1854223 29.4070463,49.5962617 29.4070463,41.2634106 C29.4070463,38.6552872 22.5713901,36.0626457 22.5713901,22.3134415 C22.5713901,8.56423723 28.3819686,0 39.7727851,0 C45.300957,0 49.534557,2.01728298 52.4279752,5.66532766"
            id="Stroke-2"/>
          <path
            d="M125.035736,81.9933798 L125.035736,69.3875202 C125.035736,69.3875202 124.428645,59.4681415 110.912281,55.3267819 C97.3959174,51.1854223 95.6286893,49.5962617 95.6286893,41.2634106 C95.6286893,38.6552872 102.464033,36.0626457 102.464033,22.3134415 C102.464033,8.56423723 96.6537669,0 85.2629504,0 C79.7347785,0 75.5012826,2.01728298 72.6078645,5.66532766"
            id="Stroke-3"/>
        </g>
      </g>
    </g>
  </svg>

export default Members
