import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const ArrowUp: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="48" height="28" viewBox="0 0 48 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icons" transform="translate(-3048.000000, -1491.000000)" stroke="#FFFFFF" strokeWidth="8">
        <polyline id="arrow-up-header-accordion" transform="translate(3071.838076, 1504.939744) rotate(-90.000000) translate(-3071.838076, -1504.939744) " points="3061.98116 1485.10167 3081.77782 1504.89833 3061.89833 1524.77782"/>
      </g>
    </g>
  </svg>
);

export default ArrowUp;