import React from "react";

const StatisticsNav = () =>
  <svg className='statistics' width="68" height="68" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="icon-statistic-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <g transform="translate(3.000000, 3.000000)" stroke="#FFFFFF" strokeWidth="3">
      <path d="M61.5709736,15.4710196 C61.5709736,6.93232826 54.6737121,0 46.1782132,0 L15.3927604,0 C6.89726154,0 0,6.93232826 0,15.4710196 L0,47.0790783 C0,55.6177696 6.89726154,62.5500978 15.3927604,62.5500978 L46.1782132,62.5500978 C54.6737121,62.5500978 61.5709736,55.6177696 61.5709736,47.0790783 L61.5709736,15.4710196 Z" id="Stroke-1"/>
      <path d="M16.6340549,47.5594533 L16.6340549,25.9974978" id="Stroke-2"/>
      <path d="M45.2564571,47.5594533 L45.2564571,37.1335696" id="Stroke-3"/>
      <path d="M31.1393978,47.5594533 L31.1393978,15.6535141" id="Stroke-4"/>
    </g>
  </g>
</svg>

export default StatisticsNav;
