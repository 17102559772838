import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const Routes: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="102" height="111" viewBox="0 0 102 111" version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1234.000000, -1449.000000)">
        <g id="icon-routes" transform="translate(1235.000000, 1451.000000)">
          <path
            d="M31.9477539,72.9550781 L31.9477539,72.9550781 C35.6531456,72.9550781 38.9097619,75.4110264 39.9284393,78.9736411 L46.2405583,101.048979 C47.2930908,104.729995 51.0909039,106.898331 54.7958984,105.933594 L54.7958984,105.933594 C58.4940569,104.970636 60.7380142,101.221375 59.839208,97.5071041 L59.5986328,96.5129395 C58.4464072,91.7514255 59.9017247,86.7357918 63.4236005,83.3304975 L63.934082,82.8369141"
            id="Path-5" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M72.4594727,75.8588867 L83.7146669,66.9878397 C86.3686506,64.8960398 87.9174805,61.7032137 87.9174805,58.3239746 L87.9174805,58.3239746 C87.9174805,54.8877958 86.60039,51.582321 84.2372418,49.0877558 L83.7600098,48.5839844"
            id="Path-6" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <circle id="Oval-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" cx="68"
                  cy="79" r="5"/>
          <circle id="Oval-3-Copy" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" cx="80"
                  cy="45" r="5"/>
          <circle id="Oval-3-Copy-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                  cx="77" cy="6" r="5"/>
          <circle id="Oval-3-Copy-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                  cx="38" cy="16" r="5"/>
          <circle id="Oval-3-Copy-4" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                  cx="12" cy="44" r="5"/>
          <circle id="Oval-3-Copy-5" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
                  cx="21.5" cy="72.5" r="10.5"/>
          <circle id="Oval-3-Copy-5" fill="#FFFFFF" cx="21.5" cy="72.5" r="5.5"/>
          <path
            d="M75.7294922,41.645752 L75.7294922,41.645752 C73.6270565,40.0211145 72.3959961,37.5140405 72.3959961,34.8570342 L72.3959961,32.4318848 C72.3959961,28.1722927 74.0423503,24.077538 76.9909513,21.0034728 L80.3050156,17.5483935 C82.2728992,15.4967756 82.4133914,12.3036825 80.6333008,10.0871582"
            id="Path-7" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M73.5258789,2.31445312 L73.5258789,2.31445312 C71.9159949,1.14957253 69.9795498,0.522460937 67.9924229,0.522460938 L65.842041,0.522460938 C62.509787,0.522460938 59.3159674,1.8553062 56.9722231,4.22401457 L48.5398251,12.7462292 C47.1006246,14.2007592 45.2608768,15.1937588 43.255127,15.5986328"
            id="Path-8" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M33.4301758,13.6774902 L31.3573816,13.0014327 C27.6887731,11.8048882 23.6912599,12.1521507 20.2839355,13.9633789 L20.2839355,13.9633789 C16.9625775,15.7289101 14.7726891,19.0681806 14.4770893,22.8179993 L13.2192383,38.7744141"
            id="Path-9" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M9.015625,48.5510254 L4.34231514,55.4969087 C2.25884107,58.5935507 1.14599609,62.2410896 1.14599609,65.9733887 L1.14599609,65.9733887 C1.14599609,69.1356773 3.70953754,71.6992188 6.87182617,71.6992188 L10.6733398,71.6992188"
            id="Path-10" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <g id="dots-left-right" transform="translate(0.000000, 98.000000)" fill="#FFFFFF">
            <g id="right" transform="translate(85.000000, 0.000000)">
              <circle id="Oval-2" cx="3" cy="3" r="3"/>
              <circle id="Oval-2-Copy" cx="13" cy="3" r="3"/>
            </g>
            <g id="left">
              <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
              <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Routes