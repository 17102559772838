import React from "react";

const BoardSuccess = () =>
  <svg width="102px" height="102px" viewBox="0 0 102 102" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-3569.000000, -1149.000000)">
        <g id="icon-myboard" transform="translate(3572.000000, 1152.000000)">
          <path
            d="M83.9252494,68.2384 L74.6594157,77.6094933 L70.3950742,73.6528 C69.5527551,72.8718933 68.2892225,72.7677867 67.3415191,73.4445867 C66.236009,74.2254933 65.9727101,75.6832 66.762391,76.7765333 L72.2377348,84.4817067 C72.3956494,84.7419733 72.6588404,84.9501867 72.8695011,85.1585067 C74.2383101,86.19968 76.1862472,85.9394133 77.2391191,84.5858133 L77.3443955,84.4817067 L87.5579326,71.4141867 C88.2949753,70.4770133 88.2423371,69.1234133 87.3473798,68.2384 C86.3470382,67.3012267 84.8203146,67.3012267 83.9252494,68.2384 Z"
            id="Fill-1" fill="#FFFFFF"/>
          <path
            d="M0,95.6823467 L0,80.9719467 C0,80.9719467 0.724206742,69.39648 16.8564135,64.5637333 C32.9886202,59.7309867 35.0978157,57.87648 35.0978157,48.1524267 C35.0978157,45.1089067 26.9393258,42.0833067 26.9393258,26.03872 C26.9393258,9.99402667 33.8743011,0 47.4694112,0 C61.0645213,0 68.0992719,9.99402667 68.0992719,26.03872 C68.0992719,42.0833067 59.940782,45.1089067 59.940782,48.1524267 C59.940782,54.6608 60.885573,57.64384 66.3475416,60.3540267"
            id="Stroke-2" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M76.784036,57.2706133 C87.2419955,57.2706133 95.7324944,65.66688 95.7324944,76.00864 C95.7324944,86.3504 87.2419955,94.74656 76.784036,94.74656 C66.3260764,94.74656 57.8354697,86.3504 57.8354697,76.00864 C57.8354697,65.66688 66.3260764,57.2706133 76.784036,57.2706133 Z"
            id="Stroke-3" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </g>
    </g>
  </svg>;

export default BoardSuccess;
