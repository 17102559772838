import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Walk: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className} width="67" height="138" viewBox="0 0 67 138" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="walk-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-4803.000000, -217.000000)" fill="#FFFFFF">
        <g id="icon-walk" transform="translate(4803.000000, 217.000000)">
          <path d="M37.3300258,-1.42108547e-14 C44.4145935,-1.42108547e-14 50.1662806,5.82510937 50.1662806,13.0000312 C50.1662806,20.1749531 44.4145935,26.0011406 37.3300258,26.0011406 C30.2454581,26.0011406 24.493771,20.1749531 24.493771,13.0000312 C24.493771,5.82510937 30.2454581,-1.42108547e-14 37.3300258,-1.42108547e-14 Z" id="Fill-1"/>
          <path d="M42.6005859,60.7107281 L56.4565408,71.616825 C59.2399311,73.807575 63.2559246,73.2976219 65.4191279,70.4786484 C67.5822246,67.659675 67.0787085,63.5923406 64.2953182,61.4014828 L45.7628376,46.8145594 C45.450615,46.5688547 45.1229569,46.3571109 44.7841214,46.1787891" id="Fill-2"/>
          <path d="M31.0441645,96.6333618 L17.8910032,86.1699829 L5.64896129,123.254592 C4.31363226,127.507472 6.63874839,132.058238 10.8379452,133.410638 C15.0371419,134.763037 19.5304645,132.408197 20.8657935,128.155317 L31.0441645,96.6333618 Z" id="Fill-3"/>
          <path d="M29.6872258,29.040375 L29.873729,28.9379531 C31.1864903,28.2177656 32.639129,28.0118438 34.0050097,28.2533437 C34.3652419,28.27275 34.7283484,28.3137187 35.0928387,28.37625 C40.3065194,29.2689375 43.8235742,34.2804937 42.9419419,39.5609344 C42.9419419,39.5609344 38.9458548,63.4948781 37.3775032,72.8883656 C36.9945968,75.1818609 37.8780387,77.50425 39.6814355,78.944625 C43.0754323,81.6554625 48.2119355,85.7580516 48.2119355,85.7580516 C52.4273129,89.1250359 55.1846226,94.0204781 55.9005097,99.4091625 C57.1612161,108.897741 59.7205258,128.159953 59.7205258,128.159953 C60.3081387,132.582853 57.2397774,136.656872 52.8727065,137.252105 C48.5056355,137.84723 44.4830419,134.739534 43.895429,130.316742 C43.895429,130.316742 41.3361194,111.054422 40.0754129,101.565844 C39.912329,100.338398 39.2842645,99.2232938 38.324071,98.4563156 C33.7198258,94.7788313 26.2718323,88.8299531 21.9616065,85.3872844 C17.5612161,81.8725969 15.4402742,76.1819297 16.4500742,70.5989672 C16.4500742,70.5989672 19.1486226,55.6786875 20.0748581,50.5581328 C20.1314903,50.2446141 20.0055581,49.9260281 19.7508194,49.7387578 C19.4960806,49.5515953 19.1578839,49.5290625 18.8811097,49.6808625 C18.3094645,49.9943812 17.7930677,50.2777125 17.7930677,50.2777125 C17.3673677,50.5111266 17.0644065,50.922 16.964129,51.4016578 C15.5400194,58.2150844 12.6367645,72.1056469 12.6367645,72.1056469 C11.9067194,75.5984484 8.51410645,77.8338328 5.06539355,77.0944547 C1.61668065,76.3550766 -0.590487097,72.9190922 0.139558065,69.4262906 C0.139558065,69.4262906 3.0428129,55.5357281 4.46692258,48.7224094 C5.34280645,44.5317375 7.99047097,40.9415812 11.7094645,38.9015531 L28.226071,29.8416375 C28.6883903,29.5329703 29.1772161,29.264625 29.6872258,29.040375 Z" id="Fill-4"/>
        </g>
      </g>
    </g>
  </svg>
);

export default Walk;