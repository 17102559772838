import React, {useContext} from "react";
import {adminRoute, footerNavLinks} from "config/nav";
import {MenuNavTitleText, userRoles} from "config/names";
import AppLink from "components/shared/RouterDecorators/AppLink";
import Icon from "components/shared/Icon/Icon";
import {UserContext} from "context/User";
import "./FooterNavigation.scss"
import {appSections, routes} from "../../config/urls";


const FooterNavigation = () => {
  const {user} = useContext(UserContext);
  const linkByRole = user?.isOrganizationManager ? userRoles.MANAGER : userRoles.USER;

  return (
    <div className='Footer-nav'>
      {
        user?.isAdmin
        && <AppLink to={adminRoute.link} key={adminRoute.name} className="Footer-nav-item" activeClassName="activeLink">
              <Icon name={adminRoute.icon} className={`Footer-nav-icon ${adminRoute.className || ""}`}/>
              <p className='Footer-nav-name'>{adminRoute.name}</p>
          </AppLink>
      }
      {footerNavLinks[linkByRole].map(
        ({link, name, icon, className}) =>
          <AppLink to={link} key={name} className="Footer-nav-item" activeClassName="activeLink">
            <Icon name={icon} className={`Footer-nav-icon ${className || ""}`}/>
            <p className='Footer-nav-name'>{name}</p>
            {/*{name === "Notifications" && notifications > 0 && <span className="notification-mark-number">{notifications}</span>}*/}
          </AppLink>
      )}
    </div>
  );
};

export default FooterNavigation;
