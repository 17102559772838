import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const ArrowDown: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="48" height="28" viewBox="0 0 48 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icons" transform="translate(-3048.000000, -1869.000000)" stroke="#FFFFFF" strokeWidth="8">
        <polyline id="arrow-down-header-accordion" transform="translate(3071.838076, 1882.939744) scale(1, -1) rotate(-90.000000) translate(-3071.838076, -1882.939744) " points="3061.98116 1863.10167 3081.77782 1882.89833 3061.89833 1902.77782"/>
      </g>
    </g>
  </svg>
);

export default ArrowDown;
