import React, {createContext, useCallback, useState} from "react";
import {globalContextInterface, layoutParamsInterface, notificationInterface} from "interfaces/misc";

export const GlobalContext = createContext<Partial<globalContextInterface>>({});

/**
 Global context component
 */
export const GlobalContextProvider: React.FC = ({children}) => {
  const [notifications, setNotifications] = useState<notificationInterface[]>([]);
  const [layoutParams, setLayoutParams] = useState<layoutParamsInterface>(defaultLayoutParams);
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<string>("");
  const [isAppLoading, setIsAppLoading] = useState<boolean>(false);

  const handleRedirectAfterLogin = (str: string) => {
    setRedirectAfterLogin(str)
  }

  const showNotification = (notification: notificationInterface) => setNotifications((pv) => [...pv, notification]);
  const hideNotification = (notification: notificationInterface) =>
    setNotifications((pv) => {

      const newSet = pv.slice();
      newSet.splice(newSet.indexOf(notification), 1);
      return newSet;
    });

  const hideAllNotifications = () => setNotifications([]);

  const changeLayoutParams = useCallback((params: Partial<layoutParamsInterface>) => {
    setLayoutParams(() => ({
      header: {...defaultLayoutParams.header, ...params.header},
      main: {...defaultLayoutParams.main, ...params.main},
      footer: {...defaultLayoutParams.footer, ...params.footer},
    }));
  }, []);
  return (
    <GlobalContext.Provider
      value={{
        redirectAfterLogin,
        notifications,
        layoutParams,
        showNotification,
        hideNotification,
        hideAllNotifications,
        changeLayoutParams,
        handleRedirectAfterLogin,
        isAppLoading,
        setIsAppLoading
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const defaultLayoutParams = {
  header: {
    show: true,
    title: "",
    className: "",
  },
  main: {
    cover: true,
    className: "",
  },
  footer: {
    className: "",
    children: null,
  },
};
