import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Envelope: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="48" height="40" viewBox="0 0 48 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Envelope-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Miscellaneous/Icons" transform="translate(-1028.000000, -566.000000)" stroke="#A5ADBA" strokeWidth="3">
        <g id="icon-email-input-field" transform="translate(1030.428571, 568.000000)">
          <path d="M38.8235294,36 L5.17647059,36 C2.31776471,36 0,33.6972857 0,30.8571429 L0,5.14285714 C0,2.30271429 2.31776471,0 5.17647059,0 L38.8235294,0 C41.6822353,0 44,2.30271429 44,5.14285714 L44,30.8571429 C44,33.6972857 41.6822353,36 38.8235294,36 Z" id="Shape"></path>
          <polyline id="Shape" points="0 5.14285714 22 18 44 5.14285714"></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export default Envelope;