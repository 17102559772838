import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const MyBoard: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="179" height="114" viewBox="0 0 179 114" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="myBoard-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-2166.000000, -240.000000)">
        <g id="MyBoards-Button" transform="translate(2121.000000, 195.000000)">
          <g id="icon-myboards" transform="translate(45.000000, 39.000000)">
            <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="4" x="28" y="8" width="124" height="110" rx="11"/>
            <path
  d="M121,94 C119.726615,91.9348 116.848385,88.2016 113.846154,86.8 L101.923077,82 C101.923077,82 97.1538462,84.4 90,84.4 C82.8461538,84.4 78.0769231,82 78.0769231,82 L66.1538462,86.8 C63.1516154,88.2016 60.2733846,91.9348 59,94"
  id="Stroke-22" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path
  d="M97.7811737,74 C104.542666,69.5705769 103.915298,66.7269231 104.721047,60.2836923 C105.154258,56.8069231 105.285313,53.0595 103.577952,49.8676923 C102.01378,46.9453462 99.1414811,44.9208077 96.0034304,43.8799231 C94.2827205,43.31 92.3981913,43 90.5003138,43 C88.6024363,43 86.717907,43.31 84.9971971,43.8799231 C81.8591465,44.9208077 78.9868472,46.9453462 77.4226758,49.8676923 C75.7128872,53.0595 75.8463696,56.8069231 76.2795808,60.2836923 C77.0853293,66.7269231 76.4579618,69.5705769 83.2194538,74"
  id="Stroke-23" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <g id="Group-5"
               transform="translate(89.500000, 102.000000) scale(1, -1) translate(-89.500000, -102.000000) translate(27.000000, 86.000000)"
               stroke="#FFFFFF" strokeWidth="4">
              <path d="M0,12 L125,12" id="Path-32-Copy"/>
            </g>
            <circle id="Oval-2" fill="#FFFFFF" cx="166" cy="117" r="3"/>
            <circle id="Oval-2-Copy-2" fill="#FFFFFF" cx="3" cy="117" r="3"/>
            <circle id="Oval-2-Copy" fill="#FFFFFF" cx="176" cy="117" r="3"/>
            <circle id="Oval-2-Copy-3" fill="#FFFFFF" cx="13" cy="117" r="3"/>
            <g id="Group-6" transform="translate(27.000000, 0.000000)" stroke="#FFFFFF" strokeWidth="4">
              <path d="M0,31 L125,31" id="Path-32"/>
              <path d="M1,19 L21.3201567,19" id="Path-33" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M109,20 L125.20427,20" id="Path-33-Copy" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MyBoard;