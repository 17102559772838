import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Shoe: React.FC<IconsPropsInterface> = ({className}) => (
  <svg width="148" height="122" viewBox="0 0 148 122" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink" className={className || ""}>
    <g id="Shoe-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
       strokeLinejoin="round">
      <g id="App_Game_IPhoneX_Leaderboard_Total_Steps_Daily" transform="translate(-489.000000, -613.000000)"
         stroke="#FFFFFF" strokeWidth="3.26571437">
        <g id="BEST_WINER" transform="translate(44.000000, 546.000000)">
          <g id="icon-step" transform="translate(447.000000, 69.000000)">
            <path d="M82.8299455,117.885764 L15.714,117.885764" id="Stroke-1"/>
            <path d="M73.9315636,109.021255 L47.5892182,109.021255" id="Stroke-2"/>
            <path
  d="M0,44.4582 C0,44.4582 9.32236364,61.9211455 25.7535818,69.9952909 C42.1848,78.0692727 68.2605818,94.0622727 80.1626727,101.993891 C92.0646,109.925509 123.248618,126.448036 140.6844,111.580036 C140.6844,111.580036 150.705327,104.784382 133.230273,93.4924909 C115.755382,82.2004364 102.190582,65.7016364 92.4308182,51.4127455 C82.6710545,37.1236909 84.3147818,26.3385818 59.6395636,29.1721091 C34.9645091,32.0054727 39.4535455,6.68029091 39.4535455,2.05969091 C39.4535455,-2.56074545 32.5610182,0.911945455 29.0974909,9.28996364 C25.6339636,17.6681455 18.6702545,35.3403818 0,44.4582 Z"
  id="Stroke-3"/>
            <path
  d="M12.1683273,36.0793636 C12.1683273,36.0793636 36.4858364,45.3302182 61.5681818,68.0977636 C86.6505273,90.8653091 118.818982,111.469582 141.1902,109.864309"
  id="Stroke-4"/>
            <path
  d="M67.0789636,27.7867636 C67.0789636,27.7867636 69.1501091,18.7183636 78.0460364,18.4745455 C86.9418,18.2307273 87.7025455,33.7341273 88.0988727,42.6109091"
  id="Stroke-5"/>
            <path
  d="M39.1910727,50.7117273 C39.1910727,50.7117273 56.8325455,43.7504727 61.0988727,43.5066545 L64.5825273,53.9356909 L50.5737818,58.8753818"
  id="Stroke-6"/>
            <path
  d="M61.1253818,67.6971818 C61.1253818,67.6971818 75.0801273,61.9978909 79.3462909,61.7540727 L82.8299455,72.1831091 L71.0721818,76.3291636"
  id="Stroke-7"/>
            <path d="M73.9315636,39.7590545 C73.9315636,39.7590545 82.5021818,37.8880364 83.9762182,37.5421091"
  id="Stroke-8"/>
            <path d="M84.3872727,54.7425818 C84.3872727,54.7425818 91.7064,52.8715636 92.9650909,52.5256364"
  id="Stroke-9"/>
            <path d="M95.3270182,69.3147273 C95.3270182,69.3147273 102.646145,67.4437091 103.904836,67.0977818"
  id="Stroke-10"/>
            <path d="M104.487382,79.6395273 C104.487382,79.6395273 111.806509,77.7685091 113.0652,77.4225818"
  id="Stroke-11"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Shoe;
