import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Search: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="13" height="13" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Search-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Buttons" transform="translate(-459.000000, -1276.000000)" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.666666667">
        <g id="Group" transform="translate(143.000000, 1265.000000)">
          <g id="Search">
            <path d="M321.581082,20.2425203 C320.51387,20.2425203 319.510766,19.8269452 318.755939,19.0722 C318.001572,18.3180293 317.586016,17.3148812 317.585786,16.2476217 C317.585786,15.1805919 318.001343,14.177214 318.755939,13.4225838 C319.510651,12.6680684 320.51387,12.2524932 321.580852,12.2524932 C322.647835,12.2524932 323.651168,12.6680684 324.405765,13.4228135 C325.160362,14.177214 325.575918,15.1805919 325.575918,16.2478515 C325.575918,17.3148812 325.160362,18.3180293 324.405765,19.0722 C323.651168,19.8269452 322.648064,20.2425203 321.581082,20.2425203 M328.19874,22.215497 L325.365325,19.3821867 C326.096599,18.5026656 326.495038,17.4059929 326.495038,16.2478515 C326.495038,14.9350614 325.983892,13.7010892 325.055812,12.7726228 C324.127502,11.8445011 322.893354,11.3333333 321.580852,11.3333333 C320.26835,11.3333333 319.034203,11.8445011 318.106122,12.7726228 C317.177812,13.7008594 316.666667,14.9350614 316.666667,16.2476217 C316.666896,17.5604118 317.178042,18.7943839 318.106122,19.7223907 C319.034433,20.6505124 320.26835,21.1616802 321.581082,21.1616802 C322.738942,21.1616802 323.835566,20.7633393 324.715163,20.0320327 L327.548808,22.8655729 C327.638652,22.9553059 327.756184,23 327.873716,23 C327.991364,23 328.108896,22.9550761 328.19874,22.8653431 C328.378198,22.6861069 328.378198,22.394963 328.19874,22.215497" id="icon-search"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Search