import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const Map: React.FC<IconPropsInterface> = ({className}) => (
<svg className={className || ""} width="149" height="98" viewBox="0 0 149 98" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="icons" transform="translate(-1211.000000, -1684.000000)">
      <g id="icon-maps" transform="translate(1211.000000, 1686.000000)">
        <path d="M86.5,24 C86.5,30.627 81.127,36 74.5,36 C67.873,36 62.5,30.627 62.5,24 C62.5,17.373 67.873,12 74.5,12 C81.127,12 86.5,17.373 86.5,24 Z" id="Stroke-1" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M98.5,24 C98.5,37.254 79,63 74.5,63 C70,63 50.5,37.254 50.5,24 C50.5,10.746 61.246,0 74.5,0 C87.7555,0 98.5,10.746 98.5,24 Z" id="Stroke-2" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M53.5,82.5 L58,60" id="Stroke-3" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M95.5,82.5 L91,60" id="Stroke-4" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <polyline id="Stroke-5" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="50.5 49.5 41.5 52.5 28 93 53.5 82.5 74.5 91.5 95.5 82.5 121 93 107.5 52.5 98.5 49.5"/>
        <g id="dots-left-right" transform="translate(0.000000, 90.000000)" fill="#FFFFFF">
          <g id="right" transform="translate(133.000000, 0.000000)">
            <circle id="Oval-2" cx="3" cy="3" r="3"/>
            <circle id="Oval-2-Copy" cx="13" cy="3" r="3"/>
          </g>
          <g id="left">
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>);

export default Map;