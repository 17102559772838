import React  from "react";

const Launch = () => (
  <svg width="83" height="83" viewBox="0 0 83 83" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HoT_LandingPage1440px" transform="translate(-925.000000, -1516.000000)">
        <g id="2-row" transform="translate(925.000000, 1516.000000)">
          <g id="Group-16-Copy-3">
            <g id="icon-features-lunch">
              <circle id="Oval-Copy" fill="#F2F6FF" cx="41.5" cy="41.5" r="41.5"/>
              <g id="Group" transform="translate(23.000000, 23.000000)">
                <path d="M18.9131429,15.8382794 C21.3192486,15.8382794 23.2726657,17.7800571 23.2726657,20.1718222 C23.2726657,22.5635873 21.3192486,24.505419 18.9131429,24.505419 C16.5070914,24.505419 14.5536743,22.5635873 14.5536743,20.1718222 C14.5536743,17.7800571 16.5070914,15.8382794 18.9131429,15.8382794 Z" id="Fill-1" fill="#36A3D8"/>
                <path d="M18.9193857,0.00248253968 C18.9193857,0.00248253968 20.8224257,-0.0710761905 22.1068257,0.575787302 C23.3912257,1.22265079 24.3300971,3.04256825 24.3300971,3.04256825 L37.2225743,25.171873 C37.2225743,25.171873 38.5140314,27.1050159 37.3577457,29.2435079 C36.20146,31.382 35.4998171,33.5948603 32.4482543,33.5948603 L5.39051714,33.5948603 C2.33900857,33.5948603 1.63736571,31.382 0.48108,29.2435079 C-0.675205714,27.1050159 0.616197143,25.171873 0.616197143,25.171873 L13.5086743,3.04256825 C13.5086743,3.04256825 14.4475457,1.22265079 15.7319457,0.575787302 C17.0163457,-0.0710761905 18.9193857,0.00248253968 18.9193857,0.00248253968 Z" id="Stroke-2" stroke="#36A3D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M18.9193857,28.8409587 C14.1246543,28.8409587 10.2319343,24.9710571 10.2319343,20.2043651 C10.2319343,15.437673 14.1246543,11.5677175 18.9193857,11.5677175 C23.7141171,11.5677175 27.6068371,15.437673 27.6068371,20.2043651 C27.6068371,24.9710571 23.7141171,28.8409587 18.9193857,28.8409587 Z" id="Stroke-3" stroke="#36A3D8" strokeWidth="3"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>);

export default Launch;
