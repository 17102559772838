/**
 * API endpoints
 */

export const api: { [key: string]: any } = {
  auth: {
    signin: "/user/signin",
    signup: "/user/signup",
    signout: "/user/signout",
    setNewPass: "/user/password/change",
    resetPass: "/user/password/reset",
  },
  user: {
    getUser: "/user",
    getDetails: "/player",
    watchList: "/watch-list",
    watchListSeen: "/watch-list/seen",
    watchListCount: "/watch-list/count",
    notifications: "/notification/all",
    alerts: "/notification/alerts",
    updateUserData: "/user/update",
    updateUserDataTheme: "/user/update/theme",
    setUserTribe: (tribeId: string | number) => `/tribe/${tribeId}/join`
  },
  player: {
    getPlayerGoals: "/player-goal/all",
    getGoalTypes: "/player-goal/goals",
    updateGoal: "/player-goal/update",
    notificationUpdates: "/notification/update",
    playerAchievements: "/player/achievements",
    avatar: (playerId: number) => `/player/${playerId}/avatar`
  },
  achievements: {
    allAchievements: "/achievements",
  },
  board: {
    main: "/board",
    public: "/board/public",
    edit: (id: number | string) => `/board/${id}/edit`,
    details: (id: string | number) => `/board/${id}/content`,
    join: (id: string | number) => `/board/public/${id}/join`,
    leave: (id: string | number) => `/board/${id}/leave`,
    invitationCodeStatus: (boardId: string | number) => `/board/code/${boardId}/status`,
    joinByInvitationCode: () => `/board/code/join`,
    all_goals: "/board-goals/available",
    all_board_goals: (id: number) => `/board-goals/${id}`,
    update_goals: (boardId: string | number) => `/board-goals/${boardId}`
  },
  organization: {
    boards_ranking: (orgId: number | string) => `/organization/${orgId}/boards-ranking`
  },
  members: {
    followMember: ({boardId, memberId}: {
      boardId: number | string;
      memberId: number | string
    }) => `/board/${boardId}/member/${memberId}/tracking`,
    allAvailable: "/board/member/available",
    availableForBoard: (id: string | number) => `/board/${id}/member/available`,
    following: (boardId: number | string) => `/following/board/${boardId}`,
  },
  stats: {
    routes: "/stats/routes",
    heatMap: "/stats/heat-map",
    player: (boardId: string | number, memberId: string | number) => `/board/${boardId}/member/${memberId}`,
    getTotalDistance: `/stats/total-distance`,
    getTotalSteps: `/stats/total-steps`,
    tribeDistricts: `/stats/tribe-districts`,
    tribePoints: `/stats/tribe-points`,
    districtsInRadius: (longitude: number, latitude: number, radius: number) => `/district/ids/${longitude}/${latitude}/${radius}`,
    districtDetails: (districtId: number | string) => `/district/${districtId}`,
    tribes: () => '/tribe/all',
    bestPlayers: "/player/leaderboard",
    bestFollowing: "/following/leaderboard"
  },
  invitations: {
    getAll: "/invitation/all",
    getOrgInvites: (orgId?: number | string) => `/invitation/organization/all?organizationId=${orgId || ":orgId"}`,
    getOrgBoardInvites: (orgId?: number | string, boardId?: number | string) =>
      `/invitation/organization/all?organizationId=${orgId || ":orgId"}&boardId=${boardId || ":boardId"}`,
    process: "/invitation/process",
    count: "/invitation/count",
    removeMember: (requestId: string | number) => `/invitation/${requestId}/remove`
  },
  deepLink: {
    access: "deep-link/access",
    appRedirectUrl: (targetAction: string, accessCookie: string) => `hotLink://${targetAction}/${accessCookie}`
  },
  manager: {
    organisation: "/organization",
    orgDetails: (orgId: string | number) => `/organization/${orgId}/details`,
  },
  landing: {
    statistic: '/landing-page'
  },
  admin: {
    tenant: 'admin/tenant',
    updateTenant: (tenantId: string | number) => `/admin/tenant/${tenantId}`,
    tenantSettings: (tenantId: string | number) => `/admin/tenant/${tenantId}/settings`,
    tenantMapContent: (tenantId: string | number) => `/admin/tenant/${tenantId}/map-content`,
    deleteTenantMapContent: (tenantId: string | number, mapContentId: string | number) => `/admin/tenant/${tenantId}/map-content/${mapContentId}`,
    arPlace: '/admin/geo-lock-content-model',
    arPlaces: '/admin/geo-lock-content-model/0',
    tenantArPlaces: (tenantId: string | number) => `/admin/geo-lock-content-model/${tenantId}`,
    activateArForTenant: (tenantId: string | number, arId: string | number) => `/admin/geo-lock-content-model/${tenantId}/activate/${arId}`,
    deactivateArForTenant: (tenantId: string | number, arId: string | number) => `/admin/geo-lock-content-model/${tenantId}/deactivate/${arId}`,
  }
};

export enum appSections {
  MAIN = "main",
  WELCOME = "welcome",
  EVENTS = "events",
  AUTH = "auth",
  USER = "user",
  INFO = "info",
  BOARDS = "boards",
  MANAGER = "manager",
  ADMIN = "admin",
  ORG = "organisation",
  TEAMS = "teams",
  STATS = "stats",
  INVITES = "invites",
  INVITE_CODE = "invite-code",
  JOIN = "join",
  DEEP_LINK = "share"
}

const asPath = (path: Array<string | number>) => "/" + path.join("/");

/**
 * Project all routes
 */
export const routes: { [key: string]: any } = {
  [appSections.MAIN]: "/",
  [appSections.WELCOME]: {
    main: asPath([appSections.WELCOME]),
    badges: asPath([appSections.WELCOME, 'badges']),
    districtChecker: asPath([appSections.WELCOME, 'district-checker']),
    aboutUs: asPath([appSections.WELCOME, 'about-us']),
  },
  [appSections.EVENTS]: {
    main: asPath([appSections.EVENTS]),
    orgBoardSummary: (orgId?: number | string) => asPath([appSections.EVENTS, orgId || ":id"]),
  },
  [appSections.AUTH]: {
    main: asPath([appSections.AUTH]),
    signin: asPath([appSections.AUTH, "signin"]),
    signup: asPath([appSections.AUTH, "signup"]),
    resetPass: asPath([appSections.AUTH, "password", "reset"]),
    newPass: asPath([appSections.AUTH, "new-password"]),
    token: (token?: string) => asPath([appSections.AUTH, "token", token || ":token"]),
  },
  [appSections.USER]: {
    main: asPath([appSections.USER]),
    dashboard: asPath([appSections.USER, "dashboard"]),
    settings: asPath([appSections.USER, "settings"]),
    notifications: asPath([appSections.USER, "notifications"]),
    ranking: asPath([appSections.USER, "ranking"]),
  },
  [appSections.INFO]: {
    main: asPath([appSections.INFO]),
    requestSent: (id?: number | string) => asPath([appSections.INFO, "sent-board-request", id || ":id"]),
    joinedBoard: asPath([appSections.INFO, "joined-board-successfully"]),
    invitationConfirmed: () => asPath([appSections.INFO, "board-invitation", "confirmed"]),
  },
  [appSections.BOARDS]: {
    board: asPath([appSections.BOARDS]),
    join: asPath([appSections.BOARDS, "join"]),
    create: asPath([appSections.BOARDS, "create"]),
    createOrgBoard: (orgId?: string | number) => asPath([appSections.BOARDS, "create", "organization", orgId || ":orgId"]),
    edit: (id?: number | string) => asPath([appSections.BOARDS, "edit", id || ":id"]),
    newBoard: asPath([appSections.BOARDS, "new"]),
    list: asPath([appSections.BOARDS, "list"]),
    details: (id?: string | number) => asPath([appSections.BOARDS, 'details', id || ":id"]),
    memberDetails: ({boardId, memberId}: { [key: string]: string | number } = {boardId: "", memberId: ""}) =>
      (boardId || boardId === 0) && memberId ? asPath([appSections.BOARDS, boardId, "member", memberId]) : asPath([appSections.BOARDS, ":boardId", "member", ":memberId"]),
  },
  [appSections.MANAGER]: {
    main: asPath([appSections.MANAGER]),
    billing: asPath([appSections.MANAGER, "billing"]),
  },
  [appSections.ADMIN]: {
    main: asPath([appSections.ADMIN]),
    settings: asPath([appSections.ADMIN, "settings"]),
    tenantSettings: (id?: string | number) => asPath([appSections.ADMIN, 'tenant', id || ":id"]),
  },
  [appSections.TEAMS]: {
    teams: asPath([appSections.TEAMS, "management"]),
    create: (orgId?: string | number) => asPath([appSections.TEAMS, "organisation", orgId || ":orgId", "create"]),
    edit: (orgId?: string | number) => asPath([appSections.TEAMS, "organisation", orgId || ":orgId"]),
  },
  [appSections.ORG]: {
    main: asPath([appSections.ORG]),
    list: asPath([appSections.ORG, "list"]),
    create: asPath([appSections.ORG, "create"]),
    details: (orgId?: number | string) => asPath([appSections.ORG, orgId || ":orgId"]),
    edit: (orgId?: number | string) => asPath([appSections.ORG, "edit", orgId || ":orgId"]),
  },
  [appSections.STATS]: {
    main: asPath([appSections.STATS]),
    bestPlayers: asPath([appSections.STATS, "best-players"]),
    bestFollowing: asPath([appSections.STATS, "best-following"]),
    bestTribe: asPath([appSections.STATS, "best-tribe"]),
    totalSteps: asPath([appSections.STATS, "total-steps"]),
    totalDistance: asPath([appSections.STATS, "total-distance"]),
    comparison: asPath([appSections.STATS, "comparison"]),
    routes: asPath([appSections.STATS, "routes"]),
    maps: asPath([appSections.STATS, "maps"]),
    heatMap: asPath([appSections.STATS, "heatmap"]),
  },
  [appSections.INVITES]: {
    main: asPath([appSections.INVITES]),
    myboards: asPath([appSections.INVITES, "my-boards"]),
    organizations: asPath([appSections.INVITES, "organizations"]),
    friends: (boardId?: number | string) => asPath([appSections.INVITES, "friends", boardId || ":boardId"])
  },
  [appSections.INVITE_CODE]: {
    main: asPath([appSections.INVITE_CODE]),
    code: asPath([appSections.INVITE_CODE, "code"]),
    success: (boardId?: number | string) => asPath([appSections.INVITE_CODE, "board", boardId || ":boardId", "success"]),
  },
  [appSections.JOIN]: {
    main: asPath([appSections.JOIN])
  },
  [appSections.DEEP_LINK]: {
    main: asPath([appSections.DEEP_LINK]),
    share: (uniqueId?: string) => asPath([appSections.DEEP_LINK, uniqueId || ":uniqueId"])
  }
};

export const isRestrictedPath = (isAdmin: boolean | undefined, path: string) => {
  const userRestricted = [
    ...Object.values(routes[appSections.MANAGER]),
    ...Object.values(routes[appSections.AUTH]),
    routes[appSections.ORG].create,
    routes[appSections.ORG].edit,
    routes[appSections.TEAMS].teams,
    routes[appSections.TEAMS].create,
    routes[appSections.TEAMS].edit,
    routes[appSections.MAIN],
  ];
  if (isAdmin === undefined) {
    const defaultRestricted = [
      ...Object.values(routes[appSections.USER]),
      ...Object.values(routes[appSections.INFO]),
      ...Object.values(routes[appSections.BOARDS]),
      ...Object.values(routes[appSections.MANAGER]),
      ...Object.values(routes[appSections.TEAMS]),
      ...Object.values(routes[appSections.ORG]),
      ...Object.values(routes[appSections.STATS]),
      ...Object.values(routes[appSections.INVITE_CODE]),
    ];

    return defaultRestricted.indexOf(path) >= 0;
  }

  if (isAdmin) {
    const adminRestricted = [
      ...Object.values(routes[appSections.AUTH]),
      routes[appSections.MAIN],
    ]
    return adminRestricted.indexOf(path) >= 0;
  }

  return userRestricted.indexOf(path) >= 0;
};
