import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const TotalSteps: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="131" height="197" viewBox="0 0 131 107" version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-1220.000000, -999.000000)">
        <g id="icon-total-steps" transform="translate(1220.000000, 1001.000000)">
          <g id="Group" transform="translate(26.000000, 0.000000)" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="4">
            <path
              d="M27.1831113,81.9221568 C20.0000885,83.4338111 13.6991913,82.048128 15.4634425,90.3622267 C17.2276938,98.6763253 24.5367345,104.219058 31.7197572,102.707403 C38.90278,101.195749 43.439426,93.0076217 41.6751748,84.693523 C39.9109236,76.3794244 34.492152,80.2845313 27.1831113,81.9221568 Z"
              id="Shape"
              transform="translate(28.610098, 91.260020) rotate(-4.000000) translate(-28.610098, -91.260020) "/>
            <path
              d="M22.179158,73.8043649 C22.6832298,73.6783937 23.0612836,73.5524225 23.5653554,73.4264513 C25.9596963,72.6706242 28.2280193,71.7888258 30.1182885,70.1512003 C32.1345756,68.3876037 33.2687371,65.7422086 33.7728088,63.0968136 C35.0329883,56.5463117 34.1508627,49.2399825 32.0085576,43.0673942 C30.2443064,38.0285465 27.5979296,33.3676124 23.6913734,29.7144478 C20.6669427,26.8171104 16.5083506,24.4236578 12.2237405,25.1794849 C7.81311249,25.9353121 4.53664596,29.9663902 2.64637681,34.1234395 C-0.126017943,40.4219991 -0.504071774,47.4763859 0.882125604,54.152859 C1.89026915,58.9397643 4.03257419,63.6006984 6.42691511,67.8837189 C7.93913043,70.4031427 9.95541753,72.7965954 12.7278123,73.9303361 C15.8782609,75.190048 19.1547274,74.6861632 22.179158,73.8043649 Z"
              id="Shape"/>
            <path
              d="M61.7608723,57.2525545 C54.5778495,56.7486697 48.5289882,52.2137068 47.1427909,60.6537766 C45.7565935,69.0938465 50.7973112,76.7780892 58.1063519,77.9118299 C65.4153926,79.0455706 72.4723975,73.1249246 73.7325769,64.6848548 C74.9927563,56.2447849 68.9438951,58.8901799 61.7608723,57.2525545 Z"
              id="Shape"/>
            <path
              d="M70.257071,0.132290278 C63.4521021,-0.875479254 58.6634202,4.03739722 55.5129716,9.32818727 C52.1104872,14.8709197 50.220218,21.2954505 49.7161462,27.8459524 C49.4641103,31.2471746 49.7161462,34.6483968 50.3462359,38.049619 C50.9763257,40.9469564 51.4803974,43.970265 54.0007563,45.985804 C55.6389896,47.2455159 57.6552767,47.8753719 59.6715638,48.3792567 C62.5699765,49.1350838 65.4683892,49.3870262 68.4928198,49.3870262 C72.1473402,49.3870262 74.4156631,48.0013431 76.4319502,44.9780345 C80.2124885,39.4353021 82.3547936,33.0107713 82.8588653,26.3342981 C83.2369192,20.1617097 82.2287756,13.107323 79.4563809,7.43861939 C78.4482373,5.54905152 77.1880579,3.65948364 75.5498246,2.27380054 C73.9115914,1.14005981 72.2733581,0.510203853 70.257071,0.132290278 Z"
              id="Shape"/>
          </g>
          <g id="dots-left-right" transform="translate(0.000000, 98.000000)" fill="#FFFFFF">
            <circle id="Oval-2" cx="118" cy="3" r="3"/>
            <circle id="Oval-2-Copy-2" cx="3" cy="3" r="3"/>
            <circle id="Oval-2-Copy" cx="128" cy="3" r="3"/>
            <circle id="Oval-2-Copy-3" cx="13" cy="3" r="3"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default TotalSteps