import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const Info: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="64" height="64" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Miscellaneous/Icons" transform="translate(-1795.000000, -558.000000)">
        <g id="icon-information" transform="translate(1798.000000, 561.000000)">
          <circle id="Oval-13" stroke="#FFFFFF" strokeWidth="6" cx="29" cy="29" r="29"></circle>
          <rect id="Rectangle-23" fill="#FFFFFF" x="25" y="26" width="8" height="20" rx="4"></rect>
          <circle id="Oval-7" fill="#FFFFFF" cx="29" cy="17" r="4"></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default Info;