import React from 'react';
import {IconPropsInterface} from "components/shared/Icon/Icon";

const Notification: React.FC<IconPropsInterface> = ({className}) => (
  <svg className={className || ""} width="85" height="95" viewBox="0 0 85 95" version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="notification-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons" transform="translate(-363.000000, -251.000000)">
        <g id="icon-notification-bell" transform="translate(363.000000, 240.000000)">
          <g id="Group-4">
            <path
              d="M56.0099351,89.0041176 C56.0367965,89.2173882 56.0367965,89.4573176 56.0367965,89.6705882 C56.0367965,97.0284235 50.0198268,103 42.6060606,103 C35.1922944,103 29.1753247,97.0284235 29.1753247,89.6705882 C29.1753247,89.4573176 29.1753247,89.2173882 29.2021861,89.0041176"
              id="Shape" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round"/>
            <path
              d="M50.4147555,24.1077399 L50.4147555,21.4418576 C50.4147555,17.0164929 46.8153182,13.4442105 42.3563139,13.4442105 C37.8973096,13.4442105 34.2978723,17.0164929 34.2978723,21.4418576 L34.2978723,24.1077399"
              id="Shape" stroke="#FFFFFF" strokeWidth="4"/>
            <path
              d="M18.4307359,47.0164706 C18.4307359,33.7643694 29.2532229,23.0235294 42.6060606,23.0235294 C55.9588983,23.0235294 66.7813853,33.7643694 66.7813853,47.0164706 L66.7813853,51.9056988 C66.7813853,59.1275741 69.0565519,66.1708353 73.2872338,72.0491059 L80.2121212,81.6729412 C80.2121212,81.6729412 71.4418506,89.6705882 42.6060606,89.6705882 C13.7702706,89.6705882 5,81.6729412 5,81.6729412 L11.9248874,72.0491059 C16.1555693,66.1708353 18.4307359,59.1275741 18.4307359,51.9056988 L18.4307359,47.0164706 Z"
              id="Shape" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round"/>
            <g id="Group-7" transform="translate(0.000000, 100.000000)" fill="#FFFFFF">
              <circle id="Oval-2" cx="72" cy="3" r="3"/>
              <circle id="Oval-2-Copy-4" cx="3" cy="3" r="3"/>
              <circle id="Oval-2-Copy" cx="82" cy="3" r="3"/>
              <circle id="Oval-2-Copy-5" cx="13" cy="3" r="3"/>
            </g>
            <g id="Notification-red" transform="translate(53.424242, 0.000000)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Notification