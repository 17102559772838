import React from 'react';
import {IconsPropsInterface} from "components/shared/Icon/Icon";

const LeftArrow: React.FC<IconsPropsInterface> = ({className}) => (
  <svg className={className || ""} width="28" height="48" viewBox="0 0 28 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icons" transform="translate(-3008.000000, -482.000000)" stroke="#FFFFFF" strokeWidth="8">
        <polyline id="arrow-left-button-menu" transform="translate(3022.000000, 506.000000) scale(-1, 1) translate(-3022.000000, -506.000000) " points="3012.08333 486 3032 505.95825 3012 526"></polyline>
      </g>
    </g>
  </svg>
);

export default LeftArrow;