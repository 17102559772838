import './HotFooter.scss'
import LogoShortFooter from "../../assets/img/landingpage/Logo_Short_HealthOverTime_White@2x.png";
import AppLink from "../shared/RouterDecorators/AppLink";
import React from "react";

const HotFooter = () : JSX.Element => {
  return (
    <div className="HotFooter">
      <footer className="HotFooter-footer">
        <div className="container">
          <div className="HotFooter-footer-logo">
            <img src={LogoShortFooter} alt="logo-footer" />
          </div>
          <nav className="HotFooter-footer-nav">
            <AppLink to="/" className="HotFooter-footer-nav-item">Terms of Use</AppLink>
            <AppLink to="/" className="HotFooter-footer-nav-item">Contact Us</AppLink>
            <AppLink to="/" className="HotFooter-footer-nav-item">Copyrights</AppLink>
          </nav>
          <div className="copyright">&copy; Health over Time <span className="separator" /> 2021</div>
        </div>
      </footer>
    </div>
  )
}

export default HotFooter